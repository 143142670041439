import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Layout } from "antd";
import "./App.css";
import Sidebar from "./components/Layouts/Sidebar/Sidebar";
import Header from "./components/Layouts/Header/Header";
import Footer from "./components/Layouts/Footer/Footer";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Dashboard from "./main/Dashboard";
import Categories from "./main/Categories";
import Products from "./main/Products";
import ShopKeeper from "./main/ShopKeeper";
import BookingHistory from "./main/Booking History";
import Roles from "./main/Roles";
import AddRole from "./main/Roles/AddRole";
import OnlineSales from "./main/OnlineSales";
import AddOnlineScale from "./main/OnlineSales/AddOnlineSales";
import OrderSummary from "./main/OnlineSales/OrderSummary";
import OfflineSales from "./main/OfflineSales";
import Customers from "./main/Customers";
import OfflineOrderSummary from "./main/OfflineSales/OfflineOrderSummary";
import Employees from "./main/Employees";
import AddOfflineSale from "./main/OfflineSales/AddOfflineSales";
import CreditPayOrderSummary from "./main/OnlineSales/CreditPayOrderSummary";
import OfflineCreditPay from "./main/OfflineSales/OfflineCreditOrderSummary";
import CustomerBookingHistory from "./main/CustomersBookingHistory";

import { useSelector } from "react-redux";
import AddOnlineSale from "./main/OnlineSales/AddOnlineSales";
import OrderManagement from "./main/order management";
import StockManagement from "./main/Stock Management";
import Notifications from "./main/Notifications";
import ProductAdd from "./main/Products/ProductAdd";
import { ConfigProvider } from "antd";

const { Content } = Layout;
const App = () => {
  const token = useSelector((state) => state.token.token);
  console.log(token);
  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: "#6750A4",
          // colorBorder: "#e8e8e8",
          // Alias Token
          // colorBgContainer: "#fff",
        },
      }}>
      <Layout style={{ minHeight: "100vh" }}>
        <Sidebar />
        <Layout>
          <Header />
          <Layout>
            <Content style={{ margin: "16px", overflowY: "auto" }}>
              <div
                className='layout-content-section'
                style={{
                  height: "calc(100vh - 64px - 53px)",
                  overflowY: "auto",
                }}>
                <Routes>
                  <Route path='/home' element={<Home />} />
                  <Route path='/notification' element={<Notifications />} />
                  <Route path='/dashboard' element={<Dashboard />} />
                  <Route path='/categories' element={<Categories />} />
                  <Route path='/products' element={<Products />} />
                  <Route
                    path='/products/add-product'
                    element={<ProductAdd />}
                  />
                  <Route
                    path='/orderManagement'
                    element={<OrderManagement />}
                  />
                  <Route
                    path='/stockManagement'
                    element={<StockManagement />}
                  />
                  <Route path='/role' element={<Roles />} />
                  <Route path='/AddRole' element={<AddRole />} />
                  <Route path='/Employee' element={<Employees />} />
                  <Route path='/onlineSales' element={<OnlineSales />} />
                  <Route path='/add-onlineSale' element={<AddOnlineSale />} />
                  <Route
                    path='/onlineOrder-summary'
                    element={<OrderSummary />}
                  />
                  <Route
                    path='/onlineCreditOrder-summary'
                    element={<CreditPayOrderSummary />}
                  />
                  <Route path='/offlineSales' element={<OfflineSales />} />
                  <Route path='/addoffline-sale' element={<AddOfflineSale />} />
                  <Route
                    path='/offlineOrder-summary'
                    element={<OfflineOrderSummary />}
                  />
                  <Route
                    path='/offlineCreditOrder-summary'
                    element={<OfflineCreditPay />}
                  />
                  <Route path='/shopKeeper' element={<ShopKeeper />} />
                  <Route
                    path='/shopKeeper-history'
                    element={<BookingHistory />}
                  />
                  <Route path='/Customers' element={<Customers />} />
                  <Route
                    path='/customer-history'
                    element={<CustomerBookingHistory />}
                  />
                </Routes>
              </div>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export default App;
