import React, { useEffect, useState } from "react";
import { Avatar, Modal, Spin, Switch, message, Pagination } from "antd";
import { MdOutlineModeEdit, MdDeleteOutline } from "react-icons/md";
import CategoryIamge from "../Categories/CategoryImage.png";
import EditEmployee from "./EditEmployee";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { postAuthApiData } from "../../api/apiService";
import useLogout from "../../utils/authUtils";
import MapViewer from "./MapViewer";
import { GoLocation } from "react-icons/go";
import { FaMapMarkerAlt } from "react-icons/fa";
import moment from "moment";

const EmployeeListBody = ({
  employeesData,
  fetchEmployeeList,
  loading,
  setLoading,
  error,
  setError,
  rolesData,
}) => {
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [successDeleteModal, setSuccessDeleteModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [swicthAction, setSwitchAction] = useState(false);
  const [EmployeeActive, setEmployeeActive] = useState(false);
  const [EmployeeDeactive, setEmployeeDeactive] = useState(false);
  const [activeSuccess, setActiveSuccess] = useState(false);
  const [deactiveSuccess, setDeactiveSuccess] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [location, setLocation] = useState({});
  const logout = useLogout();
  const pageSize = 10;

  const onChange = (item) => {
    console.log(item);
    setSelectedItem(item);
    if (item?.status === "active") {
      setEmployeeActive(true);
    } else {
      setEmployeeDeactive(true);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [employeesData]);

  const toggleStatus = async () => {
    const Endpoint = "toggleEmployeeStatus";
    const status = selectedItem?.forceLogoutStatus;
    try {
      setLoading(true);
      const userData = { employeeId: selectedItem?._id };
      const { data, loading: postLoading } = await postAuthApiData(
        Endpoint,
        userData
      );
      console.log("Employee Status Toggling successful:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data.message);
        fetchEmployeeList();
        setEmployeeActive(false);
        setEmployeeDeactive(false);
        if (status === "active") {
          setDeactiveSuccess(true);
        } else if (status === "inactive") {
          setActiveSuccess(true);
        }
      } else if (data.responseCode === 400) {
        console.log(data);
        message.error(data.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Employee Status Toggling failed:", error);
      message.error(error);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (roleId) => {
    console.log("Edit clicked for role:", roleId);
  };

  const close = () => {
    setEditModal(false);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [employeesData]);

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = employeesData?.slice(startIndex, endIndex);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
    setLocation({});
  };

  console.log(location);

  return (
    <Spin spinning={loading}>
      <div className='EmployeelistBody-container'>
        <table className='EmployeelistBody_table'>
          <thead>
            <tr className='EmployeelistBody_table_head'>
              <th className='EmployeelistBody_S.no'>S.No</th>
              <th className='EmployeelistBody_Name'>Name of the Employee</th>
              <th className=''>Location</th>
              <th className='EmployeeListBody_Designation'>Designation</th>
              <th className='EmployeeListBody_Contact'>Contact</th>
              <th className='EmployeeListBody_Email'>Email</th>
              <th className='EmployeeListBody_StartDate'>Start Date</th>
              <th className='EmployeeListBody_LastDate'>Last Date</th>
              <th className='EmployeelistBody_Status'>Status</th>
              <th className='EmployeelistBody_Actions'>Actions</th>
            </tr>
          </thead>

          {paginatedData?.length > 0
            ? paginatedData?.map((item, index) => (
                <tbody className='EmployeelistBody_table_body'>
                  <tr
                    key={item?._id}
                    className={swicthAction ? "" : "body-color"}>
                    <td>{startIndex + index + 1}</td>
                    <td>
                      <div className='EmployeeListBody_Section capital'>
                        {" "}
                        {item?.image ? (
                          <Avatar src={item?.image} size={40} />
                        ) : (
                          <Avatar
                            shape='circle'
                            size={40}
                            style={{
                              backgroundColor: "#F1ECFF",
                              fontWeight: 600,
                              fontSize: 17,
                              color: "#6750A4",
                              // border: "1px solid #6750A4",
                            }}>
                            {item?.username?.length > 0 &&
                              item?.username[0]?.charAt(0)?.toUpperCase()}
                          </Avatar>
                        )}
                        {item?.username}
                      </div>
                    </td>
                    <td>
                      <div
                        style={{
                          width: "60%",
                          margin: "0 auto",
                        }}>
                        <FaMapMarkerAlt
                          onClick={() => {
                            setLocation(item?.location);
                            console.log(item?.location);
                            if (item?.location === null) {
                              message.warning("Location not available");
                            } else {
                              setIsModalVisible(true);
                            }
                          }}
                          style={{ cursor: "pointer", textAlign: "center" }}
                        />
                      </div>
                    </td>
                    <td className='capital'>{item?.roleName}</td>
                    <td>{item?.phoneNumber || "--"}</td>
                    <td>{item?.email || "--"}</td>
                    <td>
                      {" "}
                      {item?.date
                        ? moment(item.date).format("DD-MM-YYYY")
                        : "--"}
                    </td>
                    <td>{item?.LastDate ? item?.LastDate : " - "}</td>
                    <td>
                      <Switch
                        className='rolelistBody_Switch'
                        checked={item?.status === "active" ? true : false}
                        onChange={() => {
                          setSelectedItem(item);
                          onChange(item);
                        }}
                      />
                    </td>
                    <td>
                      <span
                        className='EmployeelistBody_Edit'
                        onClick={() => {
                          if (item?.status === "active") {
                            setSelectedItem(item);
                            setEditModal(true);
                          }
                        }}>
                        <MdOutlineModeEdit
                          style={{ fontSize: "19px", cursor: "pointer" }}
                        />
                      </span>
                    </td>
                  </tr>
                </tbody>
              ))
            : ""}
        </table>
        {paginatedData?.length > 0 ? (
          ""
        ) : (
          <div className='no-data-message'>
            <h6>No data found</h6>
          </div>
        )}

        <Modal
          visible={editModal}
          onCancel={() => setEditModal(false)}
          centered
          footer={null}
          className='custom-modal'>
          <EditEmployee
            close={close}
            selectedItem={selectedItem}
            fetchEmployeeList={fetchEmployeeList}
            rolesData={rolesData}
          />
        </Modal>
        <MapViewer
          key={`${location?.latitude}-${location?.longitude}`}
          visible={isModalVisible}
          onClose={handleClose}
          latitude={location?.latitude}
          longitude={location?.longitude}
        />
        {/* <Modal
          visible={deleteModal}
          onCancel={() => setDeleteModal(false)}
          centered
          footer={null}
          className='custom-modal'>
          <div className='delete-employ-container'>
            <div className='delete-employ-content'>
              <h5>
                Do you want to delete this Employee <br /> details from Employee
                Management?
              </h5>
              <div className='delete-employ-actions'>
                <button
                  className='delete-employ-no'
                  onClick={() => {
                    setDeleteModal(false);
                  }}>
                  <p>No</p>
                </button>
                <button
                  className='delete-employ-delete'
                  onClick={() => {
                    setDeleteModal(false);
                    setSuccessModal(true);
                  }}>
                  <p>Delete</p>
                </button>
              </div>
            </div>
          </div>
        </Modal> */}
        {/* <Modal
          visible={successModal}
          onCancel={() => setSuccessModal(false)}
          centered
          footer={null}
          className='custom-modal'>
          <div className='Employ-success-container'>
            <div className='Employ-success-content'>
              <div>
                <img src='assets/images/success_image.png' alt='' />
              </div>
              <h5>
                Employee Deleted <br /> Successfully
              </h5>
            </div>
          </div>
        </Modal> */}
        <Modal
          visible={EmployeeActive}
          onCancel={() => setEmployeeActive(false)}
          centered
          footer={null}
          className='custom-modal'>
          <Spin spinning={loading}>
            <div className='role-active-container'>
              <div className='role-active-content'>
                <h5 className='head-text'>
                  Do you want to{" "}
                  <span className='Secondry-color'>Deactivate</span> this
                  Employee?
                </h5>
                <div className='role-active-actions'>
                  <button
                    className='role-active-no'
                    onClick={() => setEmployeeActive(false)}>
                    <p>No</p>
                  </button>
                  <button
                    className='role-active-delete'
                    onClick={() => {
                      toggleStatus();
                    }}>
                    <p>Yes</p>
                  </button>
                </div>
              </div>
            </div>
          </Spin>
        </Modal>
        <Modal
          visible={EmployeeDeactive}
          onCancel={() => setEmployeeDeactive(false)}
          centered
          footer={null}
          className='custom-modal'>
          <Spin spinning={loading}>
            <div className='role-active-container'>
              <div className='role-active-content'>
                <h5>
                  Are you want to <span className='primary-color'>Active</span>{" "}
                  this Employee
                </h5>
                <div className='role-active-actions'>
                  <button
                    className='role-active-no'
                    onClick={() => setEmployeeDeactive(false)}>
                    <p>No</p>
                  </button>
                  <button
                    className='role-active-delete'
                    onClick={() => {
                      toggleStatus();
                    }}>
                    <p>Yes</p>
                  </button>
                </div>
              </div>
            </div>
          </Spin>
        </Modal>
        <Modal
          visible={activeSuccess}
          onCancel={() => setActiveSuccess(false)}
          centered
          footer={null}
          className='custom-modal'>
          <div className='active-success-container'>
            <div className='active-success-content'>
              <div>
                <img src='assets/images/success_image.png' alt='' />
              </div>
              <h5>
                Employee Activated <br />
                Successfully
              </h5>
            </div>
          </div>
        </Modal>
        <Modal
          visible={deactiveSuccess}
          onCancel={() => setDeactiveSuccess(false)}
          centered
          footer={null}
          className='custom-modal'>
          <div className='active-success-container'>
            <div className='active-success-content'>
              <div>
                <img src='assets/images/success_image.png' alt='' />
              </div>
              <h5>
                Employee Deactivated <br />
                Successfully
              </h5>
            </div>
          </div>
        </Modal>
      </div>
      <div className='employee-Pagination-container'>
        {employeesData?.length > 0 && (
          <Pagination
            simple
            current={currentPage}
            pageSize={pageSize}
            total={employeesData?.length}
            onChange={handleChangePage}
          />
        )}
      </div>
    </Spin>
  );
};

export default EmployeeListBody;
