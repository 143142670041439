import React, { useEffect, useRef, useState } from "react";
import {
  Input,
  Button,
  Table,
  Pagination,
  Avatar,
  Modal,
  Select,
  Form,
  Spin,
  Menu,
  Checkbox,
  Dropdown,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { BsFilter } from "react-icons/bs";
import { MdOutlinePictureAsPdf } from "react-icons/md";
import { ImPrinter } from "react-icons/im";
import AddStock from "./AddStock";
import ProductList from "./ProductList";
import * as XLSX from "xlsx";
import { DatePicker, Space } from "antd";
import moment from "moment";
const { RangePicker } = DatePicker;

const StockManagementList = ({
  stockProductsDetails,
  fetchStockProductDetails,
  loading,
  searchInput,
  setSearchInput,
  checkedFilter,
  setCheckedFilter,
  filterDate,
  setFilterDate,
  fetchProductList,
  fetchSellingList,
  fetchGetStockStatus,
}) => {
  const [activeSuccess, setActiveSuccess] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [skuData, setSkuData] = useState({});
  const [filterVisible, setFilterVisible] = useState(false);
  const isInitialRender = useRef(true);
  const [currentPage, setCurrentPage] = useState(1);

  const onExportLocal = () => {
    // Check if the file has already been downloaded in this session
    if (localStorage.getItem("stockProductsDetailsDownloaded")) {
      const downloadAgain = confirm(
        "The file has already been downloaded. Do you want to download it again?"
      );
      if (!downloadAgain) return; // Exit if the user doesn't want to re-download
    }

    const formattedData = [];

    stockProductsDetails.forEach((item, index) => {
      if (item.totalSKUs && item.totalSKUs.length > 0) {
        item.totalSKUs.forEach((sku, skuIndex) => {
          formattedData.push({
            "S.No": `${index + 1}.${skuIndex + 1}`,
            "Product Name": item.productName,
            Sku: sku.quantity === "0 null" ? sku?.PouchesSku : sku.quantity,
            "Base Price": sku.basePrice,
            "Selling Price": sku.salePrice,
            "Units/Packets": sku.packets,
            "Quantity(KG)": sku.totalQuantityOfSKU,
            "Last Updated Date": sku?.updatedDate
              ? moment(sku.updatedDate).format("DD-MM-YYYY")
              : "--",
            "Stock Status": sku.stockStatus,
          });
        });
      } else {
        formattedData.push({
          "S.No": index + 1,
          "Product Name": item.productName,
          Sku: "",
          "Base Price": "",
          "Selling Price": "",
          "Units/Packets": "",
          "Quantity(KG)": item.totalQuantity,
          "Last Updated Date": "",
          "Stock Status": item.stockStatus ? "In Stock" : "Out of Stock",
        });
      }
    });

    console.log("Formatted Data: ", formattedData);

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "stockProductsDetails");

    // Set column widths
    const wscols = [
      { wch: 5 }, // S.No
      { wch: 35 }, // Product Name
      { wch: 20 }, // Sku
      { wch: 10 }, // Base Price
      { wch: 15 }, // Selling Price
      { wch: 15 }, // Units/Packets
      { wch: 15 }, // Quantity(KG)
      { wch: 20 }, // Last Updated Date
      { wch: 25 }, // Stock Status
    ];
    worksheet["!cols"] = wscols;

    // Generate the file and trigger download
    XLSX.writeFile(workbook, "stockProductsDetails.xlsx");

    // Set a flag in localStorage to mark the file as downloaded
    localStorage.setItem("stockProductsDetailsDownloaded", "true");
  };

  const pageSize = 10;

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const showModal = (item) => {
    console.log(item);
    setSelectedProduct(item);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSkuData({});
  };

  // const handlePrint = () => {
  //   const printWindow = window.open("", "_blank");
  //   const printData = stockProductsDetails
  //     .map((item, index) => {
  //       if (item.totalSKUs && item.totalSKUs.length > 0) {
  //         return item.totalSKUs
  //           .map(
  //             (sku, skuIndex) => `
  //       <tr>
  //         <td>${index + 1}.${skuIndex + 1}</td>
  //         <td>${item.productName}</td>
  //         <td>${sku.quantity}</td>
  //         <td>${sku.basePrice}</td>
  //         <td>${sku.salePrice}</td>
  //         <td>${sku.packets}</td>
  //         <td>${sku.totalQuantityOfSKU}</td>
  //         <td>${sku.updatedDate}</td>
  //         <td style="color: ${
  //           sku.stockStatus === "In Stock" ? "green" : "red"
  //         }">${sku.stockStatus}</td>
  //       </tr>`
  //           )
  //           .join("");
  //       } else {
  //         return `
  //       <tr>
  //         <td>${index + 1}</td>
  //         <td>${item.productName}</td>
  //         <td></td>
  //         <td></td>
  //         <td></td>
  //         <td></td>
  //         <td>${item.totalQuantity}</td>
  //         <td></td>
  //         <td style="color: ${item.stockStatus ? "green" : "red"}">${
  //           item.stockStatus ? "In Stock" : "Out of Stock"
  //         }</td>
  //       </tr>`;
  //       }
  //     })
  //     .join("");

  //   printWindow.document.write(`
  //     <html>
  //     <head>
  //       <title>Stock Product Details</title>
  //       <style>
  //         table {
  //           width: 100%;
  //           border-collapse: collapse;
  //         }
  //         th, td {
  //           border: 1px solid black;
  //           padding: 8px;
  //           text-align: left;
  //         }
  //         th {
  //           background-color: #f2f2f2;
  //         }
  //         td:nth-child(1), th:nth-child(1) { /* S.No column */
  //           width: 5%;
  //         }
  //         td:nth-child(2), th:nth-child(2) { /* Product Name column */
  //           width: 20%;
  //         }
  //         td:nth-child(3), th:nth-child(3) { /* Sku column */
  //           width: 10%;
  //         }
  //         td:nth-child(4), th:nth-child(4) { /* Base Price column */
  //           width: 10%;
  //         }
  //         td:nth-child(5), th:nth-child(5) { /* Selling Price column */
  //           width: 10%;
  //         }
  //         td:nth-child(6), th:nth-child(6) { /* Units/Packets column */
  //           width: 10%;
  //         }
  //         td:nth-child(7), th:nth-child(7) { /* Quantity(KG) column */
  //           width: 10%;
  //         }
  //         td:nth-child(8), th:nth-child(8) { /* Last Updated Date column */
  //           width: 15%;
  //         }
  //         td:nth-child(9), th:nth-child(9) { /* Stock Status column */
  //           width: 10%;
  //         }
  //       </style>
  //     </head>
  //     <body>
  //       <h1>Stock Product Details</h1>
  //       <table>
  //         <thead>
  //           <tr>
  //             <th>S.No</th>
  //             <th>Product Name</th>
  //             <th>Sku</th>
  //             <th>Base Price</th>
  //             <th>Selling Price</th>
  //             <th>Units/Packets</th>
  //             <th>Quantity(KG)</th>
  //             <th>Last Updated Date</th>
  //             <th>Stock Status</th>
  //           </tr>
  //         </thead>
  //         <tbody>
  //           ${printData}
  //         </tbody>
  //       </table>
  //     </body>
  //     </html>
  //   `);

  //   printWindow.document.close();
  //   printWindow.print();
  // };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = stockProductsDetails?.slice(startIndex, endIndex);

  const handleFilterChange = async (e) => {
    if (e.target.checked === true) {
      fetchStockProductDetails(filterDate);
      await setCheckedFilter(e.target.name);
    } else if (filterDate) {
      setCheckedFilter("");
      fetchStockProductDetails(filterDate);
    } else {
      setCheckedFilter("");
    }
    console.log(e);
    setFilterVisible(false);
    setCurrentPage(1);
  };

  const filterMenu = (
    <Menu>
      <Menu.Item key='1'>
        <Checkbox
          name='quantity'
          checked={checkedFilter === "quantity"}
          onChange={handleFilterChange}>
          Quantity
        </Checkbox>
      </Menu.Item>
      <Menu.Item key='2'>
        <Checkbox
          name='Instock'
          checked={checkedFilter === "Instock"}
          onChange={handleFilterChange}>
          In Stock
        </Checkbox>
      </Menu.Item>
      <Menu.Item key='3'>
        <Checkbox
          name='lowInStock'
          checked={checkedFilter === "lowInStock"}
          onChange={handleFilterChange}>
          Low in Stock
        </Checkbox>
      </Menu.Item>
      <Menu.Item key='4'>
        <Checkbox
          name='OutofStock'
          checked={checkedFilter === "OutofStock"}
          onChange={handleFilterChange}>
          Out of Stock
        </Checkbox>
      </Menu.Item>
      {/* <Menu.Item key='5'>
        <Checkbox
          name='basePrice'
          checked={checkedFilter === "basePrice"}
          onChange={handleFilterChange}>
          Base Price
        </Checkbox>
      </Menu.Item>
      <Menu.Item key='6'>
        <Checkbox
          name='salePrice'
          checked={checkedFilter === "salePrice"}
          onChange={handleFilterChange}>
          Sale Price
        </Checkbox>
      </Menu.Item>
      <Menu.Item key='7'>
        <Checkbox
          name='packets'
          checked={checkedFilter === "packets"}
          onChange={handleFilterChange}>
          Packets
        </Checkbox>
      </Menu.Item> */}
      {/* <Menu.Item key='8'>
        <Checkbox
          name='date'
          checked={checkedFilter === "date"}
          onChange={handleFilterChange}>
          Date
        </Checkbox>
      </Menu.Item> */}
    </Menu>
  );

  const onDateChange = (dates, dateStrings) => {
    const formattedDates = dateStrings?.every((date) => date) // Check if all dates are non-empty
      ? dateStrings.map((date) =>
          moment(date, "DD-MM-YYYY").format("YYYY-MM-DD")
        )
      : [];
    console.log("Selected dates:", dates);
    console.log("Formatted dates (YYYY-MM-DD):", formattedDates);
    setFilterDate(formattedDates);
    fetchStockProductDetails(formattedDates);
  };

  return (
    <div className='stock-management-list'>
      <div className='stock-management-header'>
        <div className='stockm-header-left-contant'>
          <div className='stockM_search-bar'>
            <Input
              type='search'
              placeholder='Search product'
              className='stockM-search-input'
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              suffix={<SearchOutlined className='search-icon' />}
            />
          </div>
          <div className='stockM_filter_b'>
            <Dropdown
              overlay={filterMenu}
              trigger={["click"]}
              visible={filterVisible}
              onVisibleChange={setFilterVisible}>
              <Button className='stockM_filter-button capital'>
                <span> {checkedFilter ? checkedFilter : "Filter"} </span>
                <BsFilter size={17} className='onlineSalesCard_filter-icon' />
              </Button>
            </Dropdown>
          </div>
          <RangePicker
            style={{ width: "30%" }}
            format='DD-MM-YYYY'
            onChange={onDateChange}
          />
        </div>
        <div className='stockm-header-right-contant'>
          <div className='stockM_PDF_B' onClick={onExportLocal}>
            <button className='stockM-pdf-btn'>
              <MdOutlinePictureAsPdf size={15} className='stockM_pdf-icon' />{" "}
              <span className='pdf-content'> Export to CSV </span>
            </button>
          </div>
          {/* <div className='stockM_Print_b'>
            <button className='stockM_print-button' onClick={handlePrint}>
              <ImPrinter size={15} className='stockM_print-icon' /> Print
            </button>
          </div> */}
        </div>
      </div>

      <div className='StockM-Table'>
        <table className='StockM-Table-main'>
          <thead className='StockM-Table-head'>
            <tr className='StockM-Table-head-row'>
              <th className='StockM-head-sno'>S.No</th>
              <th className='StockM-head-productname'>Product Name</th>
              <th className='StockM-head-sku'>Sku</th>
              <th className='StockM-head-baseprice'>Base Price</th>
              <th className='StockM-head-sellingprice'>Selling Price</th>
              <th className='StockM-head-units'>Units/Packets</th>
              {/* <th className='StockM-head-quantity'>Quantity/Pouches</th> */}
              <th className='StockM-head-lastupdated'>Last Updated Date</th>
              <th className='StockM-head-stocksts'>Stock Status</th>
              <th className='StockM-head-action'>Action</th>
              <th className='StockM-head-action'>History</th>
            </tr>
          </thead>
          <tbody className='StockM-Table-body-row'>
            {paginatedData?.length > 0
              ? paginatedData?.map((item, index) => (
                  <ProductList
                    key={index}
                    item={item}
                    index={index}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    showModal={showModal}
                    setSkuData={setSkuData}
                  />
                ))
              : ""}
          </tbody>
        </table>
        {paginatedData?.length > 0 ? (
          ""
        ) : (
          <div style={{ textAlign: "center", marginTop: "10%" }}>
            <h6>No data found</h6>
          </div>
        )}
      </div>
      <div className='stockM-Pagination-container'>
        {stockProductsDetails?.length > 0 && (
          <Pagination
            simple
            current={currentPage}
            pageSize={pageSize}
            total={stockProductsDetails?.length}
            onChange={handleChangePage}
          />
        )}
      </div>
      <Modal
        visible={isModalVisible}
        centered
        onCancel={handleCancel}
        footer={null}>
        <AddStock
          setActiveSuccess={setActiveSuccess}
          setIsModalVisible={setIsModalVisible}
          selectedSkuData={skuData}
          handleCancel={handleCancel}
          fetchStockProductDetails={fetchStockProductDetails}
          fetchListOfProducts={fetchProductList}
          fetchListOfSelling={fetchSellingList}
          fetchListofStockStatus={fetchGetStockStatus}
          currentPage={currentPage}
        />
      </Modal>
      <Modal
        visible={activeSuccess}
        onCancel={() => setActiveSuccess(false)}
        centered
        footer={null}
        className='custom-modal'>
        <div className='active-success-container'>
          <div className='active-success-content'>
            <div>
              <img src='assets/images/success_image.png' alt='' />
            </div>
            <h5>
              Product stock Added <br />
              Successfully
            </h5>
          </div>
        </div>
      </Modal>
    </div>
  );
};
// StockManagementList
export default StockManagementList;
