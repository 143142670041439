import React, { useEffect, useState } from "react";
import "./stockManagement.css";
import StockManagementCards from "./stockManagementCards";
import StockManagementList from "./stockManagementList";
import { getApiData, postAuthApiData } from "../../api/apiService";
import { Spin, message } from "antd";
import useLogout from "../../utils/authUtils";

const StockManagement = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [productsDetailsData, setProductsDeatailsData] = useState([]);
  const [topSellingProducts, setTopSellingProducts] = useState([]);
  const [stockStatusData, setStockStatusData] = useState([]);
  const [stockProductsDetails, setStockProductDetails] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filter, setFilter] = useState("All");
  const [checkedFilter, setCheckedFilter] = useState("");
  const [filterDate, setFilterDate] = useState([]);
  const logout = useLogout();

  const fetchSellingList = async () => {
    const Endpoint = "getTopSellingProducts";
    try {
      setLoading(true);
      const { data, loading: postLoading } = await getApiData(Endpoint, true);
      console.log("getTopSellingProducts fetched successful:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data.message);
        setTopSellingProducts(data?.data);
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("getTopSellingProducts fetching failed:", error);
      message.error(error);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  const fetchGetStockStatus = async () => {
    const Endpoint = "getStockStatus";
    try {
      setLoading(true);
      const { data, loading: postLoading } = await getApiData(Endpoint, true);
      console.log("getStockStatus fetched successful:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data.message);
        setStockStatusData(data?.data?.stockStatus);
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("getStockStatus fetching failed:", error);
      message.error(error);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  const fetchProductList = async () => {
    const Endpoint = "GetProductDetails";
    try {
      setLoading(true);
      const { data, loading: postLoading } = await getApiData(Endpoint, true);
      console.log("GetProductDetails fetched successful:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data.message);
        setProductsDeatailsData(data?.data);
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("GetProductDetails fetching failed:", error);
      message.error(error);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  const fetchStockProductDetails = async (date) => {
    const Endpoint = "getStockProductDetails";
    try {
      setLoading(true);
      const userData = {
        startDate: date?.length > 0 && date[0],
        endDate: date?.length > 0 && date[1],
      };
      const { data, loading: postLoading } = await postAuthApiData(
        Endpoint,
        userData
      );
      console.log("GetProductDetails fetched successful:", data);
      if (data.responseCode === 200) {
        console.log(data.message);
        setStockProductDetails(data?.data?.productDetails);
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("GetProductDetails fetching failed:", error);
      message.error(error);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const filterShopkeepersBySearchInput = (shopkeepers, searchInput) => {
    if (!searchInput) return shopkeepers;
    const lowerCaseSearchInput = searchInput.toLowerCase();
    return shopkeepers.filter(
      (shopkeeper) =>
        shopkeeper?.productName
          ?.toLowerCase()
          ?.includes(lowerCaseSearchInput) ||
        shopkeeper?.customerContact?.includes(searchInput)
    );
  };

  const filterAndSortShopkeepers = () => {
    let filteredData = stockProductsDetails?.filter((shopkeeper) => {
      if (filter === "All") {
        return true;
      } else if (filter === "Active") {
        return shopkeeper?.status === "active";
      } else if (filter === "Inactive") {
        return shopkeeper?.status === "inactive";
      }
    });

    filteredData = filterShopkeepersBySearchInput(filteredData, searchInput);

    if (checkedFilter === "quantity") {
      filteredData = filteredData?.sort(
        (a, b) =>
          a.totalSKUs[0]?.totalQuantityOfSKU -
          b.totalSKUs[0]?.totalQuantityOfSKU
      );
    } else if (checkedFilter === "basePrice") {
      filteredData = filteredData?.sort(
        (a, b) => a.totalSKUs[0]?.basePrice - b.totalSKUs[0]?.basePrice
      );
    } else if (checkedFilter === "salePrice") {
      filteredData = filteredData?.sort(
        (a, b) => a.totalSKUs[0]?.salePrice - b.totalSKUs[0]?.salePrice
      );
    } else if (checkedFilter === "packets") {
      filteredData = filteredData?.sort(
        (a, b) => a.totalSKUs[0]?.packets - b.totalSKUs[0]?.packets
      );
    } else if (checkedFilter === "date") {
      filteredData = filteredData?.sort(
        (a, b) => a.totalSKUs[0]?.updatedDate - b.totalSKUs[0]?.updatedDate
      );
    } else if (checkedFilter === "Instock") {
      filteredData = filteredData.filter((shopkeeper) => {
        // Filter SKUs based on the desired stock status
        const matchedSKUs = shopkeeper.totalSKUs.filter(
          (stock) => stock.stockStatus === "In stock"
        );

        // Update the totalSKUs array to only include matched SKUs
        shopkeeper.totalSKUs = matchedSKUs;

        // Update stockStatus based on the matched SKUs
        shopkeeper.stockStatus = matchedSKUs.length > 0 ? ["In stock"] : [];

        // Return true if there are matched SKUs, otherwise false
        return matchedSKUs.length > 0;
      });
    } else if (checkedFilter === "lowInStock") {
      filteredData = filteredData.filter((shopkeeper) => {
        // Filter SKUs based on the desired stock status
        const matchedSKUs = shopkeeper.totalSKUs.filter(
          (stock) => stock.stockStatus === "Low in stock"
        );

        // Update the totalSKUs array to only include matched SKUs
        shopkeeper.totalSKUs = matchedSKUs;

        // Update stockStatus based on the matched SKUs
        shopkeeper.stockStatus = matchedSKUs.length > 0 ? ["Low in stock"] : [];

        // Return true if there are matched SKUs, otherwise false
        return matchedSKUs.length > 0;
      });
    } else if (checkedFilter === "OutofStock") {
      filteredData = filteredData.filter((shopkeeper) => {
        // Filter SKUs based on the desired stock status
        const matchedSKUs = shopkeeper.totalSKUs.filter(
          (stock) => stock.stockStatus === "Out of stock"
        );

        // Update the totalSKUs array to only include matched SKUs
        shopkeeper.totalSKUs = matchedSKUs;

        // Update stockStatus based on the matched SKUs
        shopkeeper.stockStatus = matchedSKUs.length > 0 ? ["Out of stock"] : [];

        // Return true if there are matched SKUs, otherwise false
        return matchedSKUs.length > 0;
      });
    }

    return filteredData;
  };

  const filteredShopkeeper = filterAndSortShopkeepers();
  console.log(filteredShopkeeper);

  useEffect(() => {
    fetchProductList();
    fetchSellingList();
    fetchStockProductDetails(filterDate);
    fetchGetStockStatus();
    console.log("Calling useEffect");
  }, []);

  return (
    <>
      <Spin spinning={loading}>
        <div className='StockM-Cards'>
          <StockManagementCards
            productsDetailsData={productsDetailsData}
            topSellingProducts={topSellingProducts}
            stockStatusData={stockStatusData}
            loading={loading}
          />
        </div>
        <div className='stockM-List'>
          <StockManagementList
            stockProductsDetails={filteredShopkeeper}
            fetchStockProductDetails={fetchStockProductDetails}
            loading={loading}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            checkedFilter={checkedFilter}
            setCheckedFilter={setCheckedFilter}
            filterDate={filterDate}
            setFilterDate={setFilterDate}
            fetchProductList={fetchProductList}
            fetchSellingList={fetchSellingList}
            fetchGetStockStatus={fetchGetStockStatus}
          />
        </div>
      </Spin>
    </>
  );
};

export default StockManagement;
