import { Form, Input, Checkbox, Col, Row, message, Spin } from "antd";
import { ImCancelCircle } from "react-icons/im";
import React, { useEffect, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { putAuthApiData } from "../../api/apiService";
import useLogout from "../../utils/authUtils";

// Edit Role Code

const EditRole = ({
  close,
  setAddActivity,
  selectedItem,
  setSelectedItem,
  checkedFeatures,
  setCheckedFeatures,
  fetchRolesData,
  roleName,
  setRoleName,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const logout = useLogout();

  console.log(selectedItem);

  useEffect(() => {
    form.setFieldValue("role", roleName);
  }, [roleName]);

  console.log(roleName);

  const onFinish = async (values) => {
    console.log(values);
    const Endpoint = "editRoles";
    try {
      setLoading(true);
      const userData = {
        roleId: selectedItem?._id,
        roleName: values?.role,
        features: checkedFeatures,
      };
      const { data, loading: postLoading } = await putAuthApiData(
        Endpoint,
        userData
      );
      console.log("Role Updated successful:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data.message);
        close();
        message.success(data?.message);
        fetchRolesData();
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Role Update failed:", error);
      message.error(error);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleRemove = (itemToRemove) => {
    const updatedFeatures = checkedFeatures?.filter(
      (item) => item !== itemToRemove
    );
    // const updatedSelectedItem = { features: updatedFeatures };

    console.log(updatedFeatures);
    setCheckedFeatures(updatedFeatures);
  };

  let roleWarningShown = false; // Flag to track if the warning has been shown

  const validateRole = (_, value) => {
    // Check if the value contains invalid characters (anything other than letters and spaces)
    if (value && !/^[a-zA-Z\s]+$/.test(value)) {
      if (!roleWarningShown) {
        message.warning("Role must contain only letters and spaces");
        roleWarningShown = true; // Set the flag after showing the warning
      }
      return Promise.reject(
        new Error("Role must contain only letters and spaces")
      );
    }

    // Reset the flag if the input becomes valid
    roleWarningShown = false;

    // Check if the length is less than 2 characters
    if (value && value.length < 2) {
      return Promise.reject(new Error("Role must be at least 2 characters"));
    }

    // Check if the length is more than 16 characters
    if (value && value.length > 16) {
      return Promise.reject(new Error("Role must be at most 16 characters"));
    }

    return Promise.resolve();
  };

  const handleRoleChange = (e) => {
    const { value } = e.target;
    const cleanedValue = value.replace(/[^a-zA-Z\s]/g, "").slice(0, 17);
    form.setFieldsValue({ role: cleanedValue });
    setRoleName(cleanedValue);
  };
  return (
    <Spin spinning={loading}>
      <div className='edit-role-container'>
        <div className='edit-role-content'>
          <Form
            form={form}
            name='normal_login'
            className='edit-profile-form'
            layout='vertical'
            onFinish={onFinish}>
            <Form.Item
              name='role'
              label='Edit Role Name'
              initialValue={roleName}
              rules={[
                {
                  required: true,
                  message: "Please enter role name",
                },
                { validator: validateRole },
              ]}>
              <Input
                type='text'
                onChange={handleRoleChange}
                placeholder='Enter new role'
              />
            </Form.Item>
            <Form.Item
              name='selectedActivites'
              label={`Selected Activities (${checkedFeatures?.length})`}>
              <div
                style={{
                  width: "100%",
                  marginTop: "10px",
                }}>
                <Row style={{ marginBottom: "10px" }} gutter={8}>
                  {checkedFeatures?.length > 0
                    ? checkedFeatures?.map((item) => (
                        <Col span={8} style={{ marginBottom: "12px" }}>
                          <div className='activity-section'>
                            <p className='activity-name'>{item?.featureName}</p>{" "}
                            <ImCancelCircle
                              onClick={() => handleRemove(item)}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        </Col>
                      ))
                    : ""}
                </Row>
              </div>
            </Form.Item>
            <Form.Item>
              <div className='edit-role-submit'>
                <div
                  className='Add-activity'
                  onClick={() => {
                    close();
                    setAddActivity(true);
                  }}>
                  <IoMdAdd />
                  <p>Add Actvity</p>
                </div>
                <div className='edit-role-submit-actions'>
                  <div className='edit-role-cancel-button' onClick={close}>
                    <p>Cancel</p>
                  </div>
                  <button
                    type='primary'
                    htmlType='submit'
                    className='edit-role-submit-button'>
                    <p>Submit</p>
                  </button>
                </div>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Spin>
  );
};

export default EditRole;
