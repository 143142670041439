import React, { useState } from "react";
import { GrFormSearch } from "react-icons/gr";
import { FaPlus } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { DatePicker, Input, Modal, Space } from "antd";
import AddEmployeeModal from "./AddEmployee";
import AddEmployee from "./AddEmployee";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";

const { RangePicker } = DatePicker;

const EmployeeCard = ({
  rolesData,
  employeesData,
  activeCount,
  filter,
  setFilter,
  inactiveCount,
  searchInput,
  setSearchInput,
  setReportsDate,
  reportsDate,
  fetchEmployees,
  totalCount,
}) => {
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [showAddEmployeeModal, setShowAddEmployeeModal] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [AddEmploy, setAddEmploy] = useState(false);

  const handleFromDateChange = (date, dateString) => {
    setFromDate(date);
  };

  const handleToDateChange = (date, dateString) => {
    setToDate(date);
  };

  const handleFilterClick = (newFilter) => {
    setFilter(newFilter);
  };

  const handleAddEmployeeClick = () => {
    setShowAddEmployeeModal(true);
  };

  const close = () => {
    setAddEmploy(false);
  };

  const onDateChange = (dates, dateStrings) => {
    const formattedDates = dateStrings?.every((date) => date) // Check if all dates are non-empty
      ? dateStrings.map((date) =>
          moment(date, "DD-MM-YYYY").format("YYYY-MM-DD")
        )
      : []; // Set to empty array if any date is empty

    console.log("Selected dates:", dateStrings);
    console.log("Formatted dates (YYYY-MM-DD):", formattedDates);
    fetchEmployees(formattedDates);
    setReportsDate(formattedDates);
  };

  return (
    <div className='EmployeeCard_header-container'>
      <div className='EmployeeCard_left-side'>
        <div className='EmployeeCard_search-bar-Off'>
          <Input
            type='text'
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            suffix={<SearchOutlined className='search-icon' />}
            placeholder='Search Employee'
            className='EmployeeCard_search-input'
            style={{ minWidth: "100px" }}
          />
          <GrFormSearch size={20} className='EmployeeCard_search-icon' />
        </div>
        <div className='employeeCard_DatePicker'>
          <Space
            direction='Horizontal'
            style={{ width: "100%", minWidth: "240px" }}>
            <RangePicker
              style={{ width: "100%" }}
              format='DD-MM-YYYY'
              onChange={onDateChange}
            />
          </Space>
        </div>
        <div className='EmployeeCard_filter-buttons'>
          <button
            className={filter === "All" ? "active" : ""}
            onClick={() => handleFilterClick("All")}>
            ALL ({totalCount})
          </button>
          <button
            className={filter === "Active" ? "active" : ""}
            onClick={() => handleFilterClick("Active")}>
            ACTIVE ({activeCount})
          </button>
          <button
            className={filter === "Inactive" ? "active" : ""}
            onClick={() => handleFilterClick("Inactive")}>
            INACTIVE ({inactiveCount})
          </button>
        </div>
      </div>
      <div className='EmployeeCard_right-side'>
        <div className='EmployeeCard_Add_b'>
          <button
            className='EmployeeCard_add-scale-button'
            onClick={() => setAddEmploy(true)}>
            <FaPlus size={10} /> Add Employee
          </button>
        </div>
      </div>
      <Modal
        visible={AddEmploy}
        onCancel={() => setAddEmploy(false)}
        centered
        footer={null}
        className='custom-modal'>
        <AddEmployee
          close={close}
          rolesData={rolesData}
          fetchEmployeeList={fetchEmployees}
        />
      </Modal>
    </div>
  );
};

export default EmployeeCard;
