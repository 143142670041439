import { Form, Input, Checkbox, Col, Row, message } from "antd";

import React, { useEffect, useState } from "react";

const AddRole = ({
  close,
  setAddRoleReviewModal,
  setAddRoleModal,
  featuresData,
  setCheckedActivities,
  checkedActivities,
  roleName,
  setRoleName,
}) => {
  const [form] = Form.useForm();
  const [name, setName] = useState("");
  const [activities, setActivites] = useState([]);

  useEffect(() => {
    form.resetFields();
    console.log("UseEffect Call");
    setActivites([]);
  }, [close]);

  const onChange = (checkedValues) => {
    console.log("checked = ", checkedValues);
    setActivites(checkedValues);
  };
  console.log(checkedActivities);

  const onFinish = async (values) => {
    console.log(values);
    close();
    setRoleName(name);
    setCheckedActivities(activities);
    setAddRoleReviewModal(true);
  };
  console.log(checkedActivities);
  console.log(featuresData);

  let roleWarningShown = false; // Flag to track if the warning has been shown

  const validateRole = (_, value) => {
    // Check if the value contains invalid characters (anything other than letters and spaces)
    if (value && !/^[a-zA-Z\s]+$/.test(value)) {
      if (!roleWarningShown) {
        message.warning("Role must contain only letters and spaces");
        roleWarningShown = true; // Set the flag after showing the warning
      }
      return Promise.reject(
        new Error("Role must contain only letters and spaces")
      );
    }

    // Reset the flag if the input becomes valid
    roleWarningShown = false;

    // Check if the length is less than 2 characters
    if (value && value.length < 2) {
      return Promise.reject(new Error("Role must be at least 2 characters"));
    }

    // Check if the length is more than 16 characters
    if (value && value.length > 16) {
      return Promise.reject(new Error("Role must be at most 16 characters"));
    }

    return Promise.resolve();
  };

  const handleRoleChange = (e) => {
    const { value } = e.target;
    const cleanedValue = value.replace(/[^a-zA-Z\s]/g, "").slice(0, 17);
    form.setFieldsValue({ role: cleanedValue });
    setName(cleanedValue);
  };
  return (
    <div className='Add-role-container'>
      <div className='Add-role-content'>
        <h6 style={{ textAlign: "center", marginBottom: "10px" }}>
          Add New Role
        </h6>
        <Form
          form={form}
          name='normal_login'
          className='edit-profile-form'
          layout='vertical'
          onFinish={onFinish}>
          <Form.Item
            name='role'
            label='Add Role Name'
            rules={[
              {
                required: true,
                message: "Please enter role name",
              },
              { validator: validateRole },
            ]}>
            <Input type='text' onChange={handleRoleChange} placeholder="Enter new role"/>
          </Form.Item>
          <Form.Item name='checkbox'>
            <Checkbox.Group
              style={{
                width: "100%",
              }}
              onChange={onChange}>
              <Row style={{ marginBottom: "10px" }}>
                {featuresData?.length > 0
                  ? featuresData.map((item) => (
                      <Col
                        span={8}
                        style={{ marginBottom: "12px" }}
                        key={item._id}>
                        <Checkbox value={item}>{item?.featureName}</Checkbox>
                      </Col>
                    ))
                  : ""}
              </Row>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item>
            <div className='add-role-submit'>
              <div className='add-role-cancel-button' onClick={close}>
                <p>Cancel</p>
              </div>
              {activities?.length > 0 ? (
                <button
                  type='primary'
                  htmlType='submit'
                  className='add-role-submit-button'>
                  <p>Submit</p>
                </button>
              ) : (
                <div className='add-role-submit-btn'>
                  <p>Submit</p>
                </div>
              )}
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default AddRole;
