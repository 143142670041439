import React, { useEffect, useState } from "react";
import { Avatar, Switch, Modal, Table, message, Spin, Pagination } from "antd";
import { MdOutlineEdit } from "react-icons/md";
import ShopKeeperEdit from "./ShopKeeperEdit";
import CategoryIamge from "../Categories/CategoryImage.png";
import { postAuthApiData } from "../../api/apiService";
import { useNavigate } from "react-router-dom";
import useLogout from "../../utils/authUtils";

const ShopKeeperTable = ({ data, fetchShopKeepersList }) => {
  const [editModal, setEditModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [ShopkeeperActive, setShopkeeperActive] = useState(false);
  const [ShopkeeperDeactive, setShopkeeperDeactive] = useState(false);
  const [activeSuccess, setActiveSuccess] = useState(false);
  const [deactiveSuccess, setDeactiveSuccess] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [swicthAction, setSwitchAction] = useState(false);
  const [filter, setFilter] = useState("All");
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const logout = useLogout();
  const pageSize = 10;

  const onChange = (item) => {
    console.log(item);
    setSelectedItem(item);
    if (item?.status === "active") {
      setShopkeeperActive(true);
    } else {
      setShopkeeperDeactive(true);
    }
  };

  const closeModal = () => {
    setEditModal(false);
  };

  const toggleStatus = async () => {
    const endpoint = "toggle-shopkeeper-status";
    const status = selectedItem?.status;
    try {
      setLoading(true);
      const shopkeeperData = {
        shopkeeperId: selectedItem?._id,
      };
      const { data, loading: postLoading } = await postAuthApiData(
        endpoint,
        shopkeeperData
      );
      console.log("Shopkeeper Status Toggling successful:", data);
      setLoading(false);
      if (data.responseCode === 200) {
        message.success(data.message);
        fetchShopKeepersList();
        setShopkeeperActive(false);
        setShopkeeperDeactive(false);
        if (status === "active") {
          setDeactiveSuccess(true);
        } else if (status === "inactive") {
          setActiveSuccess(true);
        }
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Shopkeeper Status Toggling failed:", error);
      message.error(error?.error?.error?.message);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  console.log(data);

  const handleChangePage = (page) => {
    console.log(page);
    setCurrentPage(page);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [data]);

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = data?.slice(startIndex, endIndex);
  console.log(paginatedData);
  console.log(startIndex, endIndex);

  return (
    <div className='shopkeeper-table-container'>
      <div className='shopkeeper-table-content'>
        <table className='shopkeeper_table'>
          <thead className='shopkeeper_table-head'>
            <tr>
              <th className='shopkeeper_serial-no'>S.No</th>
              <th className='shopkeeper_name'>Name of the shopkeeper</th>
              <th className='shopkeeper_shopname'>Shop Name</th>
              <th className='shopkeeper_shopkeeperid'>Shop Keeper Id</th>
              <th className='shopkeeper_contact'>Contact</th>
              <th className='shopkeeper_Email'>E-mail</th>
              <th className='shopkeeper_address'>Address</th>
              <th className='shopkeeper_status'>Status</th>
              <th className='shopkeeper_booking-history'>Booking History</th>
              <th className='shopkeeper_action'>Action</th>
            </tr>
          </thead>
          {paginatedData?.map((shopkeeper, index) => (
            <tbody className='shopkeeper_table-body'>
              <tr
                key={shopkeeper._id}
                className={swicthAction ? "" : "body-color"}>
                <td>{startIndex + index + 1}</td>
                <td>
                  <div className='shopkeeper_exacutive-section capital'>
                    {shopkeeper?.image ? (
                      <Avatar src={shopkeeper?.image} size={40} />
                    ) : (
                      <Avatar
                        shape='circle'
                        size={40}
                        style={{
                          backgroundColor: "#F1ECFF",
                          fontWeight: 600,
                          fontSize: 17,
                          color: "#6750A4",
                          // border: "1px solid #6750A4",
                        }}>
                        {shopkeeper?.name.length > 0 &&
                          shopkeeper?.name[0]?.charAt(0)?.toUpperCase()}
                      </Avatar>
                    )}
                    {shopkeeper?.name}
                  </div>
                </td>
                <td className='capital'>{shopkeeper?.shopName || "-"}</td>
                <td>{shopkeeper?.ShopkeeperId || "-"}</td>
                <td>{shopkeeper?.phone || "-"}</td>
                <td>{shopkeeper?.email || "-"}</td>
                <td className='shopkeeper_address capital'>
                  {shopkeeper?.address || "-"}
                </td>
                <td>
                  <Switch
                    className='shopkeeper_antSwitch'
                    checked={shopkeeper?.status === "active" ? true : false}
                    onChange={() => onChange(shopkeeper)}
                  />
                </td>
                <td>
                  <button
                    className='shopkeeper_view-button'
                    onClick={() =>
                      navigate("/shopKeeper-history", {
                        state: { id: shopkeeper?._id, item: shopkeeper },
                      })
                    }>
                    View
                  </button>
                </td>
                <td>
                  <div>
                    <span>
                      <MdOutlineEdit
                        className='shopkeeper_edit-icon'
                        size={20}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (shopkeeper?.status === "active") {
                            setSelectedItem(shopkeeper);
                            setEditModal(true);
                          }
                        }}
                      />
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          ))}
        </table>
        {data?.length === 0 ? (
          <div className='no-data-message'>
            <p>No data found</p>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className='Pagination-container'>
        {data?.length > 0 && (
          <Pagination
            simple
            current={currentPage}
            pageSize={pageSize}
            total={data?.length}
            onChange={handleChangePage}
          />
        )}
      </div>
      <Modal
        visible={editModal}
        onCancel={() => setEditModal(false)}
        closeIcon={false}
        centered
        footer={null}
        className='custom-modal'>
        <ShopKeeperEdit
          setEditModal={setEditModal}
          selectedItem={selectedItem}
          fetchShopKeepersList={fetchShopKeepersList}
        />
      </Modal>

      <Modal
        visible={ShopkeeperActive}
        onCancel={() => setShopkeeperActive(false)}
        centered
        footer={null}
        className='custom-modal'>
        <Spin spinning={loading}>
          <div className='role-active-container'>
            <div className='role-active-content'>
              <h5 className='head-text'>
                Do you want to{" "}
                <span className='Secondry-color'>Deactivate</span> this
                Shopkeeper?
              </h5>
              <div className='role-active-actions'>
                <button
                  className='role-active-no'
                  onClick={() => setShopkeeperActive(false)}>
                  <p>No</p>
                </button>
                <button
                  className='role-active-delete'
                  onClick={() => {
                    toggleStatus();
                  }}>
                  <p>Yes</p>
                </button>
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
      <Modal
        visible={ShopkeeperDeactive}
        onCancel={() => setShopkeeperDeactive(false)}
        centered
        footer={null}
        className='custom-modal'>
        <Spin spinning={loading}>
          <div className='role-active-container'>
            <div className='role-active-content'>
              <h5>
                Are you want to <span className='primary-color'>Active</span>{" "}
                this Shopkeeper
              </h5>
              <div className='role-active-actions'>
                <button
                  className='role-active-no'
                  onClick={() => setShopkeeperDeactive(false)}>
                  <p>No</p>
                </button>
                <button
                  className='role-active-delete'
                  onClick={() => {
                    toggleStatus();
                  }}>
                  <p>Yes</p>
                </button>
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
      <Modal
        visible={activeSuccess}
        onCancel={() => setActiveSuccess(false)}
        centered
        footer={null}
        className='custom-modal'>
        <div className='active-success-container'>
          <div className='active-success-content'>
            <div>
              <img src='assets/images/success_image.png' alt='' />
            </div>
            <h5>
              Shopkeeper Activated <br />
              Successfully
            </h5>
          </div>
        </div>
      </Modal>
      <Modal
        visible={deactiveSuccess}
        onCancel={() => setDeactiveSuccess(false)}
        centered
        footer={null}
        className='custom-modal'>
        <div className='active-success-container'>
          <div className='active-success-content'>
            <div>
              <img src='assets/images/success_image.png' alt='' />
            </div>
            <h5>
              Shopkeeper Deactivated <br />
              Successfully
            </h5>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ShopKeeperTable;
