import { Avatar, Modal, Switch, message, Pagination, Button } from "antd";
import React, { useEffect, useState } from "react";
import { MdOutlineEdit } from "react-icons/md";
import CategoryIamge from "../Categories/CategoryImage.png";
import SKUContent from "./SKUContent";
import { postAuthApiData } from "../../api/apiService";
import SKUPacketContent from "./SKUPacketContent";
import useLogout from "../../utils/authUtils";
import { FiRefreshCw } from "react-icons/fi";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const ProductList = ({
  productData,
  setLoading,
  setError,
  fetchProductList,
  setProductId,
  skuProductId,
  setSkuProductId,
  handleSkuEdit,
  setAddSkuId,
  setAddSkuData,
  loading,
  // triggerClear,
}) => {
  const [SKUModalVisible, setSKUModalVisible] = useState(false);
  const [SkuPacketModalVisible, setSkuPacketModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [skuData, setSkuData] = useState([]);
  const pageSize = 10;
  const [selectedProductId, setSelectedProductId] = useState(null);
  const logout = useLogout();
  const navigate = useNavigate();

  const onChange3 = (id) => {
    toggleStatus(id);
  };

  const toggleStatus = async (id) => {
    const Endpoint = "toggleProductStatus";
    try {
      setLoading(true);
      const userData = {
        productId: id,
      };
      const { data } = await postAuthApiData(Endpoint, userData);
      setLoading(false);
      if (data.responseCode === 200) {
        message.success(data?.message);
        fetchProductList();
      } else if (data.responseCode === 400) {
        console.log(data.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Product Status Toggling failed:", error);
      message.error(error?.error?.error?.message);
      setLoading(false);
      setError(error);
    }
  };

  const handleEditSkuModal = (productId) => {
    setSkuProductId(productId);
    setSelectedProductId(productId); // Set the selected product ID
    setSKUModalVisible(true);
  };

  const handleSkuPacket = (productId) => {
    setSkuProductId(productId);
    setSelectedProductId(productId); // Set the selected product ID
    setSkuPacketModalVisible(true);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = productData?.slice(startIndex, endIndex);

  useEffect(() => {
    if (skuProductId) {
      fetchProductBySkuId();
    }
  }, [skuProductId, setSkuProductId]);

  const fetchProductBySkuId = async () => {
    const Endpoint = "getAllListOfSKUS";
    try {
      setLoading(true);
      const userData = {
        productId: skuProductId,
      };
      const { data, loading: postLoading } = await postAuthApiData(
        Endpoint,
        userData
      );
      console.log("Product By Id fetched successful:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data.message);
        setSkuData(data?.data);
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Product By Id fetched failed:", error);
      message.error(error?.error?.error?.message);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddProduct = () => {
    navigate("/products/add-product", {
      state: {
        productId: "",
        skuEditData: {},
        addSkuData: [],
      },
    });
  };

  return (
    <div className='product-list-content'>
      <div className='Header-section'>
        <p className='product-list-header'>Product List</p>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <button
            className='refresh-btn'
            onClick={() => {
              fetchProductList();
              // triggerClear();
            }}>
            <FiRefreshCw />
            Refresh
          </button>
          <button
            className='add-product-btn'
            onClick={() => {
              handleAddProduct();
            }}>
            <PlusOutlined />
            Add product
          </button>
        </div>
      </div>
      <div className='product-list-table'>
        <table>
          <thead>
            <tr>
              <th className='th1'>S.No</th>
              <th className='th2'>Product Name</th>
              <th className='th3'>Description</th>
              <th className='th4'>SKU</th>
              <th className='th5'>Packets</th>
              <th className='th6'>Status</th>
              <th className='th7'>Edit</th>
            </tr>
          </thead>
          {productData?.length > 0
            ? paginatedData?.map((item, index) => (
                <tbody key={item?.product?._id}>
                  <tr
                    className={
                      item?.product?.productStatus === "active"
                        ? ""
                        : "body-color"
                    }>
                    <td>{startIndex + index + 1}</td>
                    <td>
                      <div
                        className='executive-section'
                        style={{ textTransform: "capitalize" }}>
                        {item?.product?.productImage ? (
                          <Avatar
                            src={item?.product?.productImage}
                            size={40}
                            className='image'
                            // style={{
                            //   backgroundColor: "#F1ECFF",
                            //   fontWeight: 600,
                            //   fontSize: 17,
                            //   color: "#6750A4",
                            //   // border: "1px solid #6750A4",
                            // }}
                          />
                        ) : (
                          <Avatar
                            shape='circle'
                            className='image'
                            size={40}
                            style={{
                              backgroundColor: "#F1ECFF",
                              fontWeight: 600,
                              fontSize: 17,
                              color: "#6750A4",
                              // border: "1px solid #6750A4",
                            }}>
                            {item?.product?.productName?.length > 0 &&
                              item?.product?.productName[0]
                                ?.charAt(0)
                                ?.toUpperCase()}
                          </Avatar>
                        )}
                        {item?.product?.productName}
                      </div>
                    </td>
                    <td>{item?.product?.description}</td>
                    <td
                      style={{ color: "#2563EB", cursor: "pointer" }}
                      onClick={() => handleEditSkuModal(item?.product?._id)}>
                      {item?.skuCount}
                    </td>
                    <td
                      style={{ color: "#2563EB", cursor: "pointer" }}
                      onClick={() => handleSkuPacket(item?.product?._id)}>
                      {item?.packetsCount}
                    </td>
                    <td>
                      <Switch
                        checked={item?.product?.productStatus === "active"}
                        onChange={() => onChange3(item?.product?._id)}
                      />
                    </td>
                    <td>
                      <MdOutlineEdit
                        className='edit-icon'
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (item?.product?.productStatus === "active") {
                            setProductId(item?.product?._id);
                            navigate("/products/add-product", {
                              state: {
                                productId: item?.product?._id,
                                skuEditData: {},
                                addSkuData: [],
                              },
                            });
                          }
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              ))
            : ""}
        </table>
        {productData?.length === 0 ? (
          <div className='no-data-message'>
            <p>No data found</p>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className='Pagination-container'>
        {productData?.length > 0 && (
          <Pagination
            simple
            current={currentPage}
            pageSize={pageSize}
            total={productData?.length}
            onChange={handleChangePage}
          />
        )}
      </div>
      <Modal
        visible={SKUModalVisible && selectedProductId === skuProductId}
        centered
        footer={null}
        style={{ minWidth: "620px" }}
        onCancel={() => setSKUModalVisible(false)}
        className='custom-modal'
        closeIcon={null}>
        <SKUContent
          skuProductId={skuProductId}
          handleSkuEdit={handleSkuEdit}
          setSKUModalVisible={setSKUModalVisible}
          setSkuProductId={setSkuProductId}
          // api={fetchProductBySkuId}
          // data={skuData}
          setAddSkuId={setAddSkuId}
          setAddSkuData={setAddSkuData}
          skuData={skuData}
          setSkuData={setSkuData}
          fetchProductBySkuId={fetchProductBySkuId}
          loading={loading}
          setLoading={setLoading}
        />
      </Modal>
      <Modal
        visible={SkuPacketModalVisible && selectedProductId === skuProductId}
        centered
        footer={null}
        onCancel={() => setSkuPacketModalVisible(false)}
        className='custom-modal'
        closeIcon={null}>
        <SKUPacketContent
          skuProductId={skuProductId}
          setSkuPacketModal={setSkuPacketModalVisible}
          fetchProductList={fetchProductList}
          setSkuProductId={setSkuProductId}
          skuData={skuData}
          setSkuData={setSkuData}
          fetchProductBySkuId={fetchProductBySkuId}
          loading={loading}
          setLoading={setLoading}
        />
      </Modal>
    </div>
  );
};

export default ProductList;
