import React, { useEffect, useState } from "react";
import { Input, Button, Table, Pagination, Avatar, Modal, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { MdOutlinePictureAsPdf } from "react-icons/md";
import { ImPrinter } from "react-icons/im";
import * as XLSX from "xlsx";
import moment from "moment";

const { Option } = Select;
const OrderManagementBody = ({
  filter,
  setFilter,
  data,
  returnsCount,
  dispatchedCount,
  deliveredCount,
  searchInput,
  totalCount,
  setSearchInput,
  ordersList,
  setDamagedCount,
  setDeliveredCount,
  setDispatchedCount,
  setOrdersCount,
  setTotalCount,
  setReturnsCount,
}) => {
  const onExportLocal = () => {
    // Check if the file has already been downloaded in this session
    if (localStorage.getItem("OrdersDataDownloaded")) {
      const downloadAgain = confirm(
        "The file has already been downloaded. Do you want to download it again?"
      );
      if (!downloadAgain) return; // Exit if the user doesn't want to re-download
    }

    const exportData = data.map((item, index) => ({
      "S.No": index + 1,
      "Order ID": item.bookingId,
      Customer: item.customerName,
      Date: item?.date ? moment(item.date).format("DD-MM-YYYY") : "--",
      Price: item.totalInfoPrice,
      "Quantity(KG)":
        item?.totalQuantityOfProducts !== 0
          ? `${item?.totalQuantityOfProducts?.toFixed(1)} Kg [${
              item?.totalPackets
            } Pkt]`
          : `${item?.totalPackets} Pkt`,
      "Units/Packets": item.totalPackets,
      "Payment Mode": item.paymentMethod,
      Status: item.orderStatus,
    }));

    // Add header row
    const headers = [
      "S.No",
      "Order ID",
      "Customer",
      "Date",
      "Price",
      "Quantity(KG)",
      "Units/Packets",
      "Payment Mode",
      "Status",
    ];

    const formattedData = [headers, ...exportData.map(Object.values)];

    // Create a worksheet
    const ws = XLSX.utils.aoa_to_sheet(formattedData);

    // Set column widths
    const columnWidths = [
      { wch: 5 }, // S.No
      { wch: 25 }, // Order ID
      { wch: 30 }, // Customer
      { wch: 15 }, // Date
      { wch: 10 }, // Price
      { wch: 15 }, // Quantity(KG)
      { wch: 15 }, // Units/Packets
      { wch: 20 }, // Payment Mode
      { wch: 25 }, // Status
    ];
    ws["!cols"] = columnWidths;

    // Create a workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Orders");

    // Save the file
    XLSX.writeFile(wb, "OrdersData.xlsx");

    // Set a flag in localStorage to mark the file as downloaded
    localStorage.setItem("OrdersDataDownloaded", "true");
  };

  const handleFilterClick = (newFilter) => {
    setFilter(newFilter);
  };
  console.log(data?.length);

  // useEffect(() => {
  //   if (searchInput) {
  //     console.log("Calling useEffect");
  //     const disptchedProducts = ordersList?.filter(
  //       (product) => product.orderStatus === "Packing Completed"
  //     );
  //     const deliveredProducts = ordersList?.filter(
  //       (product) => product.orderStatus === "Delivered"
  //     );
  //     const returnProducts = ordersList?.filter(
  //       (product) => product.orderStatus === "Return"
  //     );
  //     const damagedProducts = ordersList?.filter(
  //       (product) => product.orderStatus === "Damaged"
  //     );
  //     setTotalCount(ordersList?.length);
  //     setDamagedCount(damagedProducts?.length);
  //     setDeliveredCount(deliveredProducts?.length);
  //     setDispatchedCount(disptchedProducts?.length);
  //     setReturnsCount(returnProducts?.length);
  //   }
  // }, [ordersList]);

  console.log(returnsCount, totalCount, dispatchedCount, returnsCount);

  return (
    <div className='Orderm-mainCointainer'>
      <div className='Orderm-management-header'>
        <div className='Orderm-header-left-contant'>
          <div className='Orderm_search-bar'>
            <Input
              type='search'
              placeholder='Search '
              className='Orderm-search-input'
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              suffix={<SearchOutlined className='search-icon' />}
            />
          </div>
          <div className='Orderm_filter-buttons'>
            <button
              className={filter === "All" ? "active" : ""}
              onClick={() => handleFilterClick("All")}>
              ALL ({totalCount})
            </button>
            <button
              className={filter === "Dispatched" ? "active" : ""}
              onClick={() => handleFilterClick("Dispatched")}>
              Packed ({dispatchedCount})
            </button>
            <button
              className={filter === "Delivered" ? "active" : ""}
              onClick={() => handleFilterClick("Delivered")}>
              Delivered ({deliveredCount})
            </button>
            <button
              className={filter === "Returns" ? "active" : ""}
              onClick={() => handleFilterClick("Returns")}>
              Returns ({returnsCount})
            </button>
          </div>
        </div>
        <div className='Orderm-header-right-contant'>
          <div className='Orderm_PDF_B'>
            <button className='Orderm-pdf-btn' onClick={onExportLocal}>
              <MdOutlinePictureAsPdf size={15} className='Orderm_pdf-icon' />{" "}
              Export to CSV
            </button>
          </div>
          {/* <div className='Orderm_Print_b'>
            <button className='Orderm_print-button' onClick={handlePrint}>
              <ImPrinter size={15} className='Orderm_print-icon' /> Print
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default OrderManagementBody;
