import React, { useEffect, useState } from "react";
import { BsCardChecklist } from "react-icons/bs";
import { FaBoxArchive } from "react-icons/fa6";
import { MdDeliveryDining } from "react-icons/md";
import { MdMoney } from "react-icons/md";
import { Select } from "antd";
import { DatePicker, Space } from "antd";
import moment from "moment";
const { RangePicker } = DatePicker;

const OrderManagementCards = ({
  ordersList,
  filterDate,
  setFilterDate,
  fetchOrdersManagementsList,
}) => {
  const { Option } = Select;

  const [dispatchedCount, setDispatchedCount] = useState(0);
  const [deliveredCount, setDeliveredCount] = useState(0);
  const [returnsCount, setReturnsCount] = useState(0);
  const [damagedCount, setDamagedCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const onDateChange = (dates, dateStrings) => {
    const formattedDates = dateStrings?.every((date) => date) // Check if all dates are non-empty
      ? dateStrings.map((date) =>
          moment(date, "DD-MM-YYYY").format("YYYY-MM-DD")
        )
      : []; // Set to empty array if any date is empty

    console.log("Selected dates:", dateStrings);
    console.log("Formatted dates (YYYY-MM-DD):", formattedDates);
    setFilterDate(formattedDates);
    fetchOrdersManagementsList(formattedDates);
  };

  useEffect(() => {
    console.log("Calling useEffect");
    const disptchedProducts = ordersList?.filter(
      (product) => product.orderStatus === "Packing Completed"
    );
    const deliveredProducts = ordersList?.filter(
      (product) => product.orderStatus === "Delivered"
    );
    const returnProducts = ordersList?.filter(
      (product) => product.orderStatus === "Return"
    );
    const damagedProducts = ordersList?.filter(
      (product) => product.orderStatus === "Damaged"
    );
    setTotalCount(ordersList?.length);
    setDamagedCount(damagedProducts?.length);
    setDeliveredCount(deliveredProducts?.length);
    setDispatchedCount(disptchedProducts?.length);
    setReturnsCount(returnProducts?.length);
  }, [ordersList]);

  return (
    <div className='orderM-card-container'>
      <div className='orderM-card-contant'>
        <div className='orderM-card' style={{ backgroundColor: "#fff5dd" }}>
          <div className='orderM-card-header'>
            <span className='orderM-card-title'>Total Orders</span>
            <BsCardChecklist className='orderM-card-icon' />
          </div>
          <div className='orderM-card-body'>{totalCount}</div>
        </div>
        <div className='orderM-card' style={{ backgroundColor: "#e6ddff" }}>
          <div className='orderM-card-header'>
            <span className='orderM-card-title'>Dispatched</span>
            <FaBoxArchive className='orderM-card-icon' />
          </div>
          <div className='orderM-card-body'>{dispatchedCount}</div>
        </div>
        <div className='orderM-card' style={{ backgroundColor: "#ffd1f5" }}>
          <div className='orderM-card-header'>
            <span className='orderM-card-title'>Delivered</span>
            <MdDeliveryDining className='orderM-card-icon' />
          </div>
          <div className='orderM-card-body'>{deliveredCount}</div>
        </div>
        <div className='orderM-card' style={{ backgroundColor: "#ffd3c6" }}>
          <div className='orderM-card-header'>
            <span className='orderM-card-title'>Returns</span>
            <MdMoney className='orderM-card-icon' />
          </div>
          <div className='orderM-card-body'>{returnsCount}</div>
        </div>
      </div>
      <div className='orderM-card-dropdown'>
        <RangePicker
          style={{ width: "100%" }}
          format='DD-MM-YYYY'
          onChange={onDateChange}
        />
      </div>
    </div>
  );
};

export default OrderManagementCards;
