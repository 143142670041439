import React, { useEffect, useState } from "react";
import { Avatar, Layout, Menu, message } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  UserOutlined,
  VideoCameraOutlined,
  LogoutOutlined,
  RightSquareOutlined,
  DownSquareOutlined,
} from "@ant-design/icons";
import "./Sidebar.css";
import { MdAddShoppingCart, MdOutlineShoppingCart } from "react-icons/md";
import { RxDashboard } from "react-icons/rx";
import { MdCategory } from "react-icons/md";
import productIcon from "./products-icon.png";
import { SlControlEnd, SlOptionsVertical } from "react-icons/sl";
import { LuPlane, LuUsers } from "react-icons/lu";
import { GoProject, GoProjectRoadmap } from "react-icons/go";
import { MdOutlineFactCheck } from "react-icons/md";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser, updatingSelectedPujas } from "../../../redux/action";
import {
  FaArrowUpRightDots,
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa6";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { postAuthApiData } from "../../../api/apiService";
import useLogout from "../../../utils/authUtils";
import { FcBusinessman } from "react-icons/fc";
import { BiUserCircle } from "react-icons/bi";
import { AiOutlineTrademarkCircle } from "react-icons/ai";
import { GrGroup } from "react-icons/gr";
import { PiUserCircleGearLight } from "react-icons/pi";

const { Sider } = Layout;
const SubMenu = Menu.SubMenu;

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [mainBoardsOpen, setMainBoardsOpen] = useState(false);
  const [employerBoardOpen, setEmployerBoardOpen] = useState(false);
  const [salesOpen, setSalesOpen] = useState(false);
  const [customersOpen, setCustomersOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [sideBarData, setSideBarData] = useState({});
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logout = useLogout();

  const profileDetails = useSelector((state) => state.userProfile);
  console.log(profileDetails);
  const roleId = localStorage.getItem("roleId");
  console.log(roleId);

  const fetchSidabarApi = async () => {
    const Endpoint = "sideBarAPi";
    try {
      setLoading(true);
      const userData = {
        roleId: profileDetails?.roleId || roleId,
      };
      const { data, loading: postLoading } = await postAuthApiData(
        Endpoint,
        userData
      );
      console.log("Side bar api fetched successful:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data.message);
        setSideBarData(data?.data);
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Side bar api fetched failed:", error);
      message.error("Something went wrong please try later");
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchSidabarApi();
  }, []);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const toggleMainBoards = () => {
    setMainBoardsOpen(!mainBoardsOpen);
  };

  const toggleEmployerBoard = () => {
    setEmployerBoardOpen(!employerBoardOpen);
  };

  const toggleSales = () => {
    setSalesOpen(!salesOpen);
  };

  const toggleCustomers = () => {
    setCustomersOpen(!customersOpen);
  };

  const path = location.pathname;
  const selectedKeys = path.substr(1);

  const handleMenuItemSelect = (menuItemKey) => {
    console.log(menuItemKey);
    dispatch(updatingSelectedPujas(menuItemKey));
  };

  const selectedPuja = useSelector((state) => state.selectedPujas);
  console.log(selectedPuja);
  // Side bar Code

  // const logout = () => {
  //   localStorage.removeItem("jpf_token");
  //   localStorage.removeItem("orderBookingId");
  //   localStorage.removeItem("onlineOrderBookingId");
  //   dispatch(logoutUser());
  //   navigate("/");
  //   message.success("User logged-out successfully");
  // };

  console.log(sideBarData);

  const iconMap = {
    RxDashboard: RxDashboard,
    MdCategory: MdCategory,
    MdAddShoppingCart: MdAddShoppingCart,
    BiUserCircle: BiUserCircle,
    AiOutlineTrademarkCircle: AiOutlineTrademarkCircle,
    LuUsers: LuUsers,
    GoProject: GoProject,
    GoProjectRoadmap: GoProjectRoadmap,
    MdOutlineShoppingCart: MdOutlineShoppingCart,
    MdOutlineFactCheck: MdOutlineFactCheck,
    GrGroup: GrGroup,
  };

  const featureMain = [
    "Dashboard",
    "Categories",
    "Products",
    "Order Management",
    "Stock Management",
  ];

  const sortedMainFeatures = sideBarData?.features?.sort((a, b) => {
    return (
      featureMain.indexOf(a.featureName) - featureMain.indexOf(b.featureName)
    );
  });

  return (
    <Sider className='sidebar' collapsed={collapsed} width={250}>
      <div className='menu-container'>
        <div className='top-logo-menu'>
          <div className='logo' onClick={toggleCollapsed}>
            <img
              src='https://mrsasta.s3.eu-north-1.amazonaws.com/1729578893906_JPF_LOGO .png'
              alt='logo'
              className='logo'
            />
          </div>
          <div className={collapsed ? "xtra display" : "xtra"}>
            <p className='para-text'>JPF</p>
            <h6 style={{ color: "white" }}>Workspace</h6>
          </div>
        </div>
        {sideBarData?.roleName === "Admin" ? (
          <Menu
            theme='dark'
            mode='inline'
            selectedKeys={[selectedKeys]}
            className='Sidebar-menu'>
            <Menu.Item
              key='notification'
              icon={<UserOutlined />}
              onClick={() => handleMenuItemSelect("Notifications")}>
              <span style={{ paddingLeft: 9 }}>
                <Link to='/notification'>Notifications</Link>
              </span>
            </Menu.Item>
            <div className='underline' />
            <SubMenu
              key='MainBoards'
              title={
                <span
                  onClick={toggleMainBoards}
                  style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src='http://mrsasta.s3.eu-north-1.amazonaws.com/1731996566333_Frame.png'
                    alt='user'
                    height={16}
                    width={16}
                  />

                  <span
                    style={{
                      color: "white",
                      marginLeft: "10px",
                      fontWeight: "600",
                      display: collapsed ? "none" : "inline",
                    }}>
                    Main Boards
                  </span>
                </span>
              }>
              <Menu.Item
                key='dashboard'
                icon={<RxDashboard />}
                onClick={() => handleMenuItemSelect("Dashboard")}>
                <Link to='/dashboard'>Dashboard</Link>
              </Menu.Item>
              <Menu.Item
                key='categories'
                icon={<MdCategory />}
                onClick={() => handleMenuItemSelect("Categories")}>
                <Link to='/categories'>Categories</Link>
              </Menu.Item>
              <Menu.Item
                key='products'
                icon={<MdAddShoppingCart />}
                onClick={() => handleMenuItemSelect("Products")}>
                <Link to='/products'>Products</Link>
              </Menu.Item>
              <Menu.Item
                key='orderManagement'
                icon={<MdOutlineShoppingCart />}
                onClick={() => handleMenuItemSelect("Order Management")}>
                <Link to='/orderManagement'>Order Management</Link>
              </Menu.Item>
              <Menu.Item
                key='stockManagement'
                icon={<MdOutlineFactCheck />}
                onClick={() => handleMenuItemSelect("Stock Management")}>
                <Link to='/stockManagement'>Stock Management</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key='EmployerBoard'
              title={
                <span
                  onClick={toggleEmployerBoard}
                  style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src='http://mrsasta.s3.eu-north-1.amazonaws.com/1731996477645_mingcute_group-3-line.png'
                    alt='user'
                    height={18}
                    width={18}
                  />

                  <span
                    style={{
                      color: "white",
                      marginLeft: "10px",
                      fontWeight: "600",
                      display: collapsed ? "none" : "inline",
                    }}>
                    Employee Boards
                  </span>
                </span>
              }>
              <Menu.Item
                key='role'
                icon={<AiOutlineTrademarkCircle />}
                onClick={() => handleMenuItemSelect("Role")}>
                <Link to='/role'>Role</Link>
              </Menu.Item>
              <Menu.Item
                key='employee'
                icon={<BiUserCircle />}
                onClick={() => handleMenuItemSelect("Employee Boards")}>
                <Link to='/employee'>Employee</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key='Sales'
              title={
                <span
                  onClick={toggleSales}
                  style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src='http://mrsasta.s3.eu-north-1.amazonaws.com/1731996355567_fluent_arrow-growth-20-filled.png'
                    alt='user'
                    height={18}
                    width={18}
                  />

                  <span
                    style={{
                      color: "white",
                      marginLeft: "10px",
                      fontWeight: "600",
                      display: collapsed ? "none" : "inline",
                    }}>
                    Sales Management
                  </span>
                </span>
              }>
              <Menu.Item
                key='offlineSales'
                icon={<GoProject />}
                onClick={() => handleMenuItemSelect("Offline Sales")}>
                <Link to='/offlineSales'>Offline Sales</Link>
              </Menu.Item>
              <Menu.Item
                key='onlineSales'
                icon={<GoProjectRoadmap />}
                onClick={() => handleMenuItemSelect("Online Sales")}>
                <Link to='/onlineSales'>Online Sales</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key='Customers'
              title={
                <span
                  onClick={toggleCustomers}
                  style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src='http://mrsasta.s3.eu-north-1.amazonaws.com/1731996056332_oui_app-users-roles.png'
                    alt='user'
                    height={18}
                    width={18}
                  />

                  <span
                    style={{
                      color: "white",
                      marginLeft: "10px",
                      fontWeight: "600",
                      display: collapsed ? "none" : "inline",
                    }}>
                    Customers
                  </span>
                </span>
              }>
              <Menu.Item
                key='customers'
                icon={<LuUsers />}
                onClick={() => handleMenuItemSelect("Customers")}>
                <Link to='/customers'>Customers</Link>
              </Menu.Item>
              <Menu.Item
                key='shopKeeper'
                icon={<GrGroup />}
                onClick={() => handleMenuItemSelect("Shop Keepers")}>
                <Link to='/shopKeeper'>Shop Keepers</Link>
              </Menu.Item>
            </SubMenu>
          </Menu>
        ) : (
          <Menu
            theme='dark'
            mode='inline'
            selectedKeys={[selectedKeys]}
            className='Sidebar-menu'>
            <Menu.Item
              key='notification'
              icon={<UserOutlined />}
              onClick={() => handleMenuItemSelect("Notifications")}>
              <span style={{ paddingLeft: 9 }}>
                <Link to='/notification'>Notifications</Link>
              </span>
            </Menu.Item>
            <div className='underline' />
            {sideBarData?.features &&
              sideBarData?.features?.some((feature) =>
                [
                  "Dashboard",
                  "Categories",
                  "Products",
                  "Order Management",
                  "Stock Management",
                ]?.includes(feature?.featureName)
              ) && (
                <SubMenu
                  key='MainBoards'
                  title={
                    <span
                      onClick={toggleMainBoards}
                      style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src='http://mrsasta.s3.eu-north-1.amazonaws.com/1731996566333_Frame.png'
                        alt='user'
                        height={16}
                        width={16}
                      />

                      <span
                        style={{
                          color: "white",
                          marginLeft: "10px",
                          display: collapsed ? "none" : "inline",
                          fontWeight: "600",
                        }}>
                        Main Boards
                      </span>
                    </span>
                  }>
                  {sortedMainFeatures?.map(
                    (feature) =>
                      featureMain.includes(feature?.featureName) && (
                        <Menu.Item
                          key={feature?.endPoint}
                          icon={
                            iconMap[feature?.icon]
                              ? React.createElement(iconMap[feature?.icon])
                              : null
                          }
                          onClick={() =>
                            handleMenuItemSelect(feature?.featureName)
                          }>
                          <Link to={`/${feature?.endPoint}`}>
                            {feature?.featureName}
                          </Link>
                        </Menu.Item>
                      )
                  )}
                </SubMenu>
              )}
            {sideBarData?.features &&
              sideBarData?.features?.some((feature) =>
                ["Role", "Employee"]?.includes(feature?.featureName)
              ) && (
                <SubMenu
                  key='EmployerBoard'
                  title={
                    <span
                      onClick={toggleEmployerBoard}
                      style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src='http://mrsasta.s3.eu-north-1.amazonaws.com/1731996477645_mingcute_group-3-line.png'
                        alt='user'
                        height={18}
                        width={18}
                      />
                      <span
                        style={{
                          color: "white",
                          marginLeft: "10px",
                          display: collapsed ? "none" : "inline",
                          fontWeight: "600",
                        }}>
                        Employee Boards
                      </span>
                    </span>
                  }>
                  {sideBarData?.features?.map(
                    (feature) =>
                      ["Role", "Employee"]?.includes(feature?.featureName) && (
                        <Menu.Item
                          key={feature?.endPoint} // Assuming featureName is unique
                          icon={
                            iconMap[feature?.icon]
                              ? React.createElement(iconMap[feature?.icon])
                              : null
                          }
                          onClick={() =>
                            handleMenuItemSelect(feature?.featureName)
                          }>
                          <Link to={`/${feature?.endPoint}`}>
                            {feature?.featureName}
                          </Link>
                        </Menu.Item>
                      )
                  )}
                </SubMenu>
              )}

            {sideBarData?.features &&
              sideBarData.features.some((feature) =>
                ["Offline Sales", "Online Sales"].includes(feature.featureName)
              ) && (
                <SubMenu
                  key='Sales'
                  title={
                    <span
                      onClick={toggleSales}
                      style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src='http://mrsasta.s3.eu-north-1.amazonaws.com/1731996355567_fluent_arrow-growth-20-filled.png'
                        alt='user'
                        height={18}
                        width={18}
                      />
                      <span
                        style={{
                          color: "white",
                          marginLeft: "10px",
                          display: collapsed ? "none" : "inline",
                          fontWeight: "600",
                        }}>
                        Sales Management
                      </span>
                    </span>
                  }>
                  {sideBarData?.features?.map(
                    (feature) =>
                      ["Offline Sales", "Online Sales"].includes(
                        feature?.featureName
                      ) && (
                        <Menu.Item
                          key={feature?.endPoint}
                          icon={
                            iconMap[feature?.icon]
                              ? React.createElement(iconMap[feature?.icon])
                              : null
                          }
                          onClick={() =>
                            handleMenuItemSelect(feature.featureName)
                          }>
                          <Link to={`/${feature?.endPoint}`}>
                            {feature?.featureName}
                          </Link>
                        </Menu.Item>
                      )
                  )}
                </SubMenu>
              )}

            {sideBarData?.features &&
              sideBarData?.features?.some((feature) =>
                ["Customers", "Shop Keepers"]?.includes(feature?.featureName)
              ) && (
                <SubMenu
                  key='Customers'
                  title={
                    <span
                      onClick={toggleCustomers}
                      style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src='http://mrsasta.s3.eu-north-1.amazonaws.com/1731996056332_oui_app-users-roles.png'
                        alt='user'
                        height={18}
                        width={18}
                      />
                      <span
                        style={{
                          color: "white",
                          marginLeft: "10px",
                          display: collapsed ? "none" : "inline",
                          fontWeight: "600",
                        }}>
                        Customers
                      </span>
                    </span>
                  }>
                  {sideBarData?.features?.map(
                    (feature) =>
                      ["Customers", "Shop Keepers"]?.includes(
                        feature?.featureName
                      ) && (
                        <Menu.Item
                          key={feature?.endPoint}
                          icon={
                            iconMap[feature?.icon]
                              ? React.createElement(iconMap[feature?.icon])
                              : null
                          }
                          onClick={() =>
                            handleMenuItemSelect(feature?.featureName)
                          }>
                          <Link to={`/${feature?.endPoint}`}>
                            {feature?.featureName}
                          </Link>
                        </Menu.Item>
                      )
                  )}
                </SubMenu>
              )}
          </Menu>
        )}
      </div>

      {/* <div className='logout-btn-container'>
        <div className='Footer-section'>
          <div className='Footer-card'>
            {profileDetails?.Image ? (
              <Avatar className='' src={profileDetails?.Image} size={50} />
            ) : (
              <Avatar
                shape='circle'
                size={40}
                style={{
                  backgroundColor: "#fff",
                  fontWeight: 600,
                  fontSize: 17,
                  color: "#6750A4",
                  border: "2px solid #6750A4",
                }}>
                {profileDetails?.name?.length > 0 &&
                  profileDetails?.name[0]?.charAt(0)?.toUpperCase()}
              </Avatar>
            )}
            <div className='naming-section'>
              <p className='name'>
                {profileDetails?.name ? profileDetails?.name : "--"}
              </p>
              <p className='role'>
                {sideBarData?.roleName ? sideBarData?.roleName : "--"}
              </p>
            </div>
          </div>
          <div>
            <SlOptionsVertical style={{ color: "black" }} />
          </div>
        </div>
      </div> */}
    </Sider>
  );
};

export default Sidebar;
