import React, { useState } from "react";
import { MdOutlinePictureAsPdf } from "react-icons/md";
import { ImPrinter } from "react-icons/im";
import { FaPlus } from "react-icons/fa6";
import { Input } from "antd";
import { useNavigate } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import AddCustomer from "./AddCustomer";
import * as XLSX from "xlsx";

const CustomerCard = ({
  fetchCustomerList,
  setFilter,
  filter,
  customers,
  searchInput,
  setSearchInput,
  activeCount,
  inactiveCount,
  totalCount,
}) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const closeModal = () => {
    setShowModal(false);
  };

  const handleFilterClick = (newFilter) => {
    setFilter(newFilter);
  };

  const onExportLocal = () => {
    // Check if the file has already been downloaded in this session
    if (localStorage.getItem("customersDownloaded")) {
      const downloadAgain = confirm(
        "The file has already been downloaded. Do you want to download it again?"
      );
      if (!downloadAgain) return; // Exit if the user doesn't want to re-download
    }

    const formattedData = customers.map((item, index) => ({
      "S.No": index + 1,
      "Name of the Customer": item.name,
      "E-mail": item.email,
      Contact: item.phone,
      Address: item.address,
      Status: item.status,
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "customers");

    // Adjust column widths
    const wscols = [
      { wch: 5 }, // S.No
      { wch: 35 }, // Name of the Customer
      { wch: 35 }, // E-mail
      { wch: 25 }, // Contact
      { wch: 30 }, // Address
      { wch: 10 }, // Status
    ];
    worksheet["!cols"] = wscols;

    // Save the file
    XLSX.writeFile(workbook, "customers.xlsx");

    // Set a flag in localStorage to mark the file as downloaded
    localStorage.setItem("customersDownloaded", "true");
  };

  // const handlePrint = () => {
  //   const printWindow = window.open("", "_blank");
  //   const printData = customers
  //     .map(
  //       (item, index) => `
  //     <tr>
  //       <td>${index + 1}</td>
  //       <td>${item.name}</td>
  //       <td>${item.email}</td>
  //       <td>${item.phone}</td>
  //       <td>${item.address}</td>
  //       <td style="color: ${item.status === "active" ? "green" : "red"}">${
  //         item.status
  //       }</td>
  //     </tr>
  //   `
  //     )
  //     .join("");

  //   printWindow.document.write(`
  //     <html>
  //     <head>
  //       <title>Customer Data</title>
  //       <style>
  //         table {
  //           width: 100%;
  //           border-collapse: collapse;
  //         }
  //         th, td {
  //           border: 1px solid black;
  //           padding: 8px;
  //           text-align: left;
  //         }
  //         th {
  //           background-color: #f2f2f2;
  //         }
  //         td:nth-child(1), th:nth-child(1) { /* S.No column */
  //           width: 5%;
  //         }
  //         td:nth-child(2), th:nth-child(2) { /* Name of the Customer column */
  //           width: 20%;
  //         }
  //         td:nth-child(3), th:nth-child(3) { /* E-mail column */
  //           width: 20%;
  //         }
  //         td:nth-child(4), th:nth-child(4) { /* Contact column */
  //           width: 10%;
  //         }
  //         td:nth-child(5), th:nth-child(5) { /* Address column */
  //           width: 15%;
  //         }
  //         td:nth-child(6), th:nth-child(6) { /* Status column */
  //           width: 10%;
  //         }
  //       </style>
  //     </head>
  //     <body>
  //       <h1>Customer Data</h1>
  //       <table>
  //         <thead>
  //           <tr>
  //             <th>S.No</th>
  //             <th>Name of the Customer</th>
  //             <th>E-mail</th>
  //             <th>Contact</th>
  //             <th>Address</th>
  //             <th>Status</th>
  //           </tr>
  //         </thead>
  //         <tbody>
  //           ${printData}
  //         </tbody>
  //       </table>
  //     </body>
  //     </html>
  //   `);

  //   printWindow.document.close();
  //   printWindow.print();
  // };
  const handleAddScaleClick = () => {
    setShowModal(true);
  };

  return (
    <div className='customerCard_header-container'>
      <div className='customerCard_left-side'>
        <div className='customerCard_search-bar-Off'>
          <Input
            type='text'
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            suffix={<SearchOutlined className='search-icon' />}
            placeholder='Search by customer name'
            className='customerCard_search-input'
          />
        </div>

        <div className='customerCard_filter-buttons'>
          <button
            className={filter === "All" ? "active" : ""}
            onClick={() => handleFilterClick("All")}>
            ALL ({totalCount})
          </button>
          <button
            className={filter === "Active" ? "active" : ""}
            onClick={() => handleFilterClick("Active")}>
            ACTIVE ({activeCount})
          </button>
          <button
            className={filter === "Inactive" ? "active" : ""}
            onClick={() => handleFilterClick("Inactive")}>
            INACTIVE ({inactiveCount})
          </button>
        </div>
      </div>
      <div className='customerCard_right-side'>
        <div className='customerCard_PDF_B'>
          <button className='customerCard_pdf-button' onClick={onExportLocal}>
            <MdOutlinePictureAsPdf size={15} /> Export to CSV
          </button>
        </div>
        {/* <div className='customerCard_Print_b'>
          <button className='customerCard_print-button' onClick={handlePrint}>
            <ImPrinter size={15} /> Print
          </button>
        </div> */}
        <div className='customerCard_Add_b'>
          <button
            className='customerCard_add-scale-button'
            onClick={handleAddScaleClick}>
            <FaPlus size={10} /> Add New Customer
          </button>
        </div>
      </div>
      <Modal
        visible={showModal}
        onCancel={closeModal}
        closeIcon={false}
        centered
        footer={null}
        className='custom-modal'>
        <AddCustomer
          closeModal={closeModal}
          fetchCustomerList={fetchCustomerList}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      </Modal>
    </div>
  );
};

export default CustomerCard;
