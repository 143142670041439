import { Form, Input, Checkbox, Col, Row } from "antd";

import React, { useEffect, useState } from "react";

const AddActivity = ({
  close,
  setSelectedItem,
  selectedItem,
  featuresData,
  checkedFeatures,
  setCheckedFeatures,
  setActionModal,
  roleName,
  setRoleName,
}) => {
  const [form] = Form.useForm();

  // const onChange = (checkedValues) => {
  //   console.log("checked = ", checkedValues);
  //   setCheckedFeatures(checkedValues);
  // };
  useEffect(() => {
    form.setFieldValue("role", roleName);
  }, [roleName]);

  console.log(checkedFeatures);

  const onFinish = async (values) => {
    console.log(values);
    console.log(checkedFeatures);
    close();
    setActionModal(true);
  };

  console.log(selectedItem);
  console.log(featuresData);

  const onChange = (checkedValues) => {
    const updatedCheckedFeatures = featuresData.filter((item) =>
      checkedValues.includes(item._id)
    );
    console.log(updatedCheckedFeatures);
    setCheckedFeatures(updatedCheckedFeatures);
  };

  return (
    <div className='Add-role-container'>
      <div className='Add-role-content'>
        <h6 style={{ textAlign: "center", marginBottom: "10px" }}>Edit Role</h6>
        <Form
          form={form}
          name='normal_login'
          className='edit-profile-form'
          layout='vertical'
          onFinish={onFinish}>
          <Form.Item
            name='role'
            label='Edit Role Name'
            initialValue={roleName}
            rules={[
              {
                required: true,
                message: "Please enter role name",
              },
            ]}>
            <Input
              type='text'
              onChange={(e) => setRoleName(e.target.value)}
              placeholder='Enter new role'
            />
          </Form.Item>
          <label>Edit selected activity ({checkedFeatures?.length})</label>
          <Checkbox.Group
            style={{
              width: "100%",
            }}
            value={checkedFeatures.map((feature) => feature.featureId)}
            onChange={onChange}>
            <Row style={{ marginBottom: "10px" }}>
              {featuresData?.length > 0 &&
                featuresData.map((item) => (
                  <Col span={8} style={{ marginBottom: "12px" }} key={item._id}>
                    <Checkbox value={item._id}>{item.featureName}</Checkbox>
                  </Col>
                ))}
            </Row>
          </Checkbox.Group>

          <Form.Item>
            <div className='add-role-submit'>
              <div className='add-role-cancel-button' onClick={close}>
                <p>Cancel</p>
              </div>
              {selectedItem?.features?.length > 0 ? (
                <button
                  type='primary'
                  htmlType='submit'
                  className='add-role-submit-button'>
                  <p>Submit</p>
                </button>
              ) : (
                <div className='add-role-submit-btn'>
                  <p>Submit</p>
                </div>
              )}
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default AddActivity;
