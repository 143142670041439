import React, { useState } from "react";
import "../OfflineSales/OfflineSales.css";
import { Avatar, Modal, Spin } from "antd";
import { Input, message } from "antd";
import cardimage from "../OnlineSales/Images/OnlineCard.png";
import cardimage1 from "../OnlineSales/Images/OnlineCard2.png";
import cardimage2 from "../OnlineSales/Images/OnlineCard3.png";
import cardimage3 from "../OnlineSales/Images/OnlineCard4.png";
import cardimage4 from "../OnlineSales/Images/OnlineCard5.png";
import cardimage5 from "../OnlineSales/Images/onlineCard6.png";
import upiImage from "../OnlineSales/Images/OnlineUpi.png";
import upiImage1 from "../OnlineSales/Images/OnlineUpi1.png";
import upiImage2 from "../OnlineSales/Images/OnlineUpi2.png";
import upiImage3 from "../OnlineSales/Images/OnlineUpi3.png";
import upiImage4 from "../OnlineSales/Images/OnlineUpi4.png";
import upiImage5 from "../OnlineSales/Images/OnlineUpi5.png";
import Image from "../Categories/Image.png";
import { handleProfileImage } from "../../containers/FileUpload/FileUpload";
import { postAuthApiData, putAuthApiData } from "../../api/apiService";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  OfflineAddCustomer,
  UpdatingSelectedProducts,
  resetSelectedProducts,
} from "../../redux/action";
import SalesSuccessPopUp from "../OfflineSales/SalesSuccessPopUp";
import OnlineSaleSuccessPopup from "./OnlineSaleSuccessPopup";
import useLogout from "../../utils/authUtils";
import { handleFileUpload } from "../../containers/FileUpload/FileUploadNew";
import { useDropzone } from "react-dropzone";
import { CloudUploadOutlined } from "@ant-design/icons";
import { ImCross } from "react-icons/im";

const OnlineSalesPayment = ({
  summaryData,
  loading,
  setLoading,
  error,
  setError,
}) => {
  const [paymentMethod, setPaymentMethod] = useState("Cash");
  const [categoryImage, setCategoryImage] = useState("");
  const logout = useLogout();
  const [file, setFile] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedImage, setSelectedImage] = useState("BHIMUPI");
  const [successPopUp, setSuccessPopUp] = useState(false);

  const handleImageSelection = (type) => {
    setSelectedImage(type);
  };

  const fetchGenerateInvoice = async (bookingData) => {
    const Endpoint = "generateInvoice";
    try {
      setLoading(true);
      const userData = {
        orderbookingId: bookingData?.orderBookingId,
      };
      const { data, loading: postLoading } = await postAuthApiData(
        Endpoint,
        userData
      );
      console.log("Generate invoice fetched successfully:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data.message);
        setSuccessPopUp(true);
        navigate("/onlineSales");
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data?.message);

        // message.error(data?.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("products Info data fetching failed:", error);
      message.error(error);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmPayment = async () => {
    const Endpoint = "onlineConfirmPayment";
    try {
      setLoading(true);
      const id = localStorage.getItem("onlineOrderBookingId");
      const userData = {
        newOrderBookingId: id,
        paidAmount: summaryData?.sub_total,
        paymentMethod: paymentMethod,
        dueAmount: 0,
      };
      if (paymentMethod === "Cheque") {
        userData.chequeImageUrl = categoryImage;
      }
      if (paymentMethod === "UPI") {
        userData.paymentMethodType = selectedImage;
      }
      const { data, loading: postLoading } = await putAuthApiData(
        Endpoint,
        userData
      );
      console.log("Confirm Payment successful:", data);
      setLoading(postLoading);
      if (data?.responseCode === 200) {
        console.log(data.message);
        setSuccessPopUp(true);
        console.log("Success Pop Up");
        // message.success(data?.message);
        localStorage.removeItem("onlineOrderBookingId");
        dispatch(resetSelectedProducts());
        fetchGenerateInvoice(data?.data);
        dispatch(OfflineAddCustomer({}));
      } else if (data?.responseCode === 400) {
        console.log(data.message);
        message.warning(data?.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Confirm Payment  failed:", error);
      message.error(error);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  const handlePaymentMethod = (method) => {
    setPaymentMethod(method);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles, rejectedFiles) => {
      // Manually filter file types to allow only .jpg, .png, and .jpeg
      const validExtensions = [".png", ".jpg", ".jpeg"];

      acceptedFiles.forEach(async (file) => {
        const fileExtension = file.name
          .slice(file.name.lastIndexOf("."))
          .toLowerCase();

        // Validate file extension
        if (!validExtensions.includes(fileExtension)) {
          message.error(
            "Unsupported file type. Please upload only .jpg, .png, or .jpeg files."
          );
          return;
        }

        // Check file size (must be <= 5MB)
        // if (file.size > 5242880) {
        //   message.error("File size exceeds the maximum limit of 5MB.");
        //   return;
        // }

        // If file is valid, store the latest file and preview it
        const preview = URL.createObjectURL(file);
        setFile(
          Object.assign(file, {
            preview: preview,
          })
        );

        try {
          // Handle file upload and get the returned URL
          const uploadedFileUrl = await handleFileUpload(file);

          // Set the uploaded file URL as category image
          console.log(uploadedFileUrl);

          setCategoryImage(uploadedFileUrl);
        } catch (error) {
          message.error("File upload failed. Please try again.");
        }
      });
    },
    maxFiles: 1, // Allow only one file at a time
  });

  return (
    <Spin spinning={loading}>
      <div className='offlineSales_pay_ment'>
        <div className='offlineSales_spot-pay'>
          <h5>Spot Pay</h5>
          <p>Please select a payment method to complete the sale</p>
          <div className='offlineSales_payment-methods'>
            <button
              className={`payment-method ${
                paymentMethod === "Cash" ? "active" : ""
              }`}
              onClick={() => handlePaymentMethod("Cash")}>
              Cash
            </button>
            <button
              className={`payment-method ${
                paymentMethod === "Card" ? "active" : ""
              }`}
              onClick={() => handlePaymentMethod("Card")}>
              Card
            </button>
            <button
              className={`payment-method ${
                paymentMethod === "UPI" ? "active" : ""
              }`}
              onClick={() => handlePaymentMethod("UPI")}>
              Online Payment
            </button>
            <button
              className={`payment-method ${
                paymentMethod === "Cheque" ? "active" : ""
              }`}
              onClick={() => handlePaymentMethod("Cheque")}>
              Cheque
            </button>
          </div>
          {/* {paymentMethod === "Card" && (
          <div className='offlineSales_card-details'>
            <div className='offlineSales_Select_Card'>
              <div className='offlineSales_Select_CardLable'>Select card</div>
              <div className='offline_cardimage'>
                <img
                  className='CardImages'
                  src={cardimage3}
                  shape='square'
                  size={70}
                />
                <img
                  className='CardImages1'
                  src={cardimage4}
                  shape='square'
                  size={70}
                />
                <img
                  className='CardImages2'
                  src={cardimage2}
                  shape='square'
                  size={70}
                />
                <img
                  className='CardImages3'
                  src={cardimage1}
                  shape='square'
                  size={70}
                />
                <img
                  className='CardImages4'
                  src={cardimage5}
                  shape='square'
                  size={70}
                />
                <img
                  className='CardImages5'
                  src={cardimage}
                  shape='square'
                  size={70}
                />
              </div>
            </div>

            <h5 className='offlineSales_card-details-header'>
              Payment Details
            </h5>
            <span className='header-text-offline'>
              Please fill out the form to complete Payment
            </span>
            <div className='offlineCard_details'>
              <div className='offlineCard-Details-Row'>
                <label className='offlinecard-label'>
                  Card Holder Name
                  <Input
                    type='text'
                    id='Card Holder Name'
                    placeholder='Enter Name'
                    value={cardDetails.CardHolderName}
                    onChange={handleCardInputChange}
                  />
                </label>
                <label className='cardnumber-label'>
                  Card Number
                  <Input
                    type='Number'
                    id='Number'
                    placeholder='Enter Number'
                    value={cardDetails.CardNumber}
                    onChange={handleCardInputChange}
                  />
                </label>
              </div>
              <div className='offlineCardDetails_sec_row'>
                <label className='cardexpirydate-label'>
                  Expiry Date
                  <Input
                    type='text'
                    id='expiryDate'
                    placeholder='MM/YY'
                    value={cardDetails.expiryDate}
                    onChange={handleCardInputChange}
                  />
                </label>
                <label className='cardcvv-label'>
                  Enter CVV
                  <Input
                    type='text'
                    id='cvv'
                    placeholder='CVV'
                    value={cardDetails.cvv}
                    onChange={handleCardInputChange}
                  />
                </label>
              </div>
            </div>
          </div>
        )} */}
          {paymentMethod === "UPI" && (
            <div className='offlineSales_online-payment-details'>
              <label className='offline-payment-label'>Select UPI</label>
              <div className='offlineCredit_cardUpiimage'>
                <div className='image-section'>
                  <img
                    className={`UpiImages pointer ${
                      selectedImage === "BHIMUPI" ? "selectedImage" : ""
                    }`}
                    src={upiImage5}
                    shape='square'
                    size={70}
                    onClick={() => handleImageSelection("BHIMUPI")}
                  />
                </div>
                <div className='image-section'>
                  <img
                    className={`UpiImages pointer ${
                      selectedImage === "Phonepe" ? "selectedImage" : ""
                    }`}
                    src={upiImage4}
                    shape='square'
                    size={70}
                    onClick={() => handleImageSelection("Phonepe")}
                  />
                </div>
                <div>
                  <img
                    className={`UpiImages pointer ${
                      selectedImage === "Gpay" ? "selectedImage" : ""
                    }`}
                    src={upiImage2}
                    shape='square'
                    size={70}
                    onClick={() => handleImageSelection("Gpay")}
                  />
                </div>
                <div className='image-section'>
                  <img
                    className={`UpiImages pointer ${
                      selectedImage === "Paytm" ? "selectedImage" : ""
                    }`}
                    src={upiImage3}
                    shape='square'
                    size={70}
                    onClick={() => handleImageSelection("Paytm")}
                  />
                </div>
                <div className='image-section'>
                  <img
                    className={`UpiImages pointer ${
                      selectedImage === "Paypal" ? "selectedImage" : ""
                    }`}
                    src={upiImage1}
                    shape='square'
                    size={70}
                    onClick={() => handleImageSelection("Paypal")}
                  />
                </div>
                <div className='image-section'>
                  <img
                    className={`UpiImages pointer ${
                      selectedImage === "Cred" ? "selectedImage" : ""
                    }`}
                    src={upiImage}
                    shape='square'
                    size={70}
                    onClick={() => handleImageSelection("Cred")}
                  />
                </div>
              </div>
              {/* <h5 className='upidetail_header'>Add Upi Details</h5>
            <span className='header-text-offline'>
              Please fill out the form to complete Payment
            </span>
            <div className='offlineUpiDetails'>
              <label className='offline-Upi-label'>
                Enter UPI ID
                <Input
                  type='text'
                  id='upiId'
                  placeholder='Your UPI ID'
                  value={offlinePaymentDetails.upiId}
                  onChange={handleOnlinePaymentInputChange}
                />
              </label>
              <label className='offline-mobileNum-label'>
                Enter Mobile Number
                <Input
                  type='text'
                  id='mobileNumber'
                  placeholder='Your Mobile Number'
                  value={offlinePaymentDetails.mobileNumber}
                  onChange={handleOnlinePaymentInputChange}
                />
              </label>
            </div> */}
            </div>
          )}
          {/* {paymentMethod === "Cash" && (
          <div className='offlineSales_amount-input'>
            <span className='amount_lable'>Enter Amount</span>
            <Input
              type='text'
              id='amount'
              placeholder='0000'
              value={amount}
              onChange={handleAmountChange}
            />
          </div>
        )} */}
          {paymentMethod === "Cheque" && (
            <div {...getRootProps()} class='upload-container'>
              <input {...getInputProps()} />
              {file && categoryImage ? (
                <div className='uploaded-image-section'>
                  <div className='fileUpload-image-div'>
                    <img src={file.preview || categoryImage} alt={file.name} />
                  </div>
                  <ImCross
                    className='text-md font-light cursor-pointer'
                    onClick={() => {
                      setFile(null);
                      setCategoryImage("");
                    }}
                  />
                </div>
              ) : (
                <CloudUploadOutlined className='cloud-icon' />
              )}
              <div className='upload-text-section'>
                <p class='upload-text'>
                  Browse/drag to {file && categoryImage ? "change" : "upload"}{" "}
                  File
                </p>
                {/* <p class='file-size-text'>File Size must be below 5 Mb</p> */}
              </div>
            </div>
          )}

          <div className='offlineSales_payment-actions'>
            <button className='offlineSales_cancelButton'>Cancel</button>
            <button
              className='offlineSales_confirmButton'
              onClick={handleConfirmPayment}>
              Confirm Payment
            </button>
          </div>
        </div>
        <Modal
          visible={successPopUp}
          onCancel={() => setSuccessPopUp(false)}
          centered
          footer={null}
          className='custom-modal'>
          <OnlineSaleSuccessPopup />
        </Modal>
      </div>
    </Spin>
  );
};

export default OnlineSalesPayment;
