import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  Input,
  Form,
  Table,
  Select,
  message,
  Spin,
  Space,
  Row,
  Col,
  Radio,
} from "antd";
import "./Products.css";
// import Image from "../Categories/Image.png";
import {
  getApiData,
  postAuthApiData,
  putAuthApiData,
} from "../../api/apiService";
// import { handleProfileImage } from "../../containers/FileUpload/FileUpload";
import useLogout from "../../utils/authUtils";
import { useDropzone } from "react-dropzone";
import { CloudUploadOutlined } from "@ant-design/icons";
import { ImCross } from "react-icons/im";
import { handleFileUpload } from "../../containers/FileUpload/FileUploadNew";
import { BiLeftArrow } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { GrClear } from "react-icons/gr";

const ProductAdd = () => {
  const { TextArea } = Input;
  const [categoryImage, setCategoryImage] = useState("");
  const [skuList, setSkuList] = useState([]);
  const [quantityValue, setQuantityValue] = useState("");
  const [priceValue, setPriceValue] = useState("");
  const [basePrice, setBasePrice] = useState("");
  const [singlePiecePrice, setSinglePiecePrice] = useState("");
  const [packetsCount, setPacketsCount] = useState("");
  const [form] = Form.useForm();
  const [visibleInputs, setVisibleInputs] = useState(true);
  const [categoryData, setCategoryData] = useState([]);
  const [quantityType, setQuantityType] = useState("gram");
  const [skuId, setSkuId] = useState("");
  const [addSkuProductId, setAddSkuProductId] = useState("");
  const [selectedStream, setSelectedStream] = useState("Qty");
  const [isPacketsInputDisabled, setPacketsInputDisabled] = useState(false);
  const [isKgInputDisabled, setKgInputDisabled] = useState(false);
  const [kgValue, setKgValue] = useState("");
  const [taxPercent, setTaxPercent] = useState(null);
  const [taxValue, setTaxValue] = useState(null);
  const [subTotal, setSubTotal] = useState(null);
  const logout = useLogout();
  const skuListRef = useRef(skuList);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const location = useLocation();
  const productId1 = location?.state?.productId || "";
  const skuEditData1 = location?.state?.skuEditData || {};
  const addSkuData1 = location?.state?.addSkuData || [];
  const skuData = location?.state?.skuData || [];
  console.log(skuData);

  const [productId, setProductId] = useState(productId1 || "");
  const [skuEditData, setSkuEditData] = useState(skuEditData1 || {});
  const [addSkuData, setAddSkuData] = useState(addSkuData1 || []);
  console.log(productId);

  // useImperativeHandle(ref, () => ({
  //   handleClear,
  // }));

  useEffect(() => {
    skuListRef.current = skuList;
    console.log("Calling useEffect for skuList");
  }, [skuList]);

  console.log(skuListRef);

  const fetchCategoryList = async () => {
    const Endpoint = "listOfCategories";
    try {
      setLoading(true);
      const { data, loading: postLoading } = await getApiData(Endpoint, true);
      console.log("Category Details fetched successful:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data.message);
        setCategoryData(data?.data);
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Category Details fetching failed:", error);
      message.error(error?.error?.error?.message);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategoryList();
  }, []);

  console.log(skuEditData);
  useEffect(() => {
    if (skuEditData && Object.keys(skuEditData)?.length > 0) {
      form.resetFields();
      setSkuList([]);
      setCategoryImage("");
      setFile(null);
      setPacketsCount("");
      setPriceValue("");
      setQuantityType("gram");
      setQuantityValue("");
      // setAddSkuData([]);
      // setProductId("");
      setPacketsCount(skuEditData?.packets);
      setQuantityType(skuEditData?.quantityType);
      setQuantityValue(
        skuEditData?.quantityValue > 0
          ? skuEditData?.quantityValue
          : skuEditData?.piecesInaPack
      );
      setPriceValue(skuEditData?.salePrice);
      setBasePrice(skuEditData?.basePrice);
      setSinglePiecePrice(skuEditData?.singlePiecePrice);
      fetchProductById(skuEditData?.productId);
      setSkuId(skuEditData?._id);
      setSelectedStream(skuEditData?.productStream);
      setTaxPercent(skuEditData?.tax);
      setSkuList(skuData?.length > 0 ? skuData : []);
    }
  }, [skuEditData]);

  const onFinish = async (values) => {
    console.log(values);
    console.log(skuList);
    if (productId) {
      try {
        const Endpoint = "updateProduct";
        setLoading(true);
        const userData = {
          productId: productId,
          productName: values.name,
          productImage: categoryImage,
          description: values.description,
          categoryId: values.category,
        };
        const { data, loading: postLoading } = await putAuthApiData(
          Endpoint,
          userData
        );
        console.log("Product Updated successful:", data);
        setLoading(postLoading);
        if (data?.responseCode === 200) {
          console.log(data.message);
          message.success(data?.message);
          form.resetFields();
          setCategoryImage("");
          setFile(null);
          // fetchProductList();
          setSelectedStream("Qty");
          // setProductId("");
          navigate(-1);
        } else if (data?.responseCode === 400) {
          message.error(data?.message);
          console.log(data?.message);
        } else if (data.responseCode === 401) {
          message.error(data?.message);
          logout();
        }
      } catch (error) {
        console.log("Product Updated failed:", error);
        message.error(error?.error?.error?.message);
        setLoading(false);
        setError(error);
      } finally {
        setLoading(false);
      }
    } else if (skuEditData && Object.keys(skuEditData)?.length > 0) {
      if (priceValue) {
        const price = parseFloat(priceValue);
        const bPrice = parseFloat(basePrice);
        const pieceOfPrice = parseFloat(singlePiecePrice);
        try {
          const Endpoint = "updateSku";
          setLoading(true);
          const userData = {
            skuId: skuId,
            salePrice: price,
            basePrice: bPrice,
            tax: taxPercent ? taxPercent : 0,
          };
          if (skuEditData?.productStream === "Pouches") {
            userData.singlePiecePrice = pieceOfPrice;
          }
          const { data, loading: postLoading } = await putAuthApiData(
            Endpoint,
            userData
          );
          console.log("Sku Updated successful:", data);
          setLoading(postLoading);
          if (data?.responseCode === 200) {
            console.log(data.message);
            message.success(data?.message);
            form.resetFields();
            // fetchProductList();
            // setSkuEditData({});
            // setProductId("");
            setQuantityValue("");
            setPriceValue("");
            setBasePrice("");
            setKgValue("");
            setSelectedStream("Qty");
            // setSkuProductId("");
            setPacketsCount("");
            setQuantityType("gram");
            setCategoryImage("");
            setFile(null);
            navigate(-1);
          } else if (data?.responseCode === 400) {
            message.warning(data?.message);
            console.log(data?.message);
          } else if (data.responseCode === 401) {
            message.error(data?.message);
            logout();
          }
        } catch (error) {
          console.log("Sku Updated failed:", error);
          message.error(error?.error?.error?.message);
          setLoading(false);
          setError(error);
        } finally {
          setLoading(false);
        }
      } else {
        message.warning("Please enter the Price and packets inputs");
      }
    } else if (addSkuData?.length > 0) {
      if (selectedStream === "Qty") {
        console.log("Selected Stream: addSku", selectedStream);
        if (
          quantityType &&
          quantityValue &&
          priceValue &&
          packetsCount &&
          basePrice
        ) {
          const packets = parseInt(packetsCount, 10);
          const salePrice = parseFloat(priceValue);
          const baseprice = parseFloat(basePrice);
          const newSku = {
            quantityType,
            quantityValue,
            salePrice,
            packets,
            basePrice: baseprice,
            productStream: selectedStream,
            tax: taxPercent ? taxPercent : 0,
          };
          setSkuList([...skuList, newSku]);
          skuListRef.current = [...skuListRef.current, newSku];
          setQuantityValue("");
          setPriceValue("");
          setPacketsCount("");
          setBasePrice("");
          // setSelectedStream("Qty");
          console.log("Added Skus List in addSku", skuList, newSku);

          setQuantityType("gram");
        }
      } else if (selectedStream === "Pouches") {
        console.log("Selected Stream: addSku", selectedStream);
        if (quantityValue && priceValue && packetsCount && basePrice) {
          const packets = parseInt(packetsCount, 10);
          const salePrice = parseFloat(priceValue);
          const baseprice = parseFloat(basePrice);
          const newSku = {
            piecesInaPack: quantityValue,
            salePrice,
            packets,
            basePrice: baseprice,
            productStream: selectedStream,
            tax: taxPercent ? taxPercent : 0,
          };
          setSkuList([...skuList, newSku]);
          skuListRef.current = [...skuListRef.current, newSku];
          setQuantityValue("");
          setPriceValue("");
          setBasePrice("");
          // setSelectedStream("Qty");
          setPacketsCount("");
          setQuantityType("gram");
          console.log("Added Skus List in addSku", skuList, newSku);
        }
      }
      if (skuListRef?.current?.length > 0) {
        console.log(skuListRef?.current);
        const filteredSkus = skuListRef?.current?.filter(
          (sku) => !sku.hasOwnProperty("productId")
        );
        console.log(filteredSkus);
        try {
          const Endpoint = "addSkuData";
          setLoading(true);
          const userData = {
            productId: addSkuProductId,
            skus: filteredSkus,
            productStream: selectedStream,
          };
          const { data, loading: postLoading } = await postAuthApiData(
            Endpoint,
            userData
          );
          console.log("Product Sku  Added successful:", data);
          setLoading(postLoading);
          if (data.responseCode === 200) {
            console.log(data.message);
            message.success(data?.message);
            form.resetFields();
            setSkuList([]);
            // setAddSkuData([]);
            // setSkuProductId("");
            skuListRef.current = [];
            setAddSkuProductId("");
            setVisibleInputs(true);
            setSelectedStream("Qty");
            setCategoryImage("");
            setFile(null);
            navigate(-1);
            // fetchProductList();
          } else if (data.responseCode === 400) {
            console.log(data);
            console.log(data.message);
          } else if (data.responseCode === 401) {
            message.error(data?.message);
            logout();
          }
        } catch (error) {
          console.log("Product Sku Added failed:", error);
          message.error(error?.error?.error?.message);
          setLoading(false);
          setError(error);
        } finally {
          setLoading(false);
        }
      }
    } else {
      if (selectedStream === "Qty") {
        if (
          quantityType &&
          quantityValue &&
          priceValue &&
          packetsCount &&
          basePrice
        ) {
          const packets = parseInt(packetsCount, 10);
          const salePrice = parseFloat(priceValue);
          const baseprice = parseFloat(basePrice);
          const newSku = {
            quantityType,
            quantityValue,
            salePrice,
            basePrice: baseprice,
            packets,
            productStream: selectedStream,
            tax: taxPercent ? taxPercent : 0,
          };
          setSkuList([...skuList, newSku]);
          skuListRef.current = [...skuListRef.current, newSku];
          setQuantityValue("");
          setBasePrice("");
          setPriceValue("");
          setPacketsCount("");
          setQuantityType("gram");
          setKgValue("");
          setKgInputDisabled(false);
          setTaxPercent(null);
          setTaxValue(null);
          setSubTotal(null);
          setPacketsInputDisabled(false);
        }
      } else if (selectedStream === "Pouches") {
        if (quantityValue && priceValue && packetsCount && basePrice) {
          const packets = parseInt(packetsCount, 10);
          const salePrice = parseFloat(priceValue);
          const baseprice = parseFloat(basePrice);
          const piecePrice = parseFloat(singlePiecePrice);
          const newSku = {
            piecesInaPack: quantityValue,
            salePrice,
            packets,
            basePrice: baseprice,
            productStream: selectedStream,
            singlePiecePrice: piecePrice,
            tax: taxPercent ? taxPercent : 0,
          };
          setSkuList([...skuList, newSku]);
          skuListRef.current = [...skuListRef.current, newSku];
          setQuantityValue("");
          setPriceValue("");
          setBasePrice("");
          setPacketsCount("");
          setQuantityType("gram");
          setKgValue("");
          setPacketsInputDisabled(false);
          setKgInputDisabled(false);
          setTaxPercent(null);
          setTaxValue(null);
          setSubTotal(null);
          setSinglePiecePrice("");
        }
      }

      if (skuListRef.current.length > 0) {
        try {
          const Endpoint = "addProduct";
          setLoading(true);
          const userData = {
            productName: values.name,
            productImage: categoryImage,
            description: values.description,
            categoryId: values.category,
            skus: skuListRef.current,
            // productStream: selectedStream,
          };
          const { data, loading: postLoading } = await postAuthApiData(
            Endpoint,
            userData
          );
          console.log("Product Added successful:", data);
          setLoading(postLoading);
          if (data.responseCode === 200) {
            console.log(data.message);
            message.success(data?.message);
            form.resetFields();
            setSkuList([]);
            setCategoryImage("");
            setFile(null);
            setSelectedStream("Qty");
            setVisibleInputs(true);
            // fetchProductList();
            setKgValue("");
            setPacketsInputDisabled(false);
            setKgInputDisabled(false);
            setSinglePiecePrice("");
            setPacketsCount("");
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
            setVisibleInputs(true);
            navigate(-1);
          } else if (data.responseCode === 400) {
            console.log(data);
            console.log(data.message);
            message.error(data?.message);
          } else if (data.responseCode === 401) {
            message.error(data?.message);
            logout();
          }
        } catch (error) {
          console.log("Product Added failed:", error);
          message.error(error?.error?.error?.message);
          setLoading(false);
          setError(error);
        } finally {
          setLoading(false);
        }
      } else {
        message.error("Please enter the sku details");
      }
    }
  };
  console.log(skuList);

  const handleAddSku = () => {
    console.log(quantityType, quantityValue, priceValue, packetsCount);
    if (
      quantityType &&
      quantityValue &&
      priceValue &&
      packetsCount &&
      basePrice
    ) {
      const packets = parseInt(packetsCount, 10);
      const price = parseFloat(priceValue);
      const baseprice = parseFloat(basePrice);
      const newSku = {
        quantityType,
        quantityValue,
        salePrice: price,
        packets,
        basePrice: baseprice,
        productStream: selectedStream,
        tax: taxPercent ? taxPercent : 0,
        // skuStream: selectedStream,
      };
      console.log(newSku);
      setSkuList([...skuList, newSku]);
      skuListRef.current = [...skuListRef.current, newSku];
      setQuantityValue("");
      setPriceValue("");
      setPacketsCount("");
      setBasePrice("");
      setQuantityType("gram");
      setKgValue("");
      setKgInputDisabled(false);
      setPacketsInputDisabled(false);
      // setVisibleInputs(!visibleInputs);
      setTaxPercent(null);
      setTaxValue(null);
      setSubTotal(null);
    } else {
      // setVisibleInputs(!visibleInputs);
      message.error("Please enter sku details");
    }
  };
  const handlePiecesAddSku = () => {
    console.log("Stream", selectedStream);
    console.log(quantityValue, priceValue, packetsCount, singlePiecePrice);
    if (
      quantityValue &&
      priceValue &&
      packetsCount &&
      basePrice &&
      singlePiecePrice
    ) {
      const packets = parseInt(packetsCount, 10);
      const price = parseFloat(priceValue);
      const baseprice = parseFloat(basePrice);
      const piecePrice = parseFloat(singlePiecePrice);

      const newSku = {
        piecesInaPack: quantityValue,
        salePrice: price,
        packets: packets,
        basePrice: baseprice,
        singlePiecePrice: piecePrice,
        productStream: selectedStream,
        tax: taxPercent ? taxPercent : 0,
        // skuStream: "Pouches",
      };
      setSkuList([...skuList, newSku]);
      skuListRef.current = [...skuListRef.current, newSku];
      setQuantityValue("");
      setPriceValue("");
      setSinglePiecePrice("");
      setPacketsCount("");
      setBasePrice("");
      setQuantityType("gram");
      setKgValue("");
      setKgInputDisabled(false);
      setPacketsInputDisabled(false);
      setTaxPercent(null);
      setTaxValue(null);
      setSubTotal(null);
      // setVisibleInputs(!visibleInputs);
    } else {
      // setVisibleInputs(!visibleInputs);
      message.error("Please enter sku details");
    }
  };
  console.log(skuList);

  const handleCategorySelect = (value) => {
    console.log(value);
  };

  const options = [
    {
      value: "gram",
      label: "gram",
    },
    {
      value: "kg",
      label: "kg",
    },
  ];

  const streamOptions = [
    {
      value: "Qty",
      label: "Qty",
    },
    {
      value: "Pouches",
      label: "Pouches",
    },
  ];

  const handleSelectChange = (value) => {
    console.log(value);
    setQuantityType(value);
  };
  const handleStreamChange = (value) => {
    console.log(value);
    setSelectedStream(value);
    setTaxPercent(null);
    setTaxValue(null);
    setSubTotal(null);
    setPacketsCount("");
    setPriceValue("");
    setQuantityValue("");
    setBasePrice("");
    setPacketsInputDisabled(false);
    setKgValue("");
    setKgInputDisabled(false);
    setVisibleInputs(true);
    setSinglePiecePrice("");
  };

  console.log(productId);
  console.log(addSkuData);

  useEffect(() => {
    if (productId) {
      form.resetFields();
      setSkuList([]);
      setCategoryImage("");
      setFile(null);
      setPacketsCount("");
      setPriceValue("");
      setQuantityType("gram");
      setQuantityValue("");
      // setAddSkuData([]);
      // setSkuEditData({});
      setSkuId("");
      setVisibleInputs(true);
      fetchProductById(productId);
    }
  }, [productId]);

  useEffect(() => {
    if (addSkuData?.length > 0) {
      form.resetFields();
      setSkuList([]);
      setCategoryImage("");
      setFile(null);
      setPacketsCount("");
      setPriceValue("");
      setQuantityType("gram");
      setQuantityValue("");
      // setSkuEditData({});
      // setSkuId("");
      setVisibleInputs(true);
      // setProductId("");
      const id = addSkuData?.length > 0 && addSkuData[0]?.productId;
      const list = addSkuData?.length > 0 && addSkuData;
      setAddSkuProductId(id);
      setSkuList(list);
      fetchProductById(id);
      setSelectedStream(addSkuData?.length > 0 && addSkuData[0]?.productStream);
    }
  }, [addSkuData]);

  const fetchProductById = async (id) => {
    const Endpoint = "getSingleProductById";
    try {
      setLoading(true);
      const userData = {
        productId: id,
      };
      const { data, loading: postLoading } = await postAuthApiData(
        Endpoint,
        userData
      );
      console.log("Product By Id fetched successful:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data.message);
        form.setFieldsValue({
          category: data?.data?.product?.categoryId || "",
          description: data?.data?.product?.description || "",
          name: data?.data?.product?.productName || "",
        });
        setSkuList(data?.data?.skus);
        setCategoryImage(data?.data?.product?.productImage || "");
        setFile(data?.data?.product?.productImage || "");
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Product By Id fetched failed:", error);
      message.error(error?.error?.error?.message);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  console.log(selectedStream);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles, rejectedFiles) => {
      // Manually filter file types to allow only .jpg, .png, and .jpeg
      const validExtensions = [".png", ".jpg", ".jpeg"];

      acceptedFiles.forEach(async (file) => {
        const fileExtension = file.name
          .slice(file.name.lastIndexOf("."))
          .toLowerCase();

        // Validate file extension
        if (!validExtensions.includes(fileExtension)) {
          message.error(
            "Unsupported file type. Please upload only .jpg, .png, or .jpeg files."
          );
          return;
        }

        // Check file size (must be <= 5MB)
        // if (file.size > 5242880) {
        //   message.error("File size exceeds the maximum limit of 5MB.");
        //   return;
        // }

        // If file is valid, store the latest file and preview it
        const preview = URL.createObjectURL(file);
        setFile(
          Object.assign(file, {
            preview: preview,
          })
        );

        try {
          // Handle file upload and get the returned URL
          const uploadedFileUrl = await handleFileUpload(file);

          // Set the uploaded file URL as category image
          console.log(uploadedFileUrl);

          setCategoryImage(uploadedFileUrl);
        } catch (error) {
          message.error("File upload failed. Please try again.");
        }
      });
    },
    maxFiles: 1, // Allow only one file at a time
  });
  const handleClear = () => {
    form.resetFields();
    setSkuList([]);
    setCategoryImage("");
    setFile(null);
    setPacketsCount("");
    setPriceValue("");
    setQuantityType("gram");
    setQuantityValue("");
    setAddSkuData([]);
    setSkuEditData({});
    setSelectedStream("Qty");
    setBasePrice("");
    setPacketsInputDisabled(false);
    setKgValue("");
    setKgInputDisabled(false);
    setSkuId("");
    setVisibleInputs(true);
    setTaxPercent(null);
    setTaxValue(null);
    setSubTotal(null);
    setProductId("");
    setSinglePiecePrice("");
  };

  useEffect(() => {
    if (packetsCount) {
      const packets = parseFloat(packetsCount);
      const calculatedKg =
        quantityType === "gram"
          ? Math.round((packets * quantityValue) / 1000)
          : Math.round(packets * quantityValue);
      setKgValue(calculatedKg);
    }
  }, [packetsCount, quantityValue, quantityType]);

  const handlePacketsChange = (e) => {
    const value = e.target.value;
    const roundedValue = Math.round(value); // Ensure integer value
    setPacketsCount(roundedValue);
    console.log(roundedValue);
    setKgInputDisabled(value !== "");
    if (value === "") {
      setKgValue("");
      setPacketsCount("");
      setKgInputDisabled(false);
    } else {
      const packets = parseFloat(roundedValue);
      const calculatedKg =
        quantityType === "gram"
          ? Math.round((packets * quantityValue) / 1000)
          : Math.round(packets * quantityValue);
      setKgValue(calculatedKg);
    }
  };

  const handleKgChange = (e) => {
    const value = e.target.value;
    const roundedValue = Math.round(value); // Ensure integer value
    setKgValue(roundedValue);
    setPacketsInputDisabled(value !== "");
    if (value === "") {
      setPacketsCount("");
      setPacketsInputDisabled(false);
    } else {
      const kgs = roundedValue;
      setKgValue(kgs);

      const calculatedPackets =
        quantityType === "gram"
          ? Math.round((kgs * 1000) / quantityValue)
          : Math.round(kgs / quantityValue);

      setPacketsCount(calculatedPackets);
    }
  };

  const handleNumericInput = (e) => {
    const charCode = e.charCode;
    const inputValue = e.target.value;
    if (charCode >= 48 && charCode <= 57) {
      return;
    } else {
      e.preventDefault();
      message.warning("Only numbers are allowed");
    }
  };

  let productWarningShown = false; // Flag to track if the warning has been shown

  const validateProduct = (_, value) => {
    // Check if the product name starts with a letter
    if (value && !/^[a-zA-Z]/.test(value)) {
      if (!productWarningShown) {
        message.warning("Product must start with a letter");
        productWarningShown = true; // Set flag after showing the warning
      }
      return Promise.reject(new Error("Product must start with a letter"));
    }

    productWarningShown = false;

    if (value && !/^[a-zA-Z][a-zA-Z0-9\s]*$/.test(value)) {
      if (!productWarningShown) {
        message.warning(
          "Product must contain only letters, numbers, and spaces"
        );
        productWarningShown = true; // Set flag after showing the warning
      }
      return Promise.reject(
        new Error("Product must contain only letters, numbers, and spaces")
      );
    }

    // Reset the warning flag if input becomes valid
    productWarningShown = false;

    // Check if the length is less than 5 characters
    if (value && value.length < 2) {
      return Promise.reject(new Error("Product must be at least 2 characters"));
    }

    // Check if the length is more than 16 characters
    if (value && value.length > 16) {
      return Promise.reject(new Error("Product must be at most 16 characters"));
    }

    return Promise.resolve();
  };
  const handleProductChange = (e) => {
    const { value } = e.target;
    const cleanedValue = value.replace(/[^a-zA-Z0-9\s]/g, "").slice(0, 17);

    // Prevent product name from starting with a number or space
    if (/^[0-9\s]/.test(cleanedValue)) {
      form.setFieldsValue({ name: cleanedValue.slice(1) });
    } else {
      form.setFieldsValue({ name: cleanedValue });
    }
  };

  useEffect(() => {
    if (priceValue && taxPercent !== null) {
      const price = parseFloat(priceValue);
      const taxRate = parseFloat(taxPercent);

      if (!isNaN(price) && !isNaN(taxRate)) {
        // Calculate tax and subtotal
        const taxValue = price * (taxRate / 100);
        const subTotal = price - taxValue;
        setSubTotal(subTotal?.toFixed(2));
        setTaxValue(taxValue?.toFixed(2));
        console.log("Price:", price);
        console.log("Tax Percent:", taxPercent);
        console.log("Tax Value:", taxValue);
        console.log("Subtotal:", subTotal);
      } else {
        console.log("Invalid input values:", { priceValue, taxPercent });
        setSubTotal(null);
        setTaxValue(null);
      }
    } else {
      console.log("Invalid input values:", { priceValue, taxPercent });
      setSubTotal(null);
      setTaxValue(null);
    }
  }, [priceValue, taxPercent]);
  const navigate = useNavigate();

  return (
    <div className='AddProduct-section'>
      <Form
        form={form}
        className='addcategory-form'
        layout='vertical'
        autoComplete='off'
        onFinish={onFinish}>
        <div className='addproduct-content'>
          <div className='addproduct-form-section'>
            <div className='clear-section'>
              <button
                className='clear-btn'
                onClick={() => navigate("/products")}>
                <FaArrowLeftLong
                  style={{ fontSize: "14px", cursor: "pointer" }}
                />
                <p style={{ fontSize: "14px" }}>Back</p>
              </button>
              <button type='button' onClick={handleClear} className='clear-btn'>
                <GrClear style={{ fontSize: "14px", paddingBottom: "2px" }} />
                <p style={{ fontSize: "14px" }}>Clear</p>
              </button>
            </div>

            <Row gutter={10}>
              <Col sm={12} xl={12}>
                <Form.Item
                  name='category'
                  label='Category'
                  rules={[
                    {
                      required: true,
                      message: "Please select category",
                    },
                  ]}>
                  <Select
                    placeholder='Select Category'
                    allowClear
                    showSearch
                    size='medium'
                    disabled={
                      Object?.keys(skuEditData)?.length > 0 ||
                      addSkuData?.length > 0
                    }
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={handleCategorySelect}>
                    {categoryData
                      ?.filter((item) => item.status === "active")
                      .map((item, index) => (
                        <Select.Option key={item?._id} value={item._id}>
                          {item?.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={12} xl={12}>
                <Form.Item
                  name='name'
                  label='Product'
                  rules={[
                    {
                      required: true,
                      message: "Please enter name of the product !",
                    },
                    { validator: validateProduct },
                  ]}>
                  <Input
                    placeholder='Name of the Product'
                    onChange={handleProductChange}
                    size='medium'
                    disabled={
                      (skuEditData && Object.keys(skuEditData).length > 0) ||
                      (addSkuData && addSkuData.length > 0)
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            {productId ? (
              ""
            ) : (
              <div className='Sku-top'>
                <lable>SKU's</lable>
                <div>
                  {/* <Select
                      value={selectedStream}
                      options={streamOptions}
                      onChange={(value) => handleStreamChange(value)}
                      style={{ minWidth: "100px" }}
                      // disabled={
                      //   skuList?.length > 0 || Object.keys(skuEditData).length > 0
                      // }
                    /> */}

                  <Radio.Group
                    name='vehicleType'
                    className='custom-radio'
                    disabled={
                      skuList?.length > 0 &&
                      Object.keys(skuEditData)?.length > 0
                    }
                    value={selectedStream}
                    onChange={(e) => handleStreamChange(e.target.value)}>
                    <Radio value='Qty'>Qty</Radio>
                    <Radio value='Pouches'>Pouches</Radio>
                  </Radio.Group>
                </div>
              </div>
            )}

            {productId ? (
              ""
            ) : (
              <>
                {selectedStream === "Qty" && visibleInputs && (
                  <div className='sku-inputs'>
                    <Space.Compact>
                      <Input
                        placeholder='Quantity'
                        value={quantityValue}
                        onKeyPress={handleNumericInput}
                        onChange={(e) => setQuantityValue(e.target.value)}
                        disabled={
                          skuEditData && Object?.keys(skuEditData)?.length > 0
                        }
                      />
                      <Select
                        defaultValue={quantityType}
                        options={options}
                        onChange={handleSelectChange}
                        disabled={
                          skuEditData && Object?.keys(skuEditData)?.length > 0
                        }
                      />
                    </Space.Compact>
                    <Row gutter={10}>
                      <Col sm={12} xl={12}>
                        <Input
                          placeholder='Base Price'
                          value={basePrice}
                          onKeyPress={handleNumericInput}
                          onChange={(e) => setBasePrice(e.target.value)}
                          suffix='Rs'
                        />
                      </Col>
                      <Col sm={12} xl={12}>
                        <Input
                          placeholder='Sale Price'
                          value={priceValue}
                          onKeyPress={handleNumericInput}
                          onChange={(e) => setPriceValue(e.target.value)}
                          suffix='Rs'
                        />
                      </Col>
                    </Row>
                    <Select
                      value={taxPercent}
                      placeholder='Select Tax %'
                      onChange={(value) => setTaxPercent(value)}>
                      <Option value={0}>0%</Option>
                      <Option value={5}>5%</Option>
                      <Option value={12}>12%</Option>
                      <Option value={18}>18%</Option>
                    </Select>
                    {/* 
            <Input
              placeholder='Number of packets'
              value={packetsCount}
              onChange={(e) => setPacketsCount(e.target.value)}
              suffix='00'
              disabled={Object.keys(skuEditData).length > 0}
            /> 
            */}
                    <Space.Compact>
                      <Input
                        placeholder='Tax Value'
                        value={taxValue}
                        disabled
                      />
                      <Input
                        placeholder='Sub Total'
                        value={subTotal}
                        disabled
                      />
                    </Space.Compact>

                    <Space.Compact>
                      <Input
                        placeholder='Number of packets'
                        value={packetsCount}
                        onKeyPress={handleNumericInput}
                        onChange={handlePacketsChange}
                        disabled={
                          isPacketsInputDisabled ||
                          (skuEditData && Object?.keys(skuEditData)?.length > 0)
                        }
                      />
                      <Input
                        placeholder='Kg'
                        value={kgValue}
                        onKeyPress={handleNumericInput}
                        onChange={handleKgChange}
                        disabled={
                          isKgInputDisabled ||
                          (skuEditData && Object?.keys(skuEditData)?.length > 0)
                        }
                      />
                    </Space.Compact>
                  </div>
                )}
                {selectedStream === "Qty" &&
                  (!skuEditData || Object.keys(skuEditData)?.length === 0) && (
                    <div className='Add-sku'>
                      <small>You can add multiple SKU's</small>
                      <div
                        className='add-button pointer'
                        onClick={handleAddSku}>
                        <p>+</p>
                        <p>Add SKU</p>
                      </div>
                    </div>
                  )}
                {selectedStream === "Pouches" && visibleInputs && (
                  <div className='sku-inputs'>
                    <Space.Compact>
                      <Input
                        placeholder='Pieces in a packet'
                        value={quantityValue}
                        onKeyPress={handleNumericInput}
                        onChange={(e) => setQuantityValue(e.target.value)}
                        disabled={
                          skuEditData && Object.keys(skuEditData).length > 0
                        }
                        suffix='Count'
                      />
                      {/* <Select
                        defaultValue={quantityType}
                        options={options}
                        onChange={handleSelectChange}
                        disabled={Object.keys(skuEditData).length > 0}
                      /> */}
                    </Space.Compact>
                    <Input
                      placeholder='Single Piece Price'
                      value={singlePiecePrice}
                      onKeyPress={handleNumericInput}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (!isNaN(value)) {
                          setSinglePiecePrice(value);
                          setBasePrice(value * quantityValue);
                          setPriceValue(value * quantityValue);
                        }
                      }}
                      suffix='Rs'
                    />
                    <Input
                      placeholder='Base Price'
                      value={basePrice}
                      onKeyPress={handleNumericInput}
                      onChange={(e) => setBasePrice(e.target.value)}
                      suffix='Rs'
                    />
                    <Input
                      placeholder='Sale Price'
                      value={priceValue}
                      onKeyPress={handleNumericInput}
                      onChange={(e) => setPriceValue(e.target.value)}
                      suffix='Rs'
                    />
                    <Select
                      value={taxPercent}
                      placeholder='Select Tax %'
                      onChange={(value) => setTaxPercent(value)}>
                      <Option value={0}>0%</Option>
                      <Option value={5}>5%</Option>
                      <Option value={12}>12%</Option>
                      <Option value={18}>18%</Option>
                    </Select>
                    <Space.Compact>
                      <Input
                        placeholder='Tax Value'
                        value={taxValue}
                        disabled
                      />
                      <Input
                        placeholder='Sub Total'
                        value={subTotal}
                        disabled
                      />
                    </Space.Compact>
                    <Input
                      placeholder='Number of packets'
                      value={packetsCount}
                      onKeyPress={handleNumericInput}
                      onChange={(e) => {
                        const value = e.target.value;

                        // Ensure the input is a valid number and round to the nearest integer
                        if (!isNaN(value) && value.trim() !== "") {
                          const roundedValue = Math.round(parseFloat(value));
                          setPacketsCount(roundedValue);
                        } else if (value === "") {
                          // Allow clearing the input
                          setPacketsCount("");
                        }
                      }}
                      disabled={
                        skuEditData && Object.keys(skuEditData).length > 0
                      }
                    />
                  </div>
                )}
                {selectedStream === "Pouches" &&
                  skuEditData &&
                  Object.keys(skuEditData)?.length === 0 && (
                    <div className='Add-sku'>
                      <small>You can add multiple SKU's</small>
                      <div
                        className='add-button pointer'
                        onClick={handlePiecesAddSku}>
                        <p>+</p>
                        <p>Add SKU</p>
                      </div>
                    </div>
                  )}
              </>
            )}

            <Form.Item
              name='description'
              label='Description'
              style={{ marginTop: productId ? "0px" : "10px" }}
              rules={[
                {
                  required: false,
                  message: "Please input!",
                },
              ]}>
              <TextArea
                rows={4}
                placeholder='Add description for product'
                maxLength={150}
                showCount
                disabled={
                  Object.keys(skuEditData).length > 0 || addSkuData?.length > 0
                }
              />
            </Form.Item>
            {(skuEditData && Object.keys(skuEditData).length > 0) ||
            addSkuData?.length > 0 ? (
              ""
            ) : (
              <div {...getRootProps()} class='upload-container'>
                <input {...getInputProps()} />
                {file && categoryImage ? (
                  <div className='uploaded-image-section'>
                    <div className='fileUpload-image-div'>
                      <img
                        src={file.preview || categoryImage}
                        alt={file.name}
                      />
                    </div>
                    <ImCross
                      className='text-md font-light cursor-pointer'
                      onClick={() => {
                        setFile(null);
                        setCategoryImage("");
                      }}
                    />
                  </div>
                ) : (
                  <CloudUploadOutlined className='cloud-icon' />
                )}
                <p class='upload-text'>
                  Browse/drag to {file && categoryImage ? "change" : "upload"}{" "}
                  File
                </p>
                {/* <p class='file-size-text'>File Size must be below 5 Mb</p> */}
              </div>
            )}
            {skuList?.length > 0 ? (
              ""
            ) : (
              <Form.Item>
                <div className='Addcategory-submit'>
                  <button
                    type='primary'
                    htmlType='submit'
                    className='Addcategory-submit-button pointer'>
                    {productId ? (
                      <p>Update Product</p>
                    ) : skuEditData && Object.keys(skuEditData)?.length > 0 ? (
                      <p>Update SKU</p>
                    ) : addSkuData && addSkuData?.length > 0 ? (
                      <p>Add SKU</p>
                    ) : (
                      <p>Add Product</p>
                    )}
                  </button>
                </div>
              </Form.Item>
            )}
          </div>
        </div>
        <div className='vertical-line'></div>
        <div className='SKUlist-section'>
          {" "}
          {skuList?.length > 0 && (
            <div className='sku-table'>
              <table>
                <thead>
                  <tr>
                    <th>S.No</th>
                    {/* {selectedStream === "Qty" ? ( */}
                    <th>Quantity / Pieces</th>
                    {/* ) : ( */}
                    {/* <th>Pieces in a pack</th> */}
                    {/* )} */}
                    {/* {selectedStream === "Pouches" && */}
                    <th>Piece Price</th>
                    {/* } */}
                    <th>B.Price</th>
                    <th>S.Price</th>
                    <th>Tax</th>
                    <th>Packets</th>
                  </tr>
                </thead>
                <tbody>
                  {skuList.map((sku, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <span>
                          {sku.quantityType === "gram" ||
                          sku.quantityType === "kg" ? (
                            <td style={{ textAlign: "center" }}>
                              {sku.quantityValue} {sku.quantityType}
                            </td>
                          ) : sku.piecesInaPack > 0 ? (
                            <td style={{ textAlign: "center" }}>
                              {sku.piecesInaPack}
                            </td>
                          ) : null}
                        </span>
                      </td>
                      {/* <td>{sku.piecesInaPack}</td> */}
                      {/* {selectedStream === "Pouches" && ( */}
                      <td>{sku.singlePiecePrice || "-"}</td>
                      {/* )} */}
                      <td>{sku.basePrice}</td>
                      <td>{sku.salePrice}</td>
                      <td>{sku?.tax}</td>
                      <td>{sku.packets}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {skuList?.length > 0 ? (
            <Form.Item>
              <div className='Addcategory-submit'>
                <button
                  type='primary'
                  htmlType='submit'
                  className='Addcategory-submit-button pointer'>
                  {productId ? (
                    <p>Update Product</p>
                  ) : skuEditData && Object.keys(skuEditData)?.length > 0 ? (
                    <p>Update SKU</p>
                  ) : addSkuData && addSkuData?.length > 0 ? (
                    <p>Add SKU</p>
                  ) : (
                    <p>Add Product</p>
                  )}
                </button>
              </div>
            </Form.Item>
          ) : (
            ""
          )}
        </div>
      </Form>
    </div>
  );
};

export default ProductAdd;
