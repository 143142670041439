import { Input, Spin, Switch, message } from "antd";
import React, { useEffect, useState } from "react";
import { IoAdd } from "react-icons/io5";
import { MdHdrPlus, MdOutlineEdit, MdPlusOne } from "react-icons/md";
import { postAuthApiData, putAuthApiData } from "../../api/apiService";
import useLogout from "../../utils/authUtils";

const SKUPacketContent = ({
  skuProductId,
  setSkuPacketModal,
  fetchProductList,
  skuData,
  setSkuData,
  fetchProductBySkuId,
  loading,
  setLoading,
}) => {
  const [error, setError] = useState("");
  const [packetsCount, setPacketsCount] = useState({});
  const logout = useLogout();

  console.log(skuProductId);

  console.log(skuData);

  const fetchPacketsEdit = async () => {
    const Endpoint = "editSkuPackets";
    const userData = skuData?.map(({ _id, newPackets }) => ({
      skuId: _id,
      packets: newPackets || 0,
    }));
    console.log(userData);
    try {
      setLoading(true);
      const { data, loading: postLoading } = await putAuthApiData(
        Endpoint,
        userData
      );
      console.log("Sku Packets Updated successful:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data.message);
        setSkuPacketModal(false);
        message.success(data?.message);
        fetchProductList();
        fetchProductBySkuId();
        setPacketsCount({});
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Sku Packets Updated failed:", error);
      message.error(error);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handlePacketsEdit = (index, e, item) => {
    const { value } = e.target;
    const newValue = parseInt(value, 10);
    setPacketsCount((prevCounts) => ({
      ...prevCounts,
      [index]: newValue,
    }));
    const updatedSkuData = skuData.map((sku) => {
      if (sku._id === item._id) {
        return { ...sku, newPackets: newValue };
      }
      return sku;
    });
    setSkuData(updatedSkuData);
  };

  // const handleNumericInput = (e) => {
  //   const charCode = e.charCode;
  //   console.log(charCode);
  //   const inputValue = e.target.value;
  //   if (charCode >= 48 && charCode <= 57) {
  //     console.log("valid number");
  //     return;
  //   } else {
  //     e.preventDefault();
  //     console.log("not a valid number");
  //     message.warning("Only numbers are allowed");
  //   }
  // };

  return (
    <Spin spinning={loading}>
      <div className='SKU-modal-container'>
        <div className='SKU-modal-header'>
          <h6>SKU's</h6>
        </div>
        <div className='SKU-modal-table'>
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                {/* {skuData[0]?.productStream === "Qty" ? ( */}
                {/* <th>Quantity</th> */}
                {/* ) : ( */}
                <th>Pieces in pouch /(Quantity)</th>
                {/* )} */}
                <th>Packets</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {skuData?.map((item, index) => (
                <tr key={item?._id}>
                  <td>{index + 1}</td>
                  {item?.productStream === "Qty" ? (
                    <td stye={{ textAlign: "center" }}>
                      {item?.quantityValue} {item?.quantityType}
                    </td>
                  ) : (
                    <td stye={{ textAlign: "center" }}>
                      {item?.piecesInaPack}
                    </td>
                  )}
                  <td>
                    <div className='flex'>
                      <span>{item?.packets}</span>
                    </div>
                  </td>
                  <td style={{ textAlign: "center", width: "20%" }}>
                    <Input
                      type='number'
                      value={packetsCount[index]}
                      min={1}
                      onChange={(e) => handlePacketsEdit(index, e, item)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className='SkuPacketsEdit-submit-section'>
          <button
            className='SkuPacketsEdit-cancel'
            onClick={() => setSkuPacketModal(false)}>
            Cancel
          </button>
          <button className='SkuPacketsEdit-submit' onClick={fetchPacketsEdit}>
            Submit
          </button>
        </div>
      </div>
    </Spin>
  );
};

export default SKUPacketContent;
