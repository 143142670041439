import React, { useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { MdOutlinePictureAsPdf } from "react-icons/md";
import { ImPrinter } from "react-icons/im";
import { FaPlus } from "react-icons/fa6";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import ShopKeeperpopup from "./ShopkeeperPopup";
import * as XLSX from "xlsx";

const FilterScreen = ({
  shopkeeperData,
  fetchShopKeepersList,
  activeCount,
  filter,
  setFilter,
  inactiveCount,
  searchInput,
  setSearchInput,
  totalCount,
}) => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const closeModal = () => {
    setShowModal(false);
  };

  const handleAddScaleClick = () => {
    setShowModal(true);
  };

  const handleFilterClick = (newFilter) => {
    setFilter(newFilter);
  };

  const onExportLocal = () => {
    // Check if the file has already been downloaded in this session
    if (localStorage.getItem("shopkeeperDataDownloaded")) {
      const downloadAgain = confirm(
        "The file has already been downloaded. Do you want to download it again?"
      );
      if (!downloadAgain) return; // Exit if the user doesn't want to re-download
    }

    const formattedData = shopkeeperData.map((item, index) => ({
      "S.No": index + 1,
      "Name of the shopkeeper": item.name,
      "Shop Name": item.shopName,
      "Shop Keeper Id": item.ShopkeeperId,
      Contact: item.phone,
      "E-mail": item.email,
      Address: item.address,
      Status: item.status,
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "shopkeeperData");

    const wscols = [
      { wch: 5 }, // S.No
      { wch: 30 }, // Name of the shopkeeper
      { wch: 30 }, // Shop Name
      { wch: 15 }, // Shop Keeper Id
      { wch: 15 }, // Contact
      { wch: 30 }, // E-mail
      { wch: 30 }, // Address
      { wch: 10 }, // Status
    ];
    worksheet["!cols"] = wscols;

    // Generate the file and trigger download
    XLSX.writeFile(workbook, "shopkeeperData.xlsx");

    // Set a flag in localStorage to mark the file as downloaded
    localStorage.setItem("shopkeeperDataDownloaded", "true");
  };

  // const handlePrint = () => {
  //   const printWindow = window.open("", "_blank");
  //   const printData = shopkeeperData
  //     .map(
  //       (item, index) => `
  //     <tr>
  //       <td>${index + 1}</td>
  //       <td>${item.name}</td>
  //       <td>${item.shopName}</td>
  //       <td>${item.ShopkeeperId}</td>
  //       <td>${item.phone}</td>
  //       <td>${item.email}</td>
  //       <td>${item.address}</td>
  //       <td style="color: ${item.status === "active" ? "green" : "red"}">${
  //         item.status
  //       }</td>
  //     </tr>
  //   `
  //     )
  //     .join("");

  //   printWindow.document.write(`
  //     <html>
  //     <head>
  //       <title>Shopkeeper Data</title>
  //       <style>
  //         table {
  //           width: 100%;
  //           border-collapse: collapse;
  //         }
  //         th, td {
  //           border: 1px solid black;
  //           padding: 8px;
  //           text-align: left;
  //         }
  //         th {
  //           background-color: #f2f2f2;
  //         }
  //         td:nth-child(1), th:nth-child(1) { /* S.No column */
  //           width: 5%;
  //         }
  //         td:nth-child(2), th:nth-child(2) { /* Name column */
  //           width: 20%;
  //         }
  //         td:nth-child(3), th:nth-child(3) { /* Shop Name column */
  //           width: 20%;
  //         }
  //         td:nth-child(4), th:nth-child(4) { /* Shop Keeper Id column */
  //           width: 10%;
  //         }
  //         td:nth-child(5), th:nth-child(5) { /* Contact column */
  //           width: 10%;
  //         }
  //         td:nth-child(6), th:nth-child(6) { /* E-mail column */
  //           width: 15%;
  //         }
  //         td:nth-child(7), th:nth-child(7) { /* Address column */
  //           width: 15%;
  //         }
  //         td:nth-child(8), th:nth-child(8) { /* Status column */
  //           width: 5%;
  //         }
  //       </style>
  //     </head>
  //     <body>
  //       <h1>Shopkeeper Data</h1>
  //       <table>
  //         <thead>
  //           <tr>
  //             <th>S.No</th>
  //             <th>Name of the shopkeeper</th>
  //             <th>Shop Name</th>
  //             <th>Shop Keeper Id</th>
  //             <th>Contact</th>
  //             <th>E-mail</th>
  //             <th>Address</th>
  //             <th>Status</th>
  //           </tr>
  //         </thead>
  //         <tbody>
  //           ${printData}
  //         </tbody>
  //       </table>
  //     </body>
  //     </html>
  //   `);

  //   printWindow.document.close();
  //   printWindow.print();
  // };

  return (
    <div className='filterscreen-container'>
      <div className='filterscreen-content'>
        <div className='screen-left-content'>
          <Input
            type='search'
            placeholder='Search by shop keeper name'
            className='search-input'
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            suffix={<SearchOutlined className='search-icon' />}
          />
          <div className='Shopkeeper_filterbuttons'>
            <button
              className={filter === "All" ? "active" : ""}
              onClick={() => handleFilterClick("All")}>
              ALL ({totalCount})
            </button>
            <button
              className={filter === "Active" ? "active" : ""}
              onClick={() => handleFilterClick("Active")}>
              ACTIVE ({activeCount})
            </button>
            <button
              className={filter === "Inactive" ? "active" : ""}
              onClick={() => handleFilterClick("Inactive")}>
              INACTIVE ({inactiveCount})
            </button>
          </div>
        </div>
        <div className='screen-right-content'>
          <div className='pdf-section' onClick={onExportLocal}>
            <MdOutlinePictureAsPdf style={{ fontSize: "18px" }} />
            <span className='pdf-content'>Export to CSV</span>
          </div>
          {/* <div className='print-section' onClick={handlePrint}>
            <ImPrinter size={15} />
            <span>Print</span>
          </div> */}
          <div className='ShopKeeper_Add_b'>
            <button
              className='ShopKeeper_add-scale-button'
              onClick={handleAddScaleClick}>
              <FaPlus style={{ fontSize: "16px" }} /> Add New ShopKeeper
            </button>
          </div>
          <Modal
            visible={showModal}
            onCancel={closeModal}
            closeIcon={false}
            centered
            footer={null}
            className='Shopkeeper-modal'>
            <ShopKeeperpopup
              closeModal={closeModal}
              fetchShopKeepersList={fetchShopKeepersList}
            />
          </Modal>
        </div>
      </div>
    </div>
  );
};
// FilterScreen
export default FilterScreen;
