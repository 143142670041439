import { Form, Input, Checkbox, Col, Row } from "antd";

import React, { useState } from "react";

const AddAddActivity = ({
  close,
  setAddRoleReviewModal,
  setAddRoleModal,
  featuresData,
  setCheckedActivities,
  checkedActivities,
  roleName,
  setRoleName,
}) => {
  const [form] = Form.useForm();

  const onChange = (checkedValues) => {
    console.log("checked = ", checkedValues);
    setCheckedActivities(checkedValues);
  };
  console.log(checkedActivities);

  const onFinish = async (values) => {
    console.log(values);
    close();
    setAddRoleReviewModal(true);
  };
  console.log(featuresData);
  return (
    <div className='Add-role-container'>
      <div className='Add-role-content'>
        <h6 style={{ textAlign: "center", marginBottom: "10px" }}>
          Add New Role
        </h6>
        <Form
          form={form}
          name='normal_login'
          className='edit-profile-form'
          layout='vertical'
          onFinish={onFinish}>
          <Form.Item
            name='role'
            label='Add Role Name'
            initialValue={roleName}
            rules={[
              {
                required: true,
                message: "Please enter role name",
              },
            ]}>
            <Input
              type='text'
              onChange={(e) => setRoleName(e.target.value)}
              placeholder='Enter new role'
            />
          </Form.Item>
          <Checkbox.Group
            style={{
              width: "100%",
            }}
            value={checkedActivities}
            onChange={onChange}>
            <Row style={{ marginBottom: "10px" }}>
              {featuresData?.length > 0
                ? featuresData.map((item) => (
                    <Col
                      span={8}
                      style={{ marginBottom: "12px" }}
                      key={item._id}>
                      <Checkbox value={item}>{item?.featureName}</Checkbox>
                    </Col>
                  ))
                : ""}
            </Row>
          </Checkbox.Group>
          <Form.Item>
            <div className='add-role-submit'>
              <div className='add-role-cancel-button' onClick={close}>
                <p>Cancel</p>
              </div>
              {checkedActivities?.length > 0 ? (
                <button
                  type='primary'
                  htmlType='submit'
                  className='add-role-submit-button'>
                  <p>Submit</p>
                </button>
              ) : (
                <div className='add-role-submit-btn'>
                  <p>Submit</p>
                </div>
              )}
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default AddAddActivity;
