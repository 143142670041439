import React, { useEffect, useState } from "react";
import { Avatar, message, Modal, Select, Spin } from "antd";
import { postAuthApiData } from "../../api/apiService";
import useLogout from "../../utils/authUtils";
import { MdOutlineEdit } from "react-icons/md";
import moment from "moment";

const ProductList = ({
  item,
  index,
  currentPage,
  pageSize,
  showModal,
  setSkuData,
}) => {
  const [selectedSku, setSelectedSku] = useState(item?.totalSKUs[0]);
  const [historyModal, setHistoryModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [historyData, setHistoryData] = useState([]);
  const logout = useLogout();

  useEffect(() => {
    // Update the selectedSku when the item prop changes
    setSelectedSku(item?.totalSKUs[0]);
  }, [item]);

  const handleSkuChange = (value) => {
    console.log(value);
    const selected = item.totalSKUs.find((sku) => sku._id === value);
    setSelectedSku(selected);
  };
  console.log(historyData?.length);

  const fetchStockHistory = async (id) => {
    const Endpoint = "productHistory";
    try {
      setLoading(true);
      const userData = {
        skuId: id,
      };
      const { data, loading: postLoading } = await postAuthApiData(
        Endpoint,
        userData
      );
      console.log("Sku history fethced successful:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data.message);
        setHistoryData(data?.data);
        message.success(data?.message);
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Sku history fethced failed:", error);
      message.error("Something went wrong please try later");
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "In stock":
        return "in-stock";
      case "Out of stock":
        return "out-of-stock";
      case "Low in stock":
        return "low-in-stock";
      default:
        return "";
    }
  };

  const handleHistory = (id) => {
    console.log(id);
    setHistoryModal(true);
    fetchStockHistory(id);
  };

  console.log(selectedSku);
  console.log("AddProduct List Component");

  return (
    <>
      <tr key={item.id}>
        <td>{(currentPage - 1) * pageSize + index + 1}</td>
        <td>
          <div
            className='product-name'
            style={{ textAlign: "left", maxWidth: "130px" }}>
            {item?.productImage ? (
              <Avatar src={item?.productImage} size={40} className='image' />
            ) : (
              <Avatar
                shape='circle'
                size={40}
                className='image'
                style={{
                  backgroundColor: "#F1ECFF",
                  fontWeight: 600,
                  fontSize: 17,
                  color: "#6750A4",
                  // border: "1px solid #6750A4",
                }}>
                {item.productName?.length > 0 &&
                  item.productName[0]?.charAt(0)?.toUpperCase()}
              </Avatar>
            )}
            <div>{item.productName}</div>
          </div>
        </td>
        <td className='stockm-body-sku'>
          <Select
            placeholder='Select Sku'
            //   showSearch
            popupMatchSelectWidth={170}
            style={{ minWidth: "120px" }}
            value={
              selectedSku?.productStream === "Pouches"
                ? selectedSku?.PouchesSku
                : selectedSku?.quantity
            }
            //   filterOption={(input, option) =>
            //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            //   }
            onChange={handleSkuChange}>
            {item?.totalSKUs?.map((sku) => (
              <Select.Option key={sku?._id} value={sku?._id}>
                {sku?.productStream === "Pouches"
                  ? sku?.PouchesSku
                  : sku?.quantity}
              </Select.Option>
            ))}
          </Select>
        </td>
        <td className='StockM-body-baseprice'>{selectedSku?.basePrice}</td>
        <td className='StockM-body-sellingprice'>{selectedSku?.salePrice}</td>
        <td className='StockM-body-units'>
          {selectedSku?.productStream === "Qty"
            ? `${selectedSku?.totalQuantityOfSKU.toFixed(0)} Kgs [${
                selectedSku?.qtyPackets + selectedSku?.pouchPackets
              } Pkt]`
            : `${selectedSku?.qtyPackets + selectedSku?.pouchPackets} Pkt`}
        </td>

        <td className='StockM-body-date'>
          {selectedSku?.updatedDate
            ? moment(selectedSku?.updatedDate).format("DD-MM-YYYY")
            : "--"}
        </td>
        <td className='StockM-body-status capital'>
          <span
            className={`status ${getStatusClass(selectedSku?.stockStatus)}`}>
            {selectedSku?.stockStatus}
          </span>
        </td>
        <td>
          <button
            className='add-stock-button'
            onClick={() => {
              setSkuData(selectedSku);
              showModal(selectedSku);
            }}>
            Add Stock
          </button>
        </td>
        <td onClick={() => handleHistory(selectedSku?._id)}>
          <button className='add-stock-button'>History</button>
        </td>
      </tr>
      <Modal
        visible={historyModal}
        onCancel={() => setHistoryModal(false)}
        centered
        maxWidth={1000}
        footer={null}
        width={800}
        className='custom-modal'>
        <Spin spinning={loading}>
          <div className='stock-history-list'>
            <h5 style={{ textAlign: "center" }}>Stock history</h5>
            <div className='stock-table'>
              <table>
                <thead className=''>
                  <tr>
                    <th className=''>S.No</th>
                    <th className=''>Product Name</th>
                    <th className=''>Sku</th>
                    <th className=''>Previous stock</th>
                    <th className=''>Added stock</th>
                    <th className=''>Stock added date</th>
                  </tr>
                </thead>
                {historyData?.map((stock, index) => (
                  <tbody className='shopkeeper_table-body'>
                    <tr key={stock.skuId}>
                      <td>{index + 1}</td>
                      <td>
                        <div className='shopkeeper_exacutive-section capital'>
                          {stock?.productName}
                        </div>
                      </td>
                      <td className='capital'>
                        {stock?.productStream === "Qty"
                          ? `${stock?.quantityValue} ${stock?.quantityType}`
                          : `${stock?.piecesInaPack} @${stock?.piecesInaPack}`}
                      </td>
                      <td>{`${stock?.previousStock} Pkt`}</td>
                      <td>{`${stock?.addedStock} Pkt`}</td>
                      <td>
                        {stock?.date
                          ? moment(stock.date).format("DD-MM-YYYY HH:mm:ss")
                          : "-"}
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
              {historyData?.length === 0 ? (
                <div className='no-data-message'>
                  <p>No Stock history found</p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default ProductList;
