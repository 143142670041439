import { Form, Input, Select, Space, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import {
  getApiData,
  postAuthApiData,
  putAuthApiData,
} from "../../api/apiService";
import useLogout from "../../utils/authUtils";

const AddStock = ({
  setActiveSuccess,
  setIsModalVisible,
  selectedSkuData,
  handleCancel,
  fetchStockProductDetails,
  fetchListOfProducts,
  fetchListOfSelling,
  fetchListofStockStatus,
  currentPage,
}) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [productData, setProductData] = useState([]);
  const [skuData, setSkuData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [packetsValue, setPacketsValue] = useState("");
  const [kgValue, setKgValue] = useState("");
  const [isPacketsInputDisabled, setPacketsInputDisabled] = useState(false);
  const [isKgInputDisabled, setKgInputDisabled] = useState(false);
  const [skuId, setSkuId] = useState("");
  const [selectedSku, setSelectedSku] = useState({});
  const logout = useLogout();

  // console.log(selectedSkuData);

  const onFinish = async (values) => {
    console.log(values);
    const Endpoint = "restockProductSku";
    try {
      setLoading(true);
      const userData = {
        productId: values.product_name,
        skus: [{ skuId: skuId, packets: packetsValue }],
      };
      console.log(userData, skuId);
      const { data, loading: postLoading } = await putAuthApiData(
        Endpoint,
        userData
      );
      console.log("Stock Added successful:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        localStorage.setItem("stock-page", currentPage);
        setIsModalVisible(false);
        await fetchStockProductDetails();
        form.resetFields();
        setPacketsValue("");
        setKgValue("");
        setKgInputDisabled(false);
        setPacketsInputDisabled(false);
        setActiveSuccess(true);
        setSelectedSku({});
        fetchListOfProducts();
        fetchListOfSelling();
        fetchListofStockStatus();
        setSkuId("");
      } else if (data.responseCode === 400) {
        message.warning(data?.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
      console.log(data.message);
    } catch (error) {
      console.log("Stock Adding failed:", error);
      message.error(error);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  // const parseQuantity = (quantity) => {
  //   const match = quantity?.match(/^(\d*\.?\d+)\s*(.*)$/);
  //   if (match) {
  //     return {
  //       number: parseFloat(match[1]),
  //       unit: match[2]?.trim(),
  //     };
  //   }
  //   return {
  //     number: null,
  //     unit: "",
  //   };
  // };
  // const parsedQuantity = parseQuantity(selectedSku?.quantity);
  // console.log(parsedQuantity);

  const fetchProductList = async () => {
    const Endpoint = "getListOfProducts";
    try {
      setLoading(true);
      const { data, loading: postLoading } = await getApiData(Endpoint, true);
      console.log("Product List fetched successful:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data.message);
        setProductData(data?.data);
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data?.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Product List fetching failed:", error);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSkuListByProductId = async () => {
    const Endpoint = "getSKUListByProductId";
    try {
      setLoading(true);
      const userData = {
        productId: selectedSkuData?.productId,
      };
      const { data, loading: postLoading } = await postAuthApiData(
        Endpoint,
        userData
      );
      console.log("Sku List By ProductId fetched successful:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data.message);
        setSkuData(data?.skuList);
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Sku List By ProductId fetched failed:", error);
      message.error(error);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProductList();
    fetchSkuListByProductId();
    setSkuId(selectedSkuData?._id);
    console.log("Calling UseEffect");
    form.setFieldsValue({
      product_name: selectedSkuData?.productId,
      Sku:
        selectedSkuData?.productStream === "Pouches"
          ? selectedSkuData?.PouchesSku
          : selectedSkuData?.quantity,
    });
    setSelectedSku(selectedSkuData);
  }, [selectedSkuData]);

  useEffect(() => {
    if (packetsValue) {
      const packets = Math.round(parseFloat(packetsValue)); // Round packets to integer
      const calculatedKg =
        selectedSku?.quantityType === "gram"
          ? Math.round((packets * selectedSku?.quantityValue) / 1000) // Round calculated Kg
          : Math.round(packets * selectedSku?.quantityValue); // Round calculated Kg
      setKgValue(calculatedKg); // Set rounded Kg value
    }
  }, [packetsValue]);

  const handlePacketsChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      setKgValue("");
      setPacketsValue("");
      setKgInputDisabled(false);
      return;
    }

    const packets = Math.round(parseFloat(value)); // Round packets to integer
    setPacketsValue(packets); // Store rounded packets
    setKgInputDisabled(true);

    const calculatedKg =
      selectedSku?.quantityType === "gram"
        ? Math.round((packets * selectedSku?.quantityValue) / 1000) // Round calculated Kg
        : Math.round(packets * selectedSku?.quantityValue); // Round calculated Kg
    setKgValue(calculatedKg); // Set rounded Kg value
  };

  const handleKgChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      setPacketsValue("");
      setKgValue("");
      setPacketsInputDisabled(false);
      return;
    }

    const kgs = Math.round(parseFloat(value)); // Round Kg to integer
    setKgValue(kgs); // Store rounded Kg value
    setPacketsInputDisabled(true);

    const calculatedPackets =
      selectedSku?.quantityType === "gram"
        ? Math.round((kgs * 1000) / selectedSku?.quantityValue) // Round calculated packets
        : Math.round(kgs / selectedSku?.quantityValue); // Round calculated packets
    setPacketsValue(calculatedPackets); // Set rounded packets
  };

  console.log(selectedSku);

  return (
    <Spin spinning={loading}>
      <div>
        <div className='restock-modal-content'>
          <h5 style={{ textAlign: "center" }}>Add Stock</h5>
          <Form
            form={form}
            name='normal_login'
            initialValues={{
              remember: true,
            }}
            layout='vertical'
            onFinish={onFinish}>
            <Form.Item
              name='product_name'
              label='Product Name'
              rules={[
                {
                  required: true,
                  message: "Please enter shop keeper name",
                },
              ]}>
              <Select
                placeholder='Select Product'
                allowClear
                size='large'
                showSearch
                //   value={selectedSkuData?.productId}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                disabled>
                {productData?.map((item, index) => (
                  <Select.Option
                    key={item?.product?._id}
                    value={item?.product?._id}>
                    <span className='stock-section'>
                      {item?.product?.productName}
                    </span>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name='Sku'
              label='Sku'
              rules={[
                {
                  required: true,
                  message: "Please enter shop keeper name",
                },
              ]}
              initialValue={
                selectedSku?.productStream === "Pouches"
                  ? selectedSku?.PouchesSku
                  : selectedSku?.quantity
              }>
              <Select
                placeholder='Select Sku Type'
                size='large'
                // disabled
                onSelect={(value) => {
                  const selectedItem = skuData.find(
                    (item) => item._id === value
                  );
                  // onSelectItem(selectedItem);
                  setSelectedSku(selectedItem);
                  setSkuId(selectedItem?._id);
                  console.log(selectedItem);
                }}>
                {skuData?.map((item) => (
                  <Select.Option key={item._id} value={item._id}>
                    {item.quantityValue
                      ? `${item.quantityValue} ${item.quantityType}`
                      : `${item.piecesInaPack} Pieces/Pack`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name='noofPackets'
              label='Add Number Of Packets'
              rules={[
                {
                  required: true,
                  message: "Please add sku quantity",
                },
              ]}>
              <Space.Compact size='large'>
                <Input
                  placeholder='00 packets'
                  value={packetsValue}
                  onChange={handlePacketsChange}
                  disabled={isPacketsInputDisabled}
                />
                {selectedSku?.productStream === "Pouches" ? (
                  console.log("Hello")
                ) : (
                  <Input
                    placeholder='0.0 (kg)'
                    value={kgValue}
                    onChange={handleKgChange}
                    disabled={isKgInputDisabled}
                  />
                )}
              </Space.Compact>
            </Form.Item>
            <div className='action-buttons'>
              <div
                className='cancel-btn'
                onClick={() => setIsModalVisible(false)}>
                Cancel
              </div>
              <button className='submit-btn' type='primary' htmlType='submit'>
                Submit
              </button>
            </div>
          </Form>
        </div>
      </div>
    </Spin>
  );
};

export default AddStock;
