import React, { useEffect, useState } from "react";
import { Col, Form, Input, Row, Select, Space, message } from "antd";
import { useNavigate } from "react-router-dom";
import { FaRegCreditCard } from "react-icons/fa";
import { getApiData, postAuthApiData } from "../../api/apiService";
import { useDispatch, useSelector } from "react-redux";
import {
  OfflineAddCustomer,
  UpdatingSelectedProducts,
  removeSelectedProduct,
  resetSelectedProducts,
  updateProductDiscount,
} from "../../redux/action"; // Ensure you import the new action
import useLogout from "../../utils/authUtils";
import { BiTrash } from "react-icons/bi";
import { EditOutlined } from "@ant-design/icons";

const AddProductOnlineSale = ({ setLoading, setError, error }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [productData, setProductData] = useState([]);
  const [productSelected, setProductSelected] = useState("");
  const [skuData, setSkuData] = useState([]);
  const [grams, setGrams] = useState(0);
  const [kgValue, setKgValue] = useState("");
  const [selectedSku, setSelectedSku] = useState({});
  const [selectedPackets, setSelectedPackets] = useState(0);
  const [selectedProductId, setSelectedProductId] = useState("");
  const [packetsValue, setPacketsValue] = useState("");
  const [packetsInput, setPacketsInput] = useState(false);
  const [kgInput, setKgInput] = useState(false);
  const [isPacketsInputDisabled, setPacketsInputDisabled] = useState(false);
  const [isKgInputDisabled, setKgInputDisabled] = useState(false);
  const [discounts, setDiscounts] = useState({});
  const [hideKgInput, setHideKgInput] = useState(false);
  const [editProduct, setEditProduct] = useState({});
  const [editStatus, setEditStatus] = useState(false);
  const logout = useLogout();

  const CustomerDetails = useSelector((state) => state.offlineAddCustomer);
  console.log(CustomerDetails);
  const SelectedProducts = useSelector((state) => state.selectedProducts);
  console.log(SelectedProducts);

  const id = localStorage.getItem("onlineOrderBookingId");

  const handlePaymentMethodSelect = (method) => {
    console.log(method);
    localStorage.setItem("paymentMethod", method);
    if (
      Object.keys(CustomerDetails).length > 0 &&
      SelectedProducts.length > 0
    ) {
      handleBooking();
    } else {
      message.warning("Please add products and shopkeeper details");
    }
  };

  const handleBooking = async () => {
    const Endpoint = "onlineOrderBookingNewinfo";
    const payment_method = localStorage.getItem("paymentMethod");
    try {
      setLoading(true);
      const userData = {
        newOrderBookingId: CustomerDetails?.OrderBookingId,
        shopkeeperId: CustomerDetails?.CustomerId,
        products: SelectedProducts,
        paymentType: payment_method,
      };
      const { data, loading: postLoading } = await postAuthApiData(
        Endpoint,
        userData
      );
      console.log("Offline Order successful:", data);
      message.success(data?.message);
      setLoading(postLoading);
      localStorage.setItem(
        "onlineOrderBookingId",
        data?.data?.length > 0 && data?.data[0]?.newOrderBookingId
      );
      if (data?.responseCode === 200) {
        console.log(data.message);
        if (payment_method === "spotpay") {
          navigate("/onlineOrder-summary");
        } else if (payment_method === "creditpay") {
          navigate("/onlineCreditOrder-summary");
        }
        dispatch(resetSelectedProducts());
        dispatch(OfflineAddCustomer({}));
      } else if (data?.responseCode === 400) {
        console.log(data.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Offline Order  failed:", error);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSkuListByProductId = async (id) => {
    const Endpoint = "getSKUListByProductId";
    try {
      setLoading(true);
      const userData = {
        productId: id,
      };
      const { data, loading: postLoading } = await postAuthApiData(
        Endpoint,
        userData
      );
      console.log("Sku List By ProductId fetched successful:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data.message);
        setSkuData(data?.skuList);
        return data?.skuList;
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Sku List By ProductId fetched failed:", error);
      message.error(error);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (packetsValue) {
      const packets = Math.round(parseFloat(packetsValue)); // Round to integer
      const calculatedKg =
        selectedSku?.quantityType === "gram"
          ? Math.round((packets * grams) / 1000) // Round calculation
          : Math.round(packets * grams); // Round calculation
      setKgValue(calculatedKg);
    }
  }, [packetsValue, grams, selectedSku]);

  const handlePacketsChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      setKgValue("");
      setPacketsValue("");
      setKgInputDisabled(false);
      return;
    }

    const packets = Math.round(parseFloat(value)); // Round to integer
    setPacketsValue(packets); // Store rounded value
    setKgInputDisabled(true);

    const calculatedKg =
      selectedSku?.quantityType === "gram"
        ? Math.round((packets * grams) / 1000) // Round calculation
        : Math.round(packets * grams); // Round calculation
    setKgValue(calculatedKg); // Set rounded Kg value
  };

  const handleKgChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      setPacketsValue("");
      setKgValue("");
      setPacketsInputDisabled(false);
      return;
    }

    const kgs = Math.round(parseFloat(value)); // Round to integer
    setKgValue(kgs); // Store rounded value
    setPacketsInputDisabled(true);

    const calculatedPackets =
      selectedSku?.quantityType === "gram"
        ? Math.round((kgs * 1000) / grams) // Round calculation
        : Math.round(kgs / grams); // Round calculation
    setPacketsValue(calculatedPackets); // Set rounded packets value
  };

  const handleSkuSelect = (value) => {
    const selectedItem = JSON.parse(value);
    console.log(selectedItem);
    console.log(selectedItem?.quantityValue);
    setSelectedSku(selectedItem);
    setGrams(selectedItem?.quantityValue);
    if (selectedItem?.productStream === "Pouches") {
      form.setFieldValue(
        "sku",
        `${selectedItem?.piecesInaPack} Pouches/Packet  @${selectedItem?.singlePiecePrice}`
      );
      setHideKgInput(true);
    } else {
      form.setFieldValue(
        "sku",
        `${selectedItem?.quantityValue} ${selectedItem?.quantityType} @${selectedItem?.salePrice}`
      );
      setHideKgInput(false);
    }
  };

  const handleProductSelect = (value) => {
    const selectedProduct = JSON.parse(value);
    console.log(selectedProduct);
    setKgValue("");
    setHideKgInput(false);
    setProductSelected("");
    setPacketsValue("");
    form.resetFields(["sku", "noofPackets"]);
    setPacketsInputDisabled(false);
    setKgInputDisabled(false);
    setSelectedSku({});
    setSkuData([]);
    setProductSelected(selectedProduct.productName);
    setSelectedProductId(selectedProduct.productId);
    fetchSkuListByProductId(selectedProduct.productId);
    form.setFieldValue("product", selectedProduct.productName);
    setHideKgInput(false);
  };

  const fetchProductList = async () => {
    const Endpoint = "getListOfProducts";
    try {
      setLoading(true);
      const { data, loading: postLoading } = await getApiData(Endpoint, true);
      console.log("Product List fetched successful:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data.message);
        setProductData(data?.data);
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data?.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Product List fetching failed:", error);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProductList();
  }, []);

  const onFinish = async (values) => {
    console.log(values);
    console.log(productSelected);
    console.log(kgValue);
    console.log(selectedSku);
    const isSkuIdExist = SelectedProducts?.some(
      (product) => product.skuId === selectedSku?._id
    );
    console.log(isSkuIdExist);
    if (!isSkuIdExist || editStatus) {
      if (packetsValue <= selectedSku?.packets) {
        const userData = {
          productName: productSelected,
          productId: selectedProductId,
          quantity: kgValue,
          salePrice: selectedSku?.salePrice,
          packets: packetsValue,
          skuId: selectedSku?._id || selectedSku?.skuId,
          discount: 0,
          tax: selectedSku?.tax || 0,
          quantityValue: selectedSku?.quantityValue,
          quantityType: selectedSku?.quantityType,
          productStream: selectedSku?.singlePiecePrice ? "Pouches" : "Qty",
          final_price: selectedSku?.salePrice * packetsValue,
          sub_total: selectedSku?.salePrice * packetsValue,
        };
        if (selectedSku?.singlePiecePrice) {
          userData.singlePiecePrice = selectedSku?.singlePiecePrice;
        }
        if (selectedSku?.piecesInaPack) {
          userData.piecesInaPack = selectedSku?.piecesInaPack;
        }
        console.log(userData);
        dispatch(UpdatingSelectedProducts(userData));
        form.resetFields();
        setKgValue("");
        setProductSelected("");
        setPacketsValue("");
        setHideKgInput(false);
        setPacketsInputDisabled(false);
        setKgInputDisabled(false);
        setSelectedSku({});
        setSkuData([]);
        setEditStatus(false);
      } else {
        message.warning(
          `The entered quantity exceeds the available stock. Only ${Math.round(
            selectedSku?.packets
          )} packets are available.`
        );
      }
    } else {
      message.warning("The Product Sku is already added");
    }
  };

  //   console.log(values);
  //   console.log(productSelected);
  //   console.log(kgValue);
  //   console.log(selectedSku);
  //   const isSkuIdExist = SelectedProducts?.some(
  //     (product) => product.skuId === selectedSku?._id
  //   );
  //   console.log(isSkuIdExist);
  //   if (!isSkuIdExist) {
  //     if (packetsValue <= selectedSku?.packets) {
  //       const userData = {
  //         productName: productSelected,
  //         productId: selectedProductId,
  //         quantity: kgValue,
  //         salePrice: selectedSku?.salePrice,
  //         packets: packetsValue,
  //         skuId: selectedSku?._id,
  //         discount: 0,
  //         tax: 0,
  //         quantityValue: selectedSku?.quantityValue,
  //         quantityType: selectedSku?.quantityType,
  //         productStream: selectedSku?.singlePiecePrice ? "Pouches" : "Qty",
  //         final_price: selectedSku?.salePrice * packetsValue,
  //         sub_total: selectedSku?.salePrice * packetsValue,
  //       };
  //       if (selectedSku?.singlePiecePrice) {
  //         userData.singlePiecePrice = selectedSku?.singlePiecePrice;
  //       }
  //       if (selectedSku?.piecesInaPack) {
  //         userData.piecesInaPack = selectedSku?.piecesInaPack;
  //       }
  //       console.log(userData);
  //       dispatch(UpdatingSelectedProducts(userData));
  //       form.resetFields();
  //       setKgValue("");
  //       setProductSelected("");
  //       setPacketsValue("");
  //       setHideKgInput(false);
  //       setPacketsInputDisabled(false);
  //       setKgInputDisabled(false);
  //       setSelectedSku({});
  //       setSkuData([]);
  //     } else {
  //       // message.warning("The entered quantity exceeds the available stock.");
  //       message.warning(
  //         `The entered quantity exceeds the available stock. Only ${selectedSku?.packets} packets are available.`
  //       );
  //     }
  //   } else {
  //     message.warning("The Product Sku is already added");
  //   }
  // };

  const totalPayableAmount = SelectedProducts.reduce(
    (total, product) => total + product.final_price,
    0
  );

  const handleDiscountChange = (index, value) => {
    let discount = parseFloat(value);

    if (value === "") {
      discount = 0; // Set discount to 0 when input is cleared
    }

    setDiscounts((prevDiscounts) => ({
      ...prevDiscounts,
      [index]: value, // Keep the raw input value in the state
    }));

    if (isNaN(discount)) return;

    const product = SelectedProducts[index];
    const finalPrice = product.salePrice * product.packets;
    const newFinalPrice = finalPrice - discount;
    if (discount >= finalPrice) {
      message.warning("Discount exceeds the actual price");
    } else {
      const newFinalPrice = finalPrice - discount;
      dispatch(updateProductDiscount(index, discount, newFinalPrice));
    }
  };

  const handleDeleteProduct = async (product) => {
    const Endpoint = "removeOnlineProduct";
    try {
      setLoading(true);
      const userData = { orderBookingId: id, infoId: product?.infoId };
      const { data, loading: postLoading } = await postAuthApiData(
        Endpoint,
        userData
      );
      console.log("Product Sku Deleted Succeessfully:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data.message);
        dispatch(removeSelectedProduct(product?.skuId));
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Product Sku Deleting failed:", error);
      message.error(error);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveProduct = (product) => {
    if (product?.infoId) {
      handleDeleteProduct(product);
      console.log("Info id is available");
    } else {
      dispatch(removeSelectedProduct(product?.skuId));
      console.log("Info id is not available");
    }
  };

  const handleEditProduct = async (product) => {
    console.log(product);
    const data = await fetchSkuListByProductId(product?.productId);
    console.log(data);
    setSelectedSku(data?.find((item) => item?._id === product?.skuId));
    form.setFieldValue("product", product?.productName);
    setProductSelected(product.productName);
    setSelectedProductId(product?.productId);
    fetchSkuListByProductId(product?.productId);
    if (product?.productStream === "Pouches") {
      form.setFieldValue(
        "sku",
        `${product?.piecesInaPack} Pouches/Packet  @${product?.singlePiecePrice}`
      );
      setHideKgInput(true);
    } else {
      form.setFieldValue(
        "sku",
        `${product?.quantityValue} ${product?.quantityType} @${product?.salePrice}`
      );
      setHideKgInput(false);
    }
    setPacketsValue(product?.packets);
    setEditStatus(true);
    setGrams(product?.quantityValue);
    // setKgValue(product?.quantity);
  };

  console.log(selectedSku);

  return (
    <div>
      <h2 className='AddOfflineSales_Product_Details'>Product Details</h2>
      <Form
        form={form}
        className='addofflinseSales-form'
        layout='vertical'
        autoComplete='off'
        onFinish={onFinish}>
        <div className='AddOfflineSales_Product'>
          <Row gutter={8}>
            <Col lg={6} md={6} sm={6}>
              <Form.Item
                name='product'
                label='Select Product'
                rules={[
                  {
                    required: true,
                    message: "Please Select Product",
                  },
                ]}>
                <Select
                  placeholder='Select Product'
                  allowClear
                  popupMatchSelectWidth={350}
                  size='large'
                  showSearch
                  disabled={editStatus}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={handleProductSelect}>
                  {productData
                    ?.filter(
                      (item) =>
                        item?.product?.productStatus === "active" &&
                        item?.packetsCount !== 0.0
                    )
                    ?.map((item, index) => (
                      <Select.Option
                        key={item?.product?._id}
                        label={`${item?.product?.productName || ""} ${
                          item?.product?.productCode || ""
                        }`}
                        value={JSON.stringify({
                          productId: item?.product?._id,
                          productName: item?.product?.productName,
                        })}>
                        <span className='stock-section'>
                          {`${item?.product?.productName} (${item?.product?.productCode})`}
                          <span className='avail-stock'>
                            {item?.product?.productStream === "Qty"
                              ? `${Math.round(
                                  item?.totalQuantityInKg
                                )} kg avail`
                              : `${Math.round(
                                  item?.packetsCount
                                )} packets avail`}
                          </span>
                        </span>
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={6} md={6} sm={6}>
              <Form.Item
                name='sku'
                label='Select Sku Type'
                rules={[
                  {
                    required: true,
                    message: "Please Select Sku Type",
                  },
                ]}>
                <Select
                  placeholder='Select Sku Type'
                  allowClear
                  popupMatchSelectWidth={350}
                  showSearch
                  disabled={editStatus}
                  size='large'
                  filterOption={(input, option) => {
                    const optionText = String(option.children);
                    return optionText
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  onChange={handleSkuSelect}>
                  {skuData
                    ?.filter((item) => item?.status === "active")
                    ?.map((item) => (
                      <Select.Option
                        key={item?._id}
                        value={JSON.stringify(item)}>
                        <span className='stock-section'>
                          {item?.quantityValue
                            ? item?.quantityValue
                            : `${item?.piecesInaPack} Pouches/Packet`}
                          {item?.quantityType && item?.quantityType} @
                          {item?.singlePiecePrice
                            ? item?.singlePiecePrice
                            : item?.salePrice}{" "}
                          {`(${item?.productCode})`}
                          <span className='avail-stock'>
                            {item?.productStream === "Qty"
                              ? `${Math.round(
                                  item?.totalQuantityOfSKU
                                )} kg avail`
                              : `${Math.round(item?.packets)} packets avail`}
                          </span>
                        </span>
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={8} md={8} sm={8}>
              <Form.Item
                name='noofPackets'
                label='Add Number Of Packets'
                rules={[
                  {
                    required: true,
                    message: "Please enter sku quantity or packets",
                  },
                ]}>
                <Space.Compact size='large'>
                  <Input
                    placeholder='Number of packets'
                    value={packetsValue}
                    onChange={handlePacketsChange}
                    disabled={isPacketsInputDisabled}
                  />
                  {!hideKgInput && (
                    <Input
                      placeholder='Kg'
                      value={kgValue}
                      onChange={handleKgChange}
                      disabled={isKgInputDisabled}
                    />
                  )}
                </Space.Compact>
              </Form.Item>
            </Col>

            <Col lg={4} md={4} sm={4}>
              <Form.Item label=' '>
                <button
                  className='AddOfflineSales_Add_Product'
                  type='primary'
                  htmlType='submit'>
                  {editStatus ? "Update Product" : "Add Product"}
                </button>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
      {id ? (
        ""
      ) : (
        <div className='reset-button'>
          <button
            onClick={() => dispatch(resetSelectedProducts())}
            className='btn'>
            Remove products
          </button>
        </div>
      )}
      <table className='AddOfflineSales_Scale_Table'>
        <thead className='AddOfflineSales_Table_Head'>
          <tr className='AddOfflineSales_table_row'>
            <th>S.No</th>
            <th>Name of the Product</th>
            <th>Sku</th>
            <th>Quantity</th>
            <th>Price &#8377;</th>
            <th>Discount (&#8377;)</th>
            <th>Tax (%)</th>
            <th>Final Price&#8377;</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody className='AddOfflineSales_Table_Body'>
          {SelectedProducts?.map((product, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{product?.productName}</td>
              <td>
                {product?.quantityType
                  ? `${product?.quantityValue} ${product?.quantityType}`
                  : `${product?.piecesInaPack} pouches/pkt  @${product?.singlePiecePrice}`}
              </td>
              <td>
                {product?.quantity
                  ? `${product?.quantity} kg`
                  : `${product?.packets} pkt`}
              </td>
              <td>{product?.salePrice}</td>
              <td style={{ maxWidth: "140px" }}>
                <div className='discount-section'>
                  <Input
                    type='text'
                    value={discounts[index] || ""}
                    placeholder={`${product?.discount ? product?.discount : 0}`}
                    onChange={(e) =>
                      handleDiscountChange(index, e.target.value)
                    }
                  />
                </div>
              </td>
              <td>{product?.tax}</td>
              <td>{product.final_price}</td>
              <td>
                <EditOutlined onClick={() => handleEditProduct(product)} />
              </td>
              <td>
                <BiTrash
                  onClick={() => handleRemoveProduct(product)}
                  style={{ cursor: "pointer" }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className='AddOfflineSales_Total_Payable'>
        <h6 className='AddOfflineSales_Payable'>
          Total Payable Amount: &#8377; {totalPayableAmount.toFixed(2)} /-
        </h6>
      </div>
      <div className='Offline_payment-method-selection'>
        <span>Choose Payment Method: </span>
        <button
          onClick={() => handlePaymentMethodSelect("spotpay")}
          className='paymentMethod-btn'>
          <FaRegCreditCard style={{ fontSize: "18px" }} /> <div>Spot Pay</div>
        </button>
        <button
          onClick={() => handlePaymentMethodSelect("creditpay")}
          className='paymentMethod-btn'>
          <FaRegCreditCard style={{ fontSize: "18px" }} /> <div>Credit Pay</div>
        </button>
      </div>
    </div>
  );
};
// AddProductOnlineSale
export default AddProductOnlineSale;
