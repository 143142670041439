import React, { useState, useEffect } from "react";
import { IoEyeOutline } from "react-icons/io5";
import { VscCheck, VscCircleFilled } from "react-icons/vsc";
import { Pagination, Modal, Button, message, Spin, Select } from "antd";
import OfflineSalesPopup from "./OfflineSalesPopup";
import OfflineSalesCreditPopup from "./OfflineSalesCreditPopup";
import { postAuthApiData } from "../../api/apiService";
import ReturnModel from "./ReturnModel";
import useLogout from "../../utils/authUtils";
import moment from "moment";

const OfflineListBody = ({ offlineSalesData, fetchOfflineSalesList }) => {
  const [installmentsData, setInstallmentsData] = useState({});
  const [spotPayModal, setSpotPayModal] = useState(false);
  const [statusChange, setStatusChange] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [orderId, setOdrerId] = useState("");
  const [creditPayModal, setCreditPayModal] = useState(false);
  const [orderBookingId, setOrderBookingId] = useState("");
  const [returnModel, setReturnModel] = useState(false);
  const [productsData, setProductsData] = useState([]);
  const [selectedStatusPerOrder, setSelectedStatusPerOrder] = useState({});
  const [invoiceModal, setInvoiceModal] = useState(false);
  const logout = useLogout();
  const [invoiceUrl, setInvoiceUrl] = useState("");
  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const offlinePayment = async (item) => {
    const Endpoint = "getInstallmentList";
    try {
      setLoading(true);
      const userData = {
        orderBookingId: item?._id,
      };
      const { data, loading: postLoading } = await postAuthApiData(
        Endpoint,
        userData
      );
      console.log("Installments List fetched successful:", data);
      setLoading(postLoading);
      if (data?.responseCode === 200) {
        console.log(data.message);
        setOrderBookingId(item?._id);
        setInstallmentsData(data?.data);
      } else if (data?.responseCode === 400) {
        console.log(data?.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Installments List fetched failed:", error);
      message.error(error);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const showModal = async (item) => {
    offlinePayment(item);
    if (item?.paymentStatus === "spotpay") {
      setSpotPayModal(true);
    } else if (item?.paymentStatus === "creditpay") {
      setCreditPayModal(true);
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const pageSize = 10;

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = offlineSalesData?.slice(startIndex, endIndex);

  const { Option } = Select;

  const getBackgroundColor = (status) => {
    switch (status) {
      case "Delivered":
        return "#14B8A6";
      case "Packing Completed":
        return "#14B8A6";
      case "InProgress":
        return "#FFC727";
      case "Return":
        return "#EF4444";
      case "Damaged":
        return "#EF4444";
      default:
        return "white";
    }
  };

  const handleStatusChange = (value, id) => {
    console.log(value, id);
    setOdrerId(id);
    setSelectedStatus(value);
    if (value === "Damaged" || value === "Return") {
      setReturnModel(true);
    } else {
      setStatusChange(true);
    }
  };

  const statusUpdate = async () => {
    const Endpoint = "OfflineOrderStatus";
    try {
      setLoading(true);
      const userData = { orderBookingId: orderId, newStatus: selectedStatus };
      const { data, loading: postLoading } = await postAuthApiData(
        Endpoint,
        userData
      );
      console.log("Order Status Changed successful:", data);
      setLoading(postLoading);
      if (data?.responseCode === 200) {
        console.log(data.message);
        message.success("Status Changed Successfully");
        fetchOfflineSalesList();
        setStatusChange(false);
        setOdrerId("");
        setSelectedStatus("");
        setSelectedStatusPerOrder((prev) => ({
          ...prev,
          [orderId]: "",
        }));
      } else if (data?.responseCode === 400) {
        console.log(data.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("Order Status Changing failed:", error);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (orderId) {
      fetchListOfProducts();
    }
  }, [orderId, setOdrerId]);

  const fetchListOfProducts = async () => {
    const Endpoint = "getOrderInfo";
    try {
      setLoading(true);
      const userData = {
        orderBookingId: orderId,
      };
      const { data, loading: postLoading } = await postAuthApiData(
        Endpoint,
        userData
      );
      console.log("List of products fetched successful:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data.message);
        setProductsData(data?.data);
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data?.message);
        setProductsData([]);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      console.log("List of products fetching failed:", error);
      message.error(error);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  console.log(orderId);

  const handleInvoice = async () => {
    await setInvoiceModal(true);
  };

  return (
    <>
      <div className='offlineListBody_div'>
        <table className='offlineListBody_table'>
          <thead className='offlineListBody_thead'>
            <tr className='offlineListBody_table-row'>
              <th className='offlineListBody_Serial-No'>S.No</th>
              <th className='offlineListBody_Order-Id'>Order ID</th>
              <th className='offlineListBody_shop-keeper-details'>
                Customer Name
              </th>
              <th className='offlineListBody_Contact'>Contact</th>
              <th className='offlineListBody_No-of-packets'>No-of Packets</th>
              {/* <th className='offlineListBody_Quantity'>Quantity/Pouches</th> */}
              <th className='offlineListBody_price '>Price</th>
              <th className='offlineListBody_Date'>Date</th>
              <th className='offlineListBody_payment-Status'>Payment Status</th>
              <th>Order Status</th>
              <th className='offlineListBody_Invoice'>Invoice</th>
            </tr>
          </thead>

          {paginatedData?.length > 0
            ? paginatedData?.map((item, index) => (
                <tbody className='offlineListBody_tbody' key={index}>
                  <tr>
                    <td>{startIndex + index + 1}</td>
                    <td>{item?.orderId}</td>
                    {/* <td>{`${item?.total_packets?.toFixed(1)} Pkt`}</td> */}
                    <td className='capital'>{item?.customerName}</td>
                    <td>{item?.customerContact || "--"}</td>
                    <td>
                      {item?.total_quantity
                        ? `${item?.total_quantity.toFixed(1)} Kgs [${
                            item?.total_packets
                          } Pkt]`
                        : `${item?.total_packets?.toFixed(1)} Pkt`}
                    </td>
                    <td>{item?.total_price?.toFixed(2)}</td>
                    <td>
                      {" "}
                      {item?.date
                        ? moment(item.date).format("DD-MM-YYYY")
                        : "--"}
                    </td>
                    <td
                      className={
                        item?.paymentStatus === "spotpay" ? "paid" : "paid"
                      }>
                      <div
                        className='OfflineListBody_PaymentStatus capital'
                        style={{ cursor: "pointer" }}
                        onClick={() => showModal(item)}>
                        {item?.paymentStatus}
                        {item?.paymentStatus === "spotpay" && <VscCheck />}
                        {item?.paymentStatus === "creditpay" && <VscCheck />}
                      </div>
                    </td>
                    <td className='OnlineListBody_OrderStatus capital'>
                      <div
                        style={{
                          borderRadius: "20px",
                          color: "white",
                        }}>
                        <Select
                          placeholder='Select Status'
                          size='medium'
                          value={
                            selectedStatusPerOrder[item._id] ||
                            item?.orderStatus
                          }
                          // onChange={(value) =>
                          //   handleStatusChange(value, item._id)
                          // }
                          onSelect={(value) =>
                            handleStatusChange(value, item._id)
                          }
                          popupMatchSelectWidth={160}
                          style={{
                            width: 130,
                            backgroundColor: getBackgroundColor(
                              item?.orderStatus
                            ),
                            borderRadius: "16px",
                          }}>
                          <Option value='Delivered'>Delivered</Option>
                          <Option value='Return'>Return</Option>
                          <Option value='Damaged'>Damaged</Option>
                          <Option value='Packing Completed'>
                            Packing Completed
                          </Option>
                        </Select>
                      </div>
                    </td>
                    <td>
                      <IoEyeOutline
                        size={17}
                        onClick={() => {
                          setInvoiceUrl(item?.invoiceUrl);
                          handleInvoice();
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </td>
                  </tr>
                </tbody>
              ))
            : ""}
        </table>
        {paginatedData?.length > 0 ? (
          " "
        ) : (
          <div className='no-data-message'>
            <h6>No data found</h6>
          </div>
        )}

        <Modal
          visible={spotPayModal}
          onCancel={() => setSpotPayModal(false)}
          centered
          footer={null}>
          <OfflineSalesPopup installmentsData={installmentsData} />
        </Modal>
        <Modal
          visible={invoiceModal}
          onCancel={() => setInvoiceModal(false)}
          closeIcon={false}
          centered
          footer={null}>
          <div className='invoice-container'>
            <div>
              <iframe src={invoiceUrl} width='100%' height='550px' />
            </div>
            <div style={{ textAlign: "end" }}>
              <button
                onClick={() => {
                  setInvoiceModal(false);
                  setInvoiceUrl("");
                }}
                className='close-btn'>
                Close
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          visible={creditPayModal}
          onCancel={() => setCreditPayModal(false)}
          centered
          footer={null}>
          <OfflineSalesCreditPopup
            installmentsData={installmentsData}
            orderBookingId={orderBookingId}
            setCreditPayModal={setCreditPayModal}
            fetchOfflineSalesList={fetchOfflineSalesList}
          />
        </Modal>
        <Modal
          visible={statusChange}
          onCancel={() => setStatusChange(false)}
          centered
          footer={null}
          className='custom-modal'>
          <Spin spinning={loading}>
            <div className='role-active-container'>
              <div className='role-active-content'>
                <h5 style={{ textAlign: "center" }}>
                  Are you want to Change the Status of this Sale
                </h5>
                <div className='role-active-actions'>
                  <button
                    className='role-active-no'
                    onClick={() => setStatusChange(false)}>
                    <p>No</p>
                  </button>
                  <button
                    className='role-active-delete'
                    onClick={() => statusUpdate()}>
                    <p>Yes</p>
                  </button>
                </div>
              </div>
            </div>
          </Spin>
        </Modal>
        <Modal
          visible={returnModel}
          onCancel={() => {
            setReturnModel(false);
            setOdrerId("");
          }}
          centered
          footer={null}
          width={1000}
          className='custom-modal'>
          <ReturnModel
            orderId={orderId}
            setOdrerId={setOdrerId}
            setReturnModel={setReturnModel}
            selectedStatus={selectedStatus}
            statusUpdate={statusUpdate}
            productsData={productsData}
            loading={loading}
            setLoading={setLoading}
          />
        </Modal>
      </div>
      <div className='offlineListBody_div-Pagination-container'>
        {offlineSalesData?.length > 0 && (
          <Pagination
            simple
            current={currentPage}
            pageSize={pageSize}
            total={offlineSalesData?.length}
            onChange={handleChangePage}
          />
        )}
      </div>
    </>
  );
};

export default OfflineListBody;
