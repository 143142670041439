import React from "react";
import { GoArrowLeft, GoMail } from "react-icons/go";
import { IoIosCall } from "react-icons/io";
import { Avatar } from "antd";
import profileImage from "../Booking History/img1.png";
import { useNavigate } from "react-router-dom";
import { MdOutlineModeEdit } from "react-icons/md";

const CustomerBookingProfile = ({ details }) => {
  console.log(details);
  const navigate = useNavigate();
  return (
    <div className='BookingProfile_Container'>
      <div className='BookingProfile_Header'>
        <div className='BookingProfile_Left'>
          <div className='BookingProfile_Icon' onClick={() => navigate(-1)}>
            <GoArrowLeft size={30} />
          </div>
          <div className='BokkingProfile_avatar'>
            {details?.image ? (
              <Avatar
                src={details?.image}
                size={50}
                className='ProfileImage1'
              />
            ) : (
              <Avatar
                shape='circle'
                size={40}
                style={{
                  backgroundColor: "#F1ECFF",
                  fontWeight: 600,
                  fontSize: 17,
                  color: "#6750A4",
                  // border: "1px solid #6750A4",
                }}>
                {details?.name?.length > 0 &&
                  details?.name[0]?.charAt(0)?.toUpperCase()}
              </Avatar>
            )}
          </div>
          <div className='BookingProfile_name'>
            <label style={{ textTransform: "capitalize" }}>
              {details?.name}
            </label>
            <label>ID :- {details?.customerID}</label>
          </div>
          <div className='BookingProfile_Phone'>
            <IoIosCall size={11} />
            {details?.phone ? `+91 ${details?.phone}` : "-"}
          </div>
          {details?.email && (
            <div className='BookingProfile_Email'>
              <GoMail size={11} />
              {details?.email}
            </div>
          )}
        </div>
        <div className='BookingProfile_Right'>
          <div className='BookingProfile_edit'>
            {/* <button className='BookingProfile_editbtw'>
              <MdOutlineModeEdit size={11} />
              Edit
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerBookingProfile;
