import React from "react";
import { IoFilter } from "react-icons/io5";
import { Avatar } from "antd";
import Card_image1 from "./point_of_sale.png";
import Card_image2 from "./pending_actions.png";
import Card_image3 from "./border_inner.png";
import Card_image4 from "./delivery_dining.png";
import { DatePicker, Space } from "antd";
import moment from "moment";
const { RangePicker } = DatePicker;

const Cards = ({
  salesData,
  setReportsDate,
  reportsDate,
  fetchReportCardsData,
  apiData,
  currentPage,
}) => {
  console.log(salesData);

  const formatNumber = (num) => {
    if (num >= 1000) {
      return (num / 1000).toFixed(2) + "K"; // Convert to K and keep one decimal place
    }
    return num;
  };

  const onDateChange = (dates, dateStrings) => {
    const formattedDates = dateStrings?.every((date) => date) // Check if all dates are non-empty
      ? dateStrings.map((date) =>
          moment(date, "DD-MM-YYYY").format("YYYY-MM-DD")
        )
      : []; // Set to empty array if any date is empty
    console.log("Selected dates:", dates);
    console.log("Formatted dates (YYYY-MM-DD):", formattedDates);
    setReportsDate(formattedDates);
    fetchReportCardsData(formattedDates, currentPage);
  };

  return (
    <div className='dashboard-content'>
      <div className='reports-section'>
        <h5 className='reports-heading'>Reports</h5>
        <div className='reports-time-section'>
          {/* <small className='reports-time'>01, Jan 2024 - 07, Jan 2024</small> */}
          <div className='weekly-section'>
            <RangePicker
              style={{ width: "100%" }}
              format='DD-MM-YYYY'
              onChange={onDateChange}
            />
          </div>
        </div>
      </div>
      <div className='cards-section'>
        <div className='card-container card1-color'>
          <div className='card-sales'>
            <Avatar src={Card_image1} size={50} className='card1' />
            <div className='sales-info'>
              <h5>Revenue</h5>
              {/* <p className='units'>&#8377;Rupees</p> */}
            </div>
          </div>
          <h4>{formatNumber(salesData?.totalEarnedAmount)}</h4>
        </div>

        <div className='card-container card3-color'>
          <div className='card-sales'>
            <Avatar src={Card_image3} size={50} className='card3' />
            <div className='sales-info'>
              <h5>Orders</h5>
              {/* <p className='units'>Total Received</p> */}
            </div>
          </div>
          <h4>{apiData?.orderCount}</h4>
        </div>
        <div className='card-container card2-color'>
          <div className='card-sales'>
            <Avatar src={Card_image2} size={50} className='card2' />
            <div className='sales-info'>
              <h5>Pending</h5>
              {/* <p className='units'>Units</p> */}
            </div>
          </div>
          <h4>{salesData?.pendingCount}</h4>
        </div>
        <div className='card-container card4-color'>
          <div className='card-sales'>
            <Avatar src={Card_image4} size={50} className='card4' />
            <div className='sales-info'>
              <h5>Delivered</h5>
              {/* <p className='units'>Completed</p> */}
            </div>
          </div>
          <h4>{salesData?.deliveredCount}</h4>
        </div>
      </div>
      <div className='table-section'></div>
    </div>
  );
};

export default Cards;
